import http from './http';
import { removeEmpty } from '../shared/utility';

const getSectors = (locationUuid, page = 1, limit = 1000) => {
  return new Promise((resolve, reject) => {
    const params = {
      p: page,
      l: limit
    };
    http
      .get(`/location/${locationUuid}/area`, removeEmpty(params))
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
export default {
  getSectors
};
