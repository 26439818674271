import React from 'react';

import './Dashboard.less';

const Dashboard = () => {
  return (
    <div className="dashboard-page">
      <div className="header">
        <h2>Dashboard</h2>
      </div>
    </div>
  );
};

export default Dashboard;
