/*
  Props defination
  data - Branch's data
  className - merge this prop into paret containers className
  onUpdate - callback which update local branches list
*/

import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Select, message } from 'antd';
import api from '../../api';

const { confirm } = Modal;

const UpdateBranchStatusDropdown = ({ data, onUpdate, className }) => {
  const meta = useSelector(state => state.config.meta);
  const status = {};
  meta?.branch?.status?.length &&
    meta.branch.status.map(item => (status[item.value] = item.name));

  const handleStatusUpdate = value => {
    confirm({
      title: 'Do you want to update Branch Status?',
      content: `The status will be updated to ${status[value]}.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        api.branches
          .update(data.uuid, { status: value })
          .then(resp => {
            data.status = resp.status;
            onUpdate(data);
          })
          .catch(err => {
            message.error(err.message);
          });
      }
    });
  };
  return (
    <div className={`update-branch-status ${className}`}>
      <Select
        value={status[data?.status]}
        style={{ width: '150px' }}
        size="large"
        onChange={handleStatusUpdate}
      >
        {meta?.branch?.status.map((item, index) => (
          <Select.Option value={item.value} key={index}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default UpdateBranchStatusDropdown;
