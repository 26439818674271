import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message } from 'antd';

import './AddTagDialog.less';
import api from '../../api';
import { validate, FormItemWithError } from '../../shared/validations';
import Loader from '../Loader/Loader';

const requiredFields = ['name'];

const AddTagDialog = ({ isVisible, onSubmit, onCancel, editTagData }) => {
  const initialState = {
    name: ''
  };
  const [state, setState] = useState({
    tag: editTagData && initialState,
    error: {},
    loading: false
  });

  const resetState = () => {
    const stateObj = { ...state };
    stateObj.tag = initialState;
    stateObj.error = {};
    stateObj.loading = false;
    setState(stateObj);
  };

  const addUpdateTag = data => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    const call = data.uuid ? api.tags.update(data) : api.tags.add(data);
    call
      .then(resp => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        if (resp && resp.uuid) {
          resetState();
          onSubmit(resp);
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  };

  const submit = () => {
    const valid = validate(state.tag, requiredFields);
    // eslint-disable-next-line no-console
    !valid.isValid
      ? setState(stateObj => ({ ...stateObj, error: valid.errors }))
      : addUpdateTag(state.tag);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  const handleChange = (field, value) => {
    const stateObject = { ...state };
    stateObject.tag[field] = value;
    setState(stateObject);
  };

  useEffect(() => {
    if (editTagData && editTagData.uuid) {
      setState(stateObj => ({ ...stateObj, tag: editTagData }));
    }
    // eslint-disable-next-line
  }, [editTagData]);

  return (
    <Modal
      title={state.tag && state.tag.uuid ? 'Edit Tag' : 'Add New Tag'}
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-tag-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Name" required>
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Name"
                value={state.tag && state.tag.name}
                onChange={e => handleChange('name', e.target.value)}
              />,
              'name',
              state.error
            )}
          </Form.Item>
          <Form.Item label="Slug">
            <Input
              size="large"
              placeholder="Slug"
              value={state.tag && state.tag.slug}
              onChange={e => handleChange('slug', e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddTagDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editTagData: PropTypes.objectOf(PropTypes.any)
};

AddTagDialog.defaultProps = {
  editTagData: null
};

export default AddTagDialog;
