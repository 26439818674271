import React from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Divider, Empty } from 'antd';

const SocialAccount = ({ ninjaSocialAccount }) => {
  const open = link =>
    window.open(`${link.includes('https://') ? '' : 'https://'}${link}`);

  return (
    <div className="socialAccount m-b">
      <div className="t-b m-b">Social Accounts:</div>
      {ninjaSocialAccount.length ? (
        ninjaSocialAccount.map(socialAccount => (
          <SocialAccountItem
            socialAccount={socialAccount}
            key={socialAccount.uuid}
            open={open}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

const SocialAccountItem = ({ socialAccount, open }) => (
  <div className="social-account-item">
    <Descriptions>
      <Descriptions.Item label="Type">{socialAccount.type}</Descriptions.Item>
      <Descriptions.Item label="Link" className="link-item">
        <Button
          type="link"
          size="small"
          onClick={() => open(socialAccount.link)}
          className="m-0"
        >
          {socialAccount.link}
        </Button>
      </Descriptions.Item>
      {/* <Descriptions.Item label="Username">
        {socialAccount.user_name || 'Not Available'}
      </Descriptions.Item> */}
    </Descriptions>
    <Divider className="small divider" />
  </div>
);

SocialAccount.propTypes = {
  ninjaSocialAccount: PropTypes.arrayOf(PropTypes.any).isRequired
};

SocialAccountItem.propTypes = {
  socialAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.func.isRequired
};

export default SocialAccount;
