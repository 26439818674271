import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message, Select } from 'antd';

import './AddBlogDialog.less';
import api from '../../api';
import { validate, FormItemWithError } from '../../shared/validations';
import Loader from '../Loader/Loader';
// import { useStackEdit } from "use-stackedit";
import StackEdit from '../StackEdit/StackEdit';
// import MDEditor from '@uiw/react-md-editor';

const { Option } = Select;

const requiredFields = [];

const AddBlogDialog = ({ isVisible, onSubmit, onCancel, editBlogData }) => {
  const initialState = {
    title: ''
  };
  // const [value, setValue] = useState("");
  // const { openStackedit, onFileChange } = useStackEdit(setValue);
  const [state, setState] = useState({
    blog: editBlogData && initialState,
    error: {},
    loading: false,
    blogCategories: [],
  });

  const updateBlogContent = body => {
    const stateObject = { ...state };
    stateObject.blog.content = body;
    setState(stateObject);
  };

  const resetState = () => {
    const stateObj = { ...state };
    stateObj.blog = initialState;
    stateObj.error = {};
    stateObj.loading = false;
    setState(stateObj);
  };

  const addUpdateBlog = data => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    const call = data.uuid ? api.blog.update(data) : api.blog.add(data);
    call
      .then(resp => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        if (resp && resp.uuid) {
          resetState();
          onSubmit(resp);
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  };

  const submit = () => {
    const valid = validate(state.blog, requiredFields);
    // eslint-disable-next-line no-console
    console.log('is valid : ', valid);
    !valid.isValid
      ? setState(stateObj => ({ ...stateObj, error: valid.errors }))
      : addUpdateBlog(state.blog);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  const fetchCategories = () => {
    api.blogCategory
      .getList('', 1, process.env.REACT_APP_PAGE_LIMIT)
      .then(resp => {
        if (resp && resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            blogCategories: [resp.items.map(category => category.name)],
            loading: false
          }));
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  };

  const handleCategoriesChange = (value) => {
    // eslint-disable-next-line no-console
    console.log('categories called');
  };

  const handleChange = (field, value) => {
    const stateObject = { ...state };
    stateObject.blog[field] = value;
    setState(stateObject);
  };

  useEffect(() => {
    if (editBlogData && editBlogData.uuid) {
      setState(stateObj => ({ ...stateObj, blog: editBlogData }));
    }
    // eslint-disable-next-line
  }, [editBlogData]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(state.blogCategories);
    if (state.blogCategories.length > 0) {
      setState(stateObj => ({ ...stateObj, blog: editBlogData }));
    }
    // eslint-disable-next-line
  }, [state.blogCategories]);

  // state.blogCategories.length > 0 ? (
  //   <div>
  //     <Option />
  //   </div>
  // ) : null;

  return (
    <Modal
      title={state.blog && state.blog.uuid ? 'Edit Blog' : 'Add New Blog'}
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-skill-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Title" required>
            <Input
              size="large"
              placeholder="Title"
              value={state.blog && state.blog.title}
              onChange={e => handleChange('title', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Slug">
            <Input
              size="large"
              placeholder="Slug"
              value={state.blog && state.blog.slug}
              onChange={e => handleChange('slug', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              size="large"
              placeholder="Description"
              value={state.blog && state.blog.description}
              onChange={e => handleChange('description', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Categories">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select Categories"
              defaultValue={[]}
              onFocus={fetchCategories}
              onChange={e => handleCategoriesChange(e.target.value)}
              optionLabelProp="label"
            >
              {state.blogCategories}
            </Select>
          </Form.Item>
          <Form.Item label="Featured Photo Url">
            <Input
              size="large"
              placeholder="Featured Photo Url"
              value={state.blog && state.blog.featured_photo_url}
              onChange={e => handleChange('featured_photo_url', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Content">
            {/* <textarea
              value={value}
              onChange={e => {
                setValue(e.target.value);
                // If textarea is edited run the file change event on stackedit
                onFileChange();
              }}
            />
            <button
              onClick={() => {
                openStackedit({
                  content: {
                    // Markdown content.
                    text: value
                  }
                });
              }}
            >
              Open Editor
            </button> */}
            {/* <MDEditor
              value={state.blog && state.blog.content} onChange={e => handleChange('content', e.target.value)}
            /> */}
            {/* <MDEditor.Markdown source={state.blog && state.blog.content} /> */}
            <StackEdit
              value={state.blog && state.blog.content}
              onUpdate={updateBlogContent}
              id="problem-body"
              placeholder="Content"
            />
            {/* <Input
              size="large"
              placeholder="Content"
              value={state.blog && state.blog.content}
              onChange={e => handleChange('content', e.target.value)}
            /> */}
          </Form.Item>
          <Form.Item label="Seo Title">
            <Input
              size="large"
              placeholder="Seo Title"
              value={state.blog && state.blog.seo_title}
              onChange={e => handleChange('seo_title', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Seo Description">
            <Input
              size="large"
              placeholder="Seo Description"
              value={state.blog && state.blog.seo_description}
              onChange={e => handleChange('seo_description', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Is Featured">
            <Input
              size="large"
              placeholder="Is Featured"
              value={state.blog && state.blog.is_featured}
              onChange={e => handleChange('is_featured', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Read Time">
            <Input
              size="large"
              placeholder="Read Time"
              value={state.blog && state.blog.read_time}
              onChange={e => handleChange('read_time', e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddBlogDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editBlogData: PropTypes.objectOf(PropTypes.any)
};

AddBlogDialog.defaultProps = {
  editBlogData: null
};

export default AddBlogDialog;
