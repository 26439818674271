import { connect } from 'react-redux';
import ApplicantDetails from './ApplicantDetails';

const mapStateToProps = state => ({
  applicantStatus:
    state.config.meta.applicant && state.config.meta.applicant.status
      ? state.config.meta.applicant.status
      : [],
  jobCommitment:
    state.config.meta.job && state.config.meta.job.commitment
      ? state.config.meta.job.commitment
      : []
});

export default connect(mapStateToProps)(ApplicantDetails);
