import { connect } from 'react-redux';
import ExtensiveSearch from './ExtensiveSearch';

const mapStateToProps = state => ({
  ninjasCommitment:
    state.config.meta.ninja && state.config.meta.ninja.commitment
      ? state.config.meta.ninja.commitment
      : [],
  ninjasStatus:
    state.config.meta.ninja && state.config.meta.ninja.status
      ? state.config.meta.ninja.status
      : [],
  ninjasTitles:
    state.config.meta.ninja && state.config.meta.ninja.titles
      ? state.config.meta.ninja.titles
      : [],
  branchesExperience:
    state.config.meta.branch && state.config.meta.branch.experience
      ? state.config.meta.branch.experience
      : [],
  branchesCommitment:
    state.config.meta.branch && state.config.meta.branch.commitment
      ? state.config.meta.branch.commitment
      : [],
  branchesStatus:
    state.config.meta.branch && state.config.meta.branch.status
      ? state.config.meta.branch.status
      : []
});

export default connect(mapStateToProps)(ExtensiveSearch);
