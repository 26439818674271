import React from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Divider, Empty } from 'antd';

const Portfolio = ({ ninjaPortfolio }) => {
  const open = link =>
    window.open(`${link.includes('https://') ? '' : 'https://'}${link}`);

  return (
    <div className="portfolio m-b">
      <div className="t-b m-b">Portfolios:</div>
      {ninjaPortfolio.length ? (
        ninjaPortfolio.map(portfolio => (
          <PortfolioItem
            portfolio={portfolio}
            open={open}
            key={portfolio.uuid}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

const PortfolioItem = ({ portfolio, open }) => (
  <div className="portfolio-item">
    <Descriptions>
      <Descriptions.Item label="Title">{portfolio.title}</Descriptions.Item>
      <Descriptions.Item label="Link" className="link-item">
        <Button
          className="link"
          type="link"
          size="small"
          onClick={() => open(portfolio.link)}
        >
          {portfolio.link}
        </Button>
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="Description">
        {portfolio.description}
      </Descriptions.Item>
    </Descriptions>
    <Divider className="small divider" />
  </div>
);

Portfolio.propTypes = {
  ninjaPortfolio: PropTypes.arrayOf(PropTypes.any).isRequired
};

PortfolioItem.propTypes = {
  portfolio: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.func.isRequired
};

export default Portfolio;
