import { message } from 'antd';

export const branchStatusColor = status => {
  switch (status) {
    case 0:
      return 'gold';
    case 1:
      return 'green';
    case 2:
      return 'blue';
    case -1:
      return 'red';
    default:
      return 'magenta';
  }
};

export const ninjaStatusColor = status => {
  switch (status) {
    case 0:
      return 'gold';
    case 1:
      return 'blue';
    case 2:
      return 'volcano';
    case 3:
      return 'green';
    case 4:
      return 'red';
    default:
      return 'magenta';
  }
};
export const currencyFilter = value =>
  value && parseInt(value, 10).toLocaleString();

export const currencyFormatter = num => {
  if (num < 1000) return num.toString();
  if (num < 1000000) return `${(num / 1000).toFixed(0)}k`;
  return `${(num / 1000000).toFixed(1)}m`;
};

export const removeEmpty = obj => {
  const params = obj;
  Object.keys(params).forEach(
    key =>
      (params[key] === null ||
        params[key] === '' ||
        params[key].length === 0) &&
      delete params[key]
  );
  return params;
};

export const openMail = (e, email) => {
  e.stopPropagation();
  window.open(`mailto:${email}`);
};

export const copyToClipboard = email => {
  const el = document.createElement('textarea');
  el.value = email;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  message.success('Email copied to clipboard!');
};

export const removeNullValues = obj => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
};
