import { connect } from 'react-redux';
import BasicInfo from './BasicInfo';

const mapStateToProps = state => ({
  ninjaCommitment:
    state.config.meta.ninja && state.config.meta.ninja.commitment
      ? state.config.meta.ninja.commitment
      : []
});

export default connect(mapStateToProps)(BasicInfo);
