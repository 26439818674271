import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const GuestRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !user || !user.uuid ? (
          <Component {...props} />
        ) : (
          <Redirect to={rest.redirectTo ? rest.redirectTo : '/'} />
        )
      }
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func
  ]).isRequired,
  user: PropTypes.objectOf(PropTypes.any)
};

GuestRoute.defaultProps = {
  user: null
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn
  };
};

export default connect(mapStateToProps, null)(GuestRoute);
