/* eslint-disable consistent-return */
/* eslint-disable no-console */
const setItem = (name, value) => {
  try {
    localStorage.setItem(name, JSON.stringify(value));
  } catch (error) {
    console.log('LocalStorage setitem error: ', error);
  }
};

const getItem = name => {
  try {
    const itemValue = localStorage.getItem(name);
    return itemValue;
  } catch (error) {
    console.log('LocalStorage getitem error: ', error);
  }
};

const removeItem = name => {
  try {
    localStorage.removeItem(name);
  } catch (error) {
    console.log('LocalStorage removeItem error: ', error);
  }
};

export default {
  setItem,
  getItem,
  removeItem
};
