import { connect } from 'react-redux';
import AddBlogDialog from './AddBlogDialog';

const mapStateToProps = state => ({
  slugs:
    state.config.meta.amenities && state.config.meta.amenities.slugs
      ? state.config.meta.amenities.slugs
      : []
});

export default connect(mapStateToProps)(AddBlogDialog);
