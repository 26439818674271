import { connect } from 'react-redux';
import Signin from './Signin';
import { login } from '../../store/user/userAction';

const mapStateToProps = state => ({
  user: state.user.user,
  isLoggedIn: state.user.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(login(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
