import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Divider, Button, Modal } from 'antd';

import './MenuCard.less';
// import SkillSet from '../CategorySet/CategorySet';
import AddMenuItemDialog from '../AddMenuItemDialog/AddMenuItemDialog';
import EditMenuItemDialog from '../EditMenuItemDialog/EditMenuItemDialog';
import AddChildItemDialog from '../AddChildItemDialog/AddChildItemDialog';
import EditMenuCategoryDialog from '../EditMenuCategoryDialog/EditMenuCategoryDialog';

const { confirm } = Modal;

const MenuCard = ({ data, onCategoryDelete, onItemDelete }) => {
  const [state, setState] = useState({
    showAddMenuItemDialog: false,
    showAddChildItemDialog: false,
    showEditMenuCategoryDialog: false,
    showEditMenuItemDialog: false,
    itemUuid: null
  });
  const [menu, setMenu] = useState(null);
  const applicantStatusObj = {};
  // applicantStatus?.length &&
  //   applicantStatus.map(item => (applicantStatusObj[item.value] = item.name));

  useEffect(() => {
    setMenu(data);
    console.log('CATEGORY: ', data);
  }, [data]);

  // const generateChildren = children => {
  //   return children.map(child => (
  //     <Descriptions>
  //       <Descriptions.Item label="price">
  //         {child.item_price ? child.item_price : 'N/A'}
  //       </Descriptions.Item>
  //       <Descriptions.Item label="size">
  //         {child.item_size ? child.item_size : 'N/A'}
  //       </Descriptions.Item>
  //     </Descriptions>
  //   ));
  // };

  const generateItemPrices = (children, itemPrice) => {
    const prices = [itemPrice];
    children.forEach(child => {
      prices.push(child.item_price);
    });
    return prices.join(' | ');
  };

  const generateItemSizes = (children, itemSize) => {
    const sizes = [itemSize];
    children.forEach(child => {
      if (child.item_size == null) {
        sizes.push('N/A');
      } else {
        sizes.push(child.item_size);
      }
    });
    return sizes.join(' | ');
  };

  const generateItemServing = (children, itemServing) => {
    const servings = [];
    if (itemServing) {
      servings.push(itemServing);
    }
    children.forEach(child => {
      if (child.item_serving == null) {
        servings.push('N/A');
      } else {
        servings.push(child.item_serving);
      }
    });
    if (servings.length === 0) {
      return 'N/A';
    }
    return servings.join(' | ');
  };

  const openAddChildItemDialog = uuid => {
    setState(stateObj => ({ ...stateObj, showAddChildItemDialog: true }));
    setState(stateObj => ({ ...stateObj, itemUuid: uuid }));
  };

  const closeAddChildItemDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddChildItemDialog: false }));
    setState(stateObj => ({ ...stateObj, itemUuid: null }));
  };

  const addChildItem = childItemData => {
    console.log('CHILD_ITEM_DATA: ', childItemData);
    // setState(stateObj => ({ ...stateObj, businessDetails: { ...data } }));
    // dispatch(toggleBusinessUpdated(!businessUpdated));
    closeAddChildItemDialog();
  };

  const openEditMenuItemDialog = uuid => {
    setState(stateObj => ({ ...stateObj, showEditMenuItemDialog: true }));
    setState(stateObj => ({ ...stateObj, itemUuid: uuid }));
  };

  const closeEditMenuItemDialog = () => {
    setState(stateObj => ({ ...stateObj, showEditMenuItemDialog: false }));
  };

  const editMenuItem = itemData => {
    console.log('MENU_ITEM_DATA_EDITED: ', itemData);
    closeEditMenuItemDialog();
  };

  const showDeleteItemConfirmDialog = (uuid, name) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      content: `${name} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onItemDelete(uuid);
      }
    });
  };

  const generateMenu = () => {
    return (
      <div>
        {menu?.items?.map(item => (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Tag key={item.uuid}>{item.item_name}</Tag>
                <Button
                  type="link"
                  icon="edit"
                  onClick={() => openEditMenuItemDialog(item.uuid)}
                >
                  {/* Edit Item */}
                </Button>
                <Button
                  type="link"
                  icon="delete"
                  onClick={() =>
                    showDeleteItemConfirmDialog(item.uuid, item.item_name)
                  }
                  style={{ marginTop: '-0.4rem' }}
                >
                  {/* Delete Item */}
                </Button>
              </div>
              <div>
                <Button
                  type="link"
                  icon="plus"
                  onClick={() => openAddChildItemDialog(item.uuid)}
                  style={{ marginTop: '0.6rem' }}
                >
                  Add Child
                </Button>
              </div>
            </div>
            <Descriptions>
              <Descriptions.Item label="Desc">
                <span
                  style={{ display: 'inline-grid', whiteSpace: 'pre-line' }}
                >
                  {item.item_desc ? item.item_desc : 'N/A'}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Size">
                {item.children.length > 0
                  ? generateItemSizes(item.children, item.item_size)
                  : item.item_size
                  ? item.item_size
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                {item.children.length > 0
                  ? generateItemPrices(item.children, item.item_price)
                  : item.item_price
                  ? item.item_price
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Serving">
                {item.children.length > 0
                  ? generateItemServing(item.children, item.item_serving)
                  : item.item_serving
                  ? item.item_serving
                  : 'N/A'}
              </Descriptions.Item>
              {/* <Descriptions.Item label="size">
                {item.item_size ? item.item_size : 'N/A'}
              </Descriptions.Item> */}
            </Descriptions>
            {/* {item.children.length > 0 ? generateChildren(item.children) : null} */}
            <Divider className="menu-divider" />
          </div>
        ))}
      </div>
    );
  };

  const openAddMenuItemDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddMenuItemDialog: true }));
  };

  const closeAddMenuItemDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddMenuItemDialog: false }));
  };

  const addMenuItem = itemData => {
    console.log('MENU_ITEM_DATA: ', itemData);
    // setState(stateObj => ({ ...stateObj, businessDetails: { ...data } }));
    // dispatch(toggleBusinessUpdated(!businessUpdated));
    closeAddMenuItemDialog();
  };

  const openEditMenuCategoryDialog = () => {
    setState(stateObj => ({ ...stateObj, showEditMenuCategoryDialog: true }));
  };

  const closeEditMenuCategoryDialog = () => {
    setState(stateObj => ({ ...stateObj, showEditMenuCategoryDialog: false }));
  };

  const editMenuCategory = itemData => {
    console.log('EDITED MENU CATEGORY: ', itemData);
    let temp = menu;
    temp.name = itemData.name;
    temp.uuid = itemData.uuid;
    temp.slug = itemData.slug;
    temp.category_desc = itemData.category_desc;
    setMenu(temp);
    // setState(stateObj => ({ ...stateObj, businessDetails: { ...data } }));
    // dispatch(toggleBusinessUpdated(!businessUpdated));
    closeEditMenuCategoryDialog();
  };

  const showDeleteCategoryConfirmDialog = () => {
    confirm({
      title: 'Are you sure you want to delete this category. Menu items belong to it will also be deleted?',
      content: `${menu?.name} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onCategoryDelete(menu.uuid);
      }
    });
  };

  return (
    <div className="menu-card">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-flex' }}>
          <h4>{menu?.name}</h4>
          <Button
            type="link"
            icon="edit"
            onClick={() => openEditMenuCategoryDialog()}
            style={{ marginTop: '-0.4rem' }}
          >
            {/* Edit Category */}
          </Button>
          <Button
            type="link"
            icon="delete"
            onClick={showDeleteCategoryConfirmDialog}
            style={{ marginTop: '-0.4rem' }}
          >
            {/* Delete Category */}
          </Button>
        </div>
        <div>
          <Button
            type="link"
            icon="plus"
            onClick={openAddMenuItemDialog}
            style={{ marginTop: '-0.4rem' }}
          >
            Add Item
          </Button>
        </div>
      </div>
      {/* {data && data.skills && <SkillSet skills={data.skills} />} */}
      {menu && menu.items ? generateMenu() : null}
      {/* {data && data.pivot ? (
        <Descriptions>
          <Descriptions.Item label="Status">
            {applicantStatusObj[data.pivot.status]}
          </Descriptions.Item>
        </Descriptions>
      ) : null} */}
      <AddMenuItemDialog
        menuCategoryUuid={menu && menu.uuid}
        isVisible={state?.showAddMenuItemDialog}
        onSubmit={addMenuItem}
        onCancel={closeAddMenuItemDialog}
      />

      <EditMenuItemDialog
        itemUuid={state?.itemUuid}
        isVisible={state?.showEditMenuItemDialog}
        onSubmit={editMenuItem}
        onCancel={closeEditMenuItemDialog}
      />

      <EditMenuCategoryDialog
        data={menu || data}
        isVisible={state?.showEditMenuCategoryDialog}
        onSubmit={editMenuCategory}
        onCancel={closeEditMenuCategoryDialog}
      />

      <AddChildItemDialog
        itemUuid={state?.itemUuid}
        isVisible={state?.showAddChildItemDialog}
        onSubmit={addChildItem}
        onCancel={closeAddChildItemDialog}
      />
    </div>
  );
};

MenuCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired
};

export default MenuCard;
