import http from './http';

const RESOURCE_URL = '/menu/item';

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const addChildItem = data => {
  return new Promise((resolve, reject) => {
    http
      .post('/menu/item/child', data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const update = data => {
  return new Promise((resolve, reject) => {
    http
      .put(`${RESOURCE_URL}/${data.uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getList = (
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT,
  additionalParams = {}
) => {
  return new Promise((resolve, reject) => {
    const params = {
      p: page,
      l: limit,
      ...additionalParams
    };
    http
      .get(RESOURCE_URL, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const get = uuid => {
  return new Promise((resolve, reject) => {
    http
      .get(`${RESOURCE_URL}/${uuid}?with=children`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const deleteMenuItem = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`${RESOURCE_URL}/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  add,
  addChildItem,
  update,
  getList,
  get,
  deleteMenuItem
};
