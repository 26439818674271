import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import user from './user/userReducer';
import config from './config/configReducer';

const reducers = combineReducers({
  user,
  config
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
