import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';

import { validate, FormItemWithError } from '../../../../../shared/validations';
import InputTypes from '../../../../../assets/json/InputTypes.json';

const InputSubTypes = InputTypes.filter(inputType => inputType !== 'array');
const { Option } = Select;
const initialState = {
  name: '',
  type: undefined
};

const AddInputDialog = ({ isVisible, onSubmit, onCancel, editInputData }) => {
  const [input, setInput] = useState(initialState);
  const [error, setError] = useState({});

  const resetState = () => {
    setInput(initialState);
  };

  const submit = () => {
    const requiredFields = ['name', 'type'];
    if (input.type === 'array') {
      requiredFields.push('sub_type');
    }
    const valid = validate(input, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    onSubmit(input);
    resetState();
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  useEffect(() => {
    if (editInputData && editInputData.type) {
      setInput(editInputData);
    }
  }, [editInputData]);

  return (
    <Modal
      title={editInputData && editInputData.name ? 'Edit Input' : 'Add Input'}
      centered
      visible={isVisible}
      okText={editInputData && editInputData.name ? 'Update' : 'Add'}
      width={586}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-input-dialog"
    >
      <Form>
        <Form.Item label="Name" required>
          {FormItemWithError(
            <Input
              size="large"
              placeholder="Name"
              value={input.name}
              onChange={e => setInput({ ...input, name: e.target.value })}
            />,
            'name',
            error
          )}
        </Form.Item>
        <Form.Item label="Select type" required>
          {FormItemWithError(
            <Select
              size="large"
              placeholder="Select type"
              defaultActiveFirstOption={false}
              showArrow={true}
              value={input.type}
              style={{ width: '100%' }}
              onChange={value => setInput({ ...input, type: value })}
            >
              {InputTypes &&
                InputTypes.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
            </Select>,
            'type',
            error
          )}
        </Form.Item>
        {input && input.type === 'array' ? (
          <Form.Item label="Select sub type" required>
            {FormItemWithError(
              <Select
                size="large"
                placeholder="Select sub type"
                defaultActiveFirstOption={false}
                showArrow={true}
                value={input.sub_type}
                style={{ width: '100%' }}
                onChange={value => setInput({ ...input, sub_type: value })}
              >
                {InputSubTypes &&
                  InputSubTypes.map((type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  ))}
              </Select>,
              'sub_type',
              error
            )}
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};

AddInputDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editInputData: PropTypes.objectOf(PropTypes.any)
};

AddInputDialog.defaultProps = {
  editInputData: null
};

export default AddInputDialog;
