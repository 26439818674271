const addDays = (theDate, days) => {
  return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
};
const minusDays = (theDate, days) => {
  return new Date(theDate.getTime() - days * 24 * 60 * 60 * 1000);
};
export const dateFilter = val => {
  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  if (val) {
    const date = new Date(val);
    const today = new Date();
    const yesterday = minusDays(today, 1);
    const tomorrow = addDays(today, 1);
    const startOfLastWeek = minusDays(today, 7);
    if (today.toDateString() === date.toDateString()) {
      return 'Today';
    }
    if (
      today.getTime() > date.getTime() &&
      date.getTime() > startOfLastWeek.getTime()
    ) {
      if (
        yesterday.getFullYear() === date.getFullYear() &&
        yesterday.getMonth() === date.getMonth() &&
        yesterday.getDate() === date.getDate()
      ) {
        return 'Yesterday';
      }
      return weekDays[date.getDay()];
    }
    if (
      tomorrow.getFullYear() === date.getFullYear() &&
      tomorrow.getMonth() === date.getMonth() &&
      tomorrow.getDate() === date.getDate()
    ) {
      return 'Tomorrow';
    }
    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
    return formattedDate;
  }
  return 'Date Not Available';
};

export const CalculateExperienceInYears = (startingDate, endingDate) => {
  const startDate = new Date(startingDate);
  const endDate = new Date(endingDate);
  const DAYS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  const utcStart = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  const utcEnd = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  return ((utcEnd - utcStart) / DAYS_PER_YEAR).toFixed(1);
};
