/* eslint-disable camelcase */
import './Amenities.less';

import { Button, Empty, Modal, Select, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AddAmenityDialog from '../../components/AddAmenityDialog/AddAmenityDialogHOC';
import List from './List/List';
import Loader from '../../components/Loader/Loader';
import api from '../../api';

const { Option } = Select;
const { confirm } = Modal;
const Amenities = () => {
  const [state, setState] = useState({
    amenities: [],
    userGeneratedAmenities: [],
    editAmenityData: {},
    page: 1,
    ugPage: 1, // user generated skills pagination
    pageEnd: false,
    loading: false,
    amenitiesLoaded: false,
    showAddAmenityDialog: false,
    searchedAmenities: [],
    selectedAmenities: []
  });

  const fetchAmenities = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.amenities
        .getList(
          updatedState.searchAmenities,
          updatedState.page,
          process.env.REACT_APP_PAGE_LIMIT
        )
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              amenities: [...updatedState.amenities, ...resp.items],
              amenitiesLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.page]
  );

  const fetchUserGeneratedAmenities = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.amenities
        .getList('', updatedState.page, process.env.REACT_APP_PAGE_LIMIT, {
          is_user_generated: true
        })
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              userGeneratedAmenities: [
                ...updatedState.userGeneratedAmenities,
                ...resp.items
              ],
              amenitiesLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.ugPage]
  );

  const searchCalled = options => {
    const stateObj = { ...state };
    stateObj.amenities = [];
    stateObj.amenitiesLoaded = false;
    stateObj.editAmenityData = {};
    const amenitiesArray = options.map(option => option.key);
    stateObj.searchAmenities = amenitiesArray.length
      ? amenitiesArray.reduce((query, item) => `${query},${item}`)
      : '';
    stateObj.selectedAmenities = options.map(option => option.props.value);
    setState({ ...stateObj });
    fetchAmenities(stateObj);
  };

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: stateObj.page + 1 }));
    }
  };

  const openAddAmenityDialog = () =>
    setState(stateObj => ({ ...stateObj, showAddAmenityDialog: true }));

  const closeAddAmenityDialog = () =>
    setState(stateObj => ({
      ...stateObj,
      showAddAmenityDialog: false,
      editAmenityData: {}
    }));

  const updateAmenitiesList = data => {
    if (state.editAmenityData.uuid) {
      const index = state.amenities.findIndex(
        amenity => amenity.uuid === data.uuid
      );
      const updatedAmenities = [...state.amenities];
      updatedAmenities[index] = data;
      setState(stateObj => ({ ...stateObj, amenities: updatedAmenities }));
      message.success('Amenity edited successfully!');
    } else {
      setState(stateObj => ({
        ...stateObj,
        amenities: [data, ...stateObj.amenities]
      }));
      message.success('Amenity added successfully!');
    }
    closeAddAmenityDialog();
  };

  const editAmenityHandler = (uuid, user_generated = false) => {
    let AmenityToEdit;
    if (user_generated) {
      AmenityToEdit = state.userGeneratedAmenities.find(
        amenity => amenity.uuid === uuid
      );
    } else {
      AmenityToEdit = state.amenities.find(amenity => amenity.uuid === uuid);
    }
    setState(stateObj => ({
      ...stateObj,
      showAddAmenityDialog: true,
      editAmenityData: {
        uuid: AmenityToEdit.uuid,
        name: AmenityToEdit.name,
        slug: AmenityToEdit.slug
      }
    }));
  };

  const deleteAmenityHandler = (uuid, index, user_generated = false) => {
    api.amenities.softDelete(uuid);
    const updatedAmenities = user_generated
      ? [...state.userGeneratedAmenities]
      : [...state.amenities];
    updatedAmenities.splice(index, 1);
    if (user_generated) {
      setState(stateObj => ({
        ...stateObj,
        userGeneratedAmenities: updatedAmenities
      }));
    } else {
      setState(stateObj => ({ ...stateObj, amenities: updatedAmenities }));
    }
    message.success('Skill deleted successfully!');
  };

  const showDeleteConfirmDialog = (uuid, index, user_generated = false) => {
    confirm({
      title: 'Do you want to Delete Amenity?',
      content: `The ${state.amenities[index].name} amenity will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAmenityHandler(uuid, index, user_generated);
      }
    });
  };

  const fetchAmenityOptions = (query = '') => {
    api.ninja
      .fetchAmenities(query)
      .then(resp => {
        if (resp && resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            searchedAmenities: [...resp.items]
          }));
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchAmenities({ ...state });
      // eslint-disable-next-line no-console
      // fetchUserGeneratedAmenities({ ...state });
      fetchAmenityOptions();
    } // eslint-disable-next-line
  }, [state.page, state.pageEnd, fetchAmenities]);

  return (
    <div className="skills-page">
      <div className="header">
        <h2>Amenities</h2>
        <div className="search-bar">
          <Select
            mode="multiple"
            placeholder="Search amenities"
            loading={state.loading}
            value={state?.selectedAmenities}
            size="large"
            style={{ width: '100%', paddingRight: '1rem' }}
            onChange={(values, options) => searchCalled(options)}
            onSearch={query => fetchAmenityOptions(query)}
            maxTagCount={2}
          >
            {state?.searchedAmenities &&
              state?.searchedAmenities.length &&
              state?.searchedAmenities.map(amenity => (
                <Option key={amenity.uuid} value={amenity.name}>
                  {amenity.name}
                </Option>
              ))}
          </Select>
          <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={openAddAmenityDialog}
          >
            Add Amenity
          </Button>
        </div>
      </div>
      <div
        className="skills-list-wrapper"
        style={!state.amenitiesLoaded ? { position: 'relative' } : null}
        onScroll={handleScroll}
      >
        {state.loading && <Loader infiniteLoader={state.amenitiesLoaded} />}
        {!state.amenities.length && !state.loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <List
            amenities={state.amenities}
            editAmenityHandler={editAmenityHandler}
            deleteAmenityHandler={showDeleteConfirmDialog}
            userGeneratedAmenities={state.userGeneratedAmenities}
          />
        )}
      </div>
      <AddAmenityDialog
        isVisible={state.showAddAmenityDialog}
        onSubmit={updateAmenitiesList}
        onCancel={closeAddAmenityDialog}
        editAmenityData={state.editAmenityData}
      />
    </div>
  );
};

export default Amenities;
