import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Icon, Modal } from 'antd';

import './Inputs.less';
import AddInputDialog from './AddInputDialog/AddInputDialog';

const { confirm } = Modal;

const Inputs = ({ onSubmit, targetProblem }) => {
  const [state, setState] = useState({
    inputs: [],
    showAddInputDialog: false,
    editInputData: {},
    editInputIndex: null
  });

  const openAddInputsDialog = () => {
    setState({ ...state, showAddInputDialog: true });
  };

  const closeAddInputsDialog = () => {
    const stateObj = { ...state };
    stateObj.showAddInputDialog = false;
    stateObj.editInputData = {};
    stateObj.editInputIndex = null;
    setState(stateObj);
  };

  const updateInputList = data => {
    const stateObj = { ...state };
    if (stateObj.editInputIndex === null) {
      stateObj.inputs.push(data);
    } else {
      stateObj.inputs[stateObj.editInputIndex] = data;
      stateObj.editInputIndex = null;
    }
    stateObj.showAddInputDialog = false;
    setState(stateObj);
    onSubmit(stateObj.inputs);
  };

  const editInputHandler = (input, index) => {
    const stateObj = { ...state };
    const editInput = {
      name: input.name,
      type: input.type
    };
    if (input.sub_type) {
      editInput.sub_type = input.sub_type;
    }
    stateObj.editInputData = editInput;
    stateObj.editInputIndex = index;
    stateObj.showAddInputDialog = true;
    setState(stateObj);
    onSubmit(stateObj.inputs);
  };

  const deleteInputHandler = index => {
    const stateObj = { ...state };
    stateObj.inputs.splice(index, 1);
    setState(stateObj);
    onSubmit(stateObj.inputs);
  };

  const showDeleteConfirmDialog = (input, index) => {
    confirm({
      title: 'Do you want to Delete Input?',
      content: `Input ${input.name} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteInputHandler(index);
      }
    });
  };

  const prepareInputsRow = (input, index) => {
    return (
      <div className="inputs-row" key={`${input.name}-${index}`}>
        <div className="inputs-row-item">{input.name}</div>
        <div className="inputs-row-item">{input.type}</div>
        <div className="inputs-row-item">
          {input.sub_type ? input.sub_type : '-'}
        </div>
        <div className="inputs-row-item actions">
          <Icon
            type="edit"
            theme="outlined"
            onClick={() => editInputHandler(input, index)}
          />
          <Icon
            type="delete"
            theme="outlined"
            onClick={() => showDeleteConfirmDialog(input, index)}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (targetProblem && targetProblem.uuid) {
      const stateObj = { ...state };
      stateObj.inputs = targetProblem.inputs;
      setState(stateObj);
    }
    // eslint-disable-next-line
  }, [targetProblem]);

  return (
    <div className="problem-inputs-form">
      <h4>Inputs</h4>
      <Button className="add-input" onClick={openAddInputsDialog}>
        Add Input
      </Button>
      <div className="inputs-list">
        <div className="inputs-row header-row">
          <div className="inputs-row-item column-title">Name</div>
          <div className="inputs-row-item column-title">Type</div>
          <div className="inputs-row-item column-title">Sub Type</div>
          <div className="inputs-row-item column-title">Actions</div>
        </div>
        {!state.inputs.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          state.inputs.map((input, index) => prepareInputsRow(input, index))
        )}
      </div>
      <AddInputDialog
        isVisible={state.showAddInputDialog}
        editInputData={state.editInputData}
        onSubmit={updateInputList}
        onCancel={closeAddInputsDialog}
      />
    </div>
  );
};

Inputs.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  targetProblem: PropTypes.objectOf(PropTypes.any)
};

Inputs.defaultProps = {
  targetProblem: {}
};

export default Inputs;
