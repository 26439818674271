import './List.less';

import { Icon, Table, Tabs } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

const { TabPane } = Tabs;

const List = ({
  amenities,
  editAmenityHandler,
  deleteAmenityHandler,
  userGeneratedAmenities
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        const A = a.name.toLowerCase();
        const B = b.name.toLowerCase();
        return A < B ? -1 : A > B ? 1 : 0;
      }
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        const A = a.slug.toLowerCase();
        const B = b.slug.toLowerCase();
        return A < B ? -1 : A > B ? 1 : 0;
      }
    },
    {
      title: 'Actions',
      render: (text, amenity, index) => (
        <>
          <Icon
            className="m-r"
            type="edit"
            theme="outlined"
            onClick={() =>
              editAmenityHandler(amenity.uuid, amenity.is_user_generated)
            }
          />
          <Icon
            type="delete"
            theme="outlined"
            onClick={() =>
              deleteAmenityHandler(
                amenity.uuid,
                index,
                amenity.is_user_generated
              )
            }
          />
        </>
      )
    }
  ];
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="All Skills" key="1">
        <Table
          className="skills-list"
          columns={columns}
          dataSource={amenities}
          pagination={false}
        />
      </TabPane>
      <TabPane tab="User Generated" key="2">
        <Table
          className="skills-list"
          columns={columns}
          dataSource={userGeneratedAmenities}
          pagination={false}
        />
      </TabPane>
    </Tabs>
  );
};

List.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.any).isRequired,
  editAmenityHandler: PropTypes.func.isRequired,
  deleteAmenityHandler: PropTypes.func.isRequired,
  userGeneratedAmenities: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default List;
