import http from './http';

const RESOURCE_URL = '/problem';

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const update = data => {
  return new Promise((resolve, reject) => {
    http
      .put(`${RESOURCE_URL}/${data.uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getProblems = (
  query = '',
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT,
  level
) => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      p: page,
      l: limit,
      level
    };
    http
      .get(RESOURCE_URL, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getProblemDetails = problem => {
  return new Promise((resolve, reject) => {
    http
      .get(`${RESOURCE_URL}/${problem.uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  add,
  update,
  getProblems,
  getProblemDetails
};
