import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Divider, Empty } from 'antd';

const Certification = ({ ninjaCertification }) => {
  return (
    <div className="certification m-b">
      <div className="t-b m-b">Certifications:</div>
      {ninjaCertification.length ? (
        ninjaCertification.map(certification => (
          <CertificationItem
            certification={certification}
            key={certification.uuid}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

const CertificationItem = ({ certification }) => (
  <div className="certification-item">
    <Descriptions>
      <Descriptions.Item label="Title">{certification.title}</Descriptions.Item>
      <Descriptions.Item label="Organization">
        {certification.organization}
      </Descriptions.Item>
      <Descriptions.Item label="Started at">
        {certification.from_date}
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="Description">
        {certification.description}
      </Descriptions.Item>
    </Descriptions>
    <Divider className="small divider" />
  </div>
);

Certification.propTypes = {
  ninjaCertification: PropTypes.arrayOf(PropTypes.any).isRequired
};

CertificationItem.propTypes = {
  certification: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Certification;
