import { connect } from 'react-redux';
import NinjaCard from './NinjaCard';

const mapStateToProps = state => ({
  ninjasStatus:
    state.config.meta.ninja && state.config.meta.ninja.status
      ? state.config.meta.ninja.status
      : [],
  ninjaCommitment:
    state.config.meta.ninja && state.config.meta.ninja.commitment
      ? state.config.meta.ninja.commitment
      : {}
});

export default connect(mapStateToProps)(NinjaCard);
