import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

import Loader from '../../../components/Loader/Loader';
import NinjaCard from '../../../components/NinjaCard/NinjaCardHOC';

const List = ({ ninjas, loading, pageLoaded, handleScroll, openDetails }) => {
  return (
    <div>
      {!ninjas.length && !loading ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div
          className="ninjas-list"
          onScroll={handleScroll}
          style={!pageLoaded ? { position: 'relative' } : null}
        >
          {loading && <Loader infiniteLoader={pageLoaded} />}
          {ninjas &&
            ninjas.map((ninja, index) => (
              <NinjaCard
                key={`${ninja.uuid}-${index}`}
                data={{ ...ninja }}
                openDetails={openDetails}
                col={3}
              />
            ))}
        </div>
      )}
    </div>
  );
};

List.propTypes = {
  ninjas: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  openDetails: PropTypes.func.isRequired,
  pageLoaded: PropTypes.bool,
  handleScroll: PropTypes.func.isRequired
};

List.defaultProps = {
  ninjas: [],
  pageLoaded: false
};

export default List;
