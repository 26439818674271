import http from './http';
import { removeEmpty } from '../shared/utility';

const get = uuid => {
  return new Promise((resolve, reject) => {
    const params = {
      with: 'work,portfolio,education,certification,socialAccount,jobs'
    };
    http
      .get(`/ninja/${uuid}`, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const getList = (
  query = '',
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT,
  filters
) => {
  return new Promise((resolve, reject) => {
    const params = {
      p: page,
      l: limit,
      q: query,
      ...filters
    };
    if (filters && filters.experience_started)
      params.experience_started = `${filters.experience_started[0]},${
        filters.experience_started[1] === 10
          ? 50
          : filters.experience_started[1]
      }`;
    http
      .get('/ninja', removeEmpty(params))
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const update = (uuid, data) => {
  return new Promise((resolve, reject) => {
    http
      .put(`/ninja/${uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const softDelete = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`/ninja/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const getJobs = (uuid, filters) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/ninja/${uuid}/job`, removeEmpty(filters))
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const fetchAmenities = query => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      l: 3,
      o: 'name,asc'
    };
    http
      .get('/amenity', params)
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
};
const fetchCategories = query => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      l: 3,
      o: 'name,asc'
    };
    http
      .get('/category', params)
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const fetchTags = query => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      l: 3,
      o: 'name,asc'
    };
    http
      .get('/tag', params)
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default {
  get,
  getList,
  update,
  softDelete,
  getJobs,
  fetchAmenities,
  fetchCategories,
  fetchTags
};
