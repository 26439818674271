/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Descriptions,
  Card,
  message,
  Col,
  Select,
  Empty,
  Modal
} from 'antd';

import './Details.less';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api';
import Loader from '../../../components/Loader/Loader';
import BranchCard from '../../../components/BranchCard/BranchCardCardHOC';
import BranchDetailsDrawer from '../../../components/BranchDetailsDrawer/BranchDetailsDrawer';
import EditBusinessDialog from '../../../components/EditBusinessDialog/EditBusinessDialog';
import { dateFilter } from '../../../shared/dateFilter';
import { toggleBusinessUpdated } from '../../../store/config/configAction';
import AdminSet from '../../../components/AdminSet/AdminSet';
import AddEditBusiness from '../AddEditBusiness/AddEditBusiness';
import AddEditBranch from '../../Branches/AddEditBranch/AddEditBranch';

const { confirm } = Modal;

const Details = ({ branchStatus, onDelete }) => {
  const { businessId } = useParams();
  const businessUpdated = useSelector(state => state.config.businessUpdated);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    businessDetails: {},
    branchesList: [],
    totalBranches: 0,
    loadingBranches: false,
    selectedBranch: {},
    loading: false,
    showBranchDetailsDrawer: false,
    showEditBusinessDialog: false,
    page: 1,
    pageEnd: false,
    selectedBranchStatus: null,
    updateBusiness: false,
    showMarkedBusinessDrawer: false,
    showMarkedBranchDrawer: false,
    selectedBusiness: {},
    updateBranch: false,
    branches: [],
    businesses: []
  });
  const [branchToEdit, setBranchToEdit] = useState(null);

  const [mainBranch, setMainBranch] = useState(false);
  const [isAdvertiser, setIsAdvertiser] = useState(false);
  const [isClaimaed, setIsClaimed] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);

  useEffect(() => {
    if (state.businessDetails && state.businessDetails.branches) {
      state.businessDetails.branches.forEach(branch => {
        if (branch.is_main) {
          setMainBranch(branch);
        }
      });
    }
    if (state.businessDetails.is_advertiser) {
      setIsAdvertiser(!state.businessDetails.is_advertiser);
    }
    if (state.businessDetails.is_claimed) {
      setIsClaimed(!state.businessDetails.is_claimed);
    }
    if (state.businessDetails.is_claimable) {
      setIsClaimable(!state.businessDetails.is_claimable);
    }
  }, [state.businessDetails]);

  const openBranchDetails = selectedBranch => {
    setState(stateObj => ({
      ...stateObj,
      selectedBranch,
      showBranchDetailsDrawer: true
    }));
  };

  const fetchBusinessBranches = useCallback(
    (page = 1, status = null) => {
      setState(stateObj => ({ ...stateObj, loadingBranches: true }));
      api.business
        .getBranches(businessId, status, page)
        .then(resp => {
          resp && resp.items && resp.items.length
            ? setState(stateObj => ({
                ...stateObj,
                branchesList:
                  page > 1
                    ? [...stateObj.branchesList, ...resp.items]
                    : [...resp.items],
                totalBranches: resp.total_count,
                loadingBranches: false
              }))
            : setState(stateObj => ({
                ...stateObj,
                loadingBranches: false,
                pageEnd: true
              }));
        })
        .catch(error => {
          setState(stateObj => ({ ...stateObj, loadingBranches: false }));
          message.error(error.message);
        });
    },
    [businessId]
  );

  const closeBranchDetails = () => {
    fetchBusinessBranches();
    setState(stateObj => ({ ...stateObj, showBranchDetailsDrawer: false }));
  };

  const fetchBusinessDetails = useCallback(() => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.business
      .get(businessId)
      .then(resp => {
        resp && resp.uuid
          ? setState(stateObj => ({
              ...stateObj,
              businessDetails: resp,
              loading: false,
              selectedBranchStatus: null
            }))
          : setState(stateObj => ({
              ...stateObj,
              loading: false,
              selectedBranchStatus: null
            }));
      })
      .catch(error => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(error.message);
      });
  }, [businessId]);

  const duplicateBranch = uuid => {
    console.log('duplicate branch', uuid); // eslint-disable-line no-console
    api.branches
      .duplicate(uuid)
      .then(resp => {
        message.success('Branch duplicated successfully!');
      })
      .catch(err => {
        message.error(err.message);
      });
    // const updatedBusinesses = state.businesses;
    // const targetIndex = updatedBusinesses.findIndex(
    //   business => business.uuid === uuid
    // );
    // updatedBusinesses.splice(targetIndex, 1);
    // setState(stateObj => ({ ...stateObj, businesses: updatedBusinesses }));
    // history.push(
    //   `/businesses/${updatedBusinesses.length ? updatedBusinesses[0].uuid : ''}`
    // );
  };

  const deleteBranch = uuid => {
    api.branches.remove(uuid);
    message.success('Branch deleted successfully!');
  };

  const openEditBusinessDialog = () => {
    setState(stateObj => ({ ...stateObj, showEditBusinessDialog: true }));
  };

  const closeEditBusinessDialog = () => {
    setState(stateObj => ({ ...stateObj, showEditBusinessDialog: false }));
  };

  const updateBusinessDetails = data => {
    setState(stateObj => ({ ...stateObj, businessDetails: { ...data } }));
    dispatch(toggleBusinessUpdated(!businessUpdated));
    closeEditBusinessDialog();
  };

  const open = e => {
    e.stopPropagation();
    window.open(
      `${state.businessDetails.website.includes('https://') ? '' : 'https://'}${
        state.businessDetails.website
      }`
    );
  };

  const handleBranchSort = value => {
    /* Reset page and scroll end on switching sort status */
    setState(stateObj => ({
      ...stateObj,
      selectedBranchStatus: value,
      page: 1,
      pageEnd: false
    }));
    fetchBusinessBranches(1, value);
  };

  const showConfirmDialog = () => {
    confirm({
      title: 'Are you sure you want to delete this business?',
      content: `${state.businessDetails.name} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(state.businessDetails.uuid);
      }
    });
  };

  const HeaderButtons = () => (
    <div>
      <Button type="link" icon="edit" onClick={openEditBusinessDialog}>
        Edit
      </Button>
      <Button type="primary" onClick={showConfirmDialog} className="delete">
        Delete
      </Button>
    </div>
  );

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
        element.clientHeight &&
      !state.pageEnd
    ) {
      fetchBusinessBranches(state.page + 1, state.selectedBranchStatus);
      setState(stateObj => ({ ...stateObj, page: stateObj.page + 1 }));
    }
  };

  const onBranchUpdate = updatedBranch => {
    const branches = [...state.branchesList];
    const index = branches.findIndex(
      branch => branch.uuid === updatedBranch.uuid
    );
    branches[index] = { ...branches[index], ...updatedBranch };
    setState(cState => ({ ...cState, branchesList: branches }));
  };

  const getSubscribedPlan = () => {
    const subscription = state.businessDetails?.subscription;
    if (subscription) {
      if (!subscription.is_cancelled) {
        const planName = subscription.plan_id;
        return planName ? planName.replace('_', ' ') : 'N/A';
      }
    }

    return <span className="text-italic">No Subscription</span>;
  };

  useEffect(() => {
    fetchBusinessDetails();
    fetchBusinessBranches();
  }, [fetchBusinessDetails, fetchBusinessBranches]);

  useEffect(() => {
    setState(stateObj => ({
      ...stateObj,
      branchesList: [],
      totalBranches: 0,
      page: 1,
      pageEnd: false
    }));
  }, [businessId]);

  const closeMarkedBusinessDrawer = () => {
    setState(stateObj => ({
      ...stateObj,
      selectedBusiness: {},
      showMarkedBusinessDrawer: false
    }));
  };

  const closeMarkedBranchDrawer = () => {
    setState(stateObj => ({
      ...stateObj,
      branch: {},
      showMarkedBranchDrawer: false
    }));
  };

  const openMarkedBusinessDrawer = () => {
    setState(stateObj => ({ ...stateObj, showMarkedBusinessDrawer: true }));
  };

  const openMarkedBranchDrawer = () => {
    setState(stateObj => ({ ...stateObj, showMarkedBranchDrawer: true }));
  };

  const onAddBusiness = () => {
    setState(stateObj => ({ ...stateObj, updateBusiness: false }));
    openMarkedBusinessDrawer();
  };

  const onAddBranch = () => {
    setState(stateObj => ({ ...stateObj, updateBranch: false }));
    openMarkedBranchDrawer();
  };

  const editBranch = uuid => {
    const tempBranch = state.branchesList.filter(
      branch => branch.uuid === uuid
    );
    console.log('tempBranch', tempBranch[0]);
    setBranchToEdit(tempBranch[0]);
    setState(stateObj => ({ ...stateObj, updateBranch: true }));
    openMarkedBranchDrawer();
  };

  const onSelectBusiness = business => {
    setState(stateObj => ({
      ...stateObj,
      selectedBusiness: business,
      updateBusiness: true
    }));
    openMarkedBusinessDrawer();
  };

  const updateMarkedBusiness = data => {
    const index = state.businesses.findIndex(
      business => business.uuid === data.uuid
    );
    const updatedBusinesses = [...state.businesses];
    updatedBusinesses[index] = data;
    setState(stateObj => ({ ...stateObj, businesses: updatedBusinesses }));
    closeMarkedBusinessDrawer();
  };

  const updateMarkedBranch = data => {
    console.log('updateMarkedBranch data', data);
    const index = state.branches.findIndex(branch => branch.uuid === data.uuid);
    const updatedBranches = [...state.branches];
    updatedBranches[index] = data;
    setState(stateObj => ({ ...stateObj, branches: updatedBranches }));
    closeMarkedBranchDrawer();
  };

  const appendMarkedBusiness = business => {
    setState(stateObj => ({
      ...stateObj,
      businesses: [business, ...state.businesses]
    }));
    closeMarkedBusinessDrawer();
  };

  const appendMarkedBranch = branch => {
    setState(stateObj => ({
      ...stateObj,
      branches: [branch, ...state.branches]
    }));
    closeMarkedBranchDrawer();
  };

  const generateBranchesList = () => {
    return (
      <>
        {state.branchesList.map(branch => (
          <BranchCard
            key={branch.uuid}
            data={{ ...branch }}
            openDetails={openBranchDetails}
            col={3}
            onDuplicateBranch={duplicateBranch}
            onDelete={deleteBranch}
            onEdit={editBranch}
          />
        ))}
      </>
    );
  };

  return (
    <div className="business-details-page" onScroll={handleScroll}>
      <div className="header">
        <h2>Businesses</h2>
        <Button type="primary" size="large" onClick={() => onAddBusiness()}>
          Add Business
        </Button>
      </div>
      {(state?.loading || state?.loadingBranches) && <Loader />}
      <Card title={state?.businessDetails.name} extra={<HeaderButtons />}>
        <Descriptions className="p-t" column={2}>
          <Descriptions.Item label="Website">
            <Button type="link" className="link-button" onClick={open}>
              {state?.businessDetails?.website
                ? state?.businessDetails?.website
                : 'N/A'}
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {state?.businessDetails?.user?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Total Branches">
            {state?.businessDetails?.total_branches}
          </Descriptions.Item>
          <Descriptions.Item label="Owner">
            {state?.businessDetails?.user
              ? `${state?.businessDetails?.user?.first_name} ${state?.businessDetails?.user?.last_name}`
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Active Branches">
            {state?.businessDetails?.active_branches}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Main Branch Location">
            {mainBranch && mainBranch.location
              ? `${mainBranch.location.sector}, ${mainBranch.location.city}, ${mainBranch.location.country}`
              : 'N/A'}
          </Descriptions.Item> */}
          <Descriptions.Item label="Is Advertiser">
            {isAdvertiser ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Is Claimed">
            {isClaimaed ? 'Yes' : 'No'}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Is Claimable">
            {isClaimable ? 'Yes' : 'No'}
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Price Range">
            {state.businessDetails.price_range}
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Type">
            {state?.businessDetails?.type}
          </Descriptions.Item> */}
          <Descriptions.Item label="Created">
            {dateFilter(state?.businessDetails?.created_at)}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Subscribed Plan" className="capitalize">
            {getSubscribedPlan()}
          </Descriptions.Item> */}
          <br />
          <Descriptions.Item label="Admins">
            {state?.businessDetails?.admins?.length ? (
              <AdminSet admins={state.businessDetails.admins} />
            ) : (
              'N/A'
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <EditBusinessDialog
        businessDetails={state?.businessDetails}
        isVisible={state?.showEditBusinessDialog}
        onSubmit={updateBusinessDetails}
        onCancel={closeEditBusinessDialog}
      />
      <Card
        title={`Branches (${state?.totalBranches})`}
        className="m-t"
        extra={
          <Col>
            <Button type="primary" size="medium" onClick={() => onAddBranch()}>
              Add Branch
            </Button>
            <Select
              defaultValue="All Branches"
              style={{ width: '120px', 'margin-left': '25px' }}
              onChange={handleBranchSort}
              value={state.selectedBranchStatus}
            >
              <Select.Option value={null}>All Branches</Select.Option>
              {Object.keys(branchStatus).map(key => (
                <Select.Option value={branchStatus[key].value} key={key}>
                  {branchStatus[key].name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        }
      >
        {!state?.branchesList.length && !state?.loadingBranches ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          generateBranchesList()
        )}
      </Card>
      <BranchDetailsDrawer
        isVisible={state?.showBranchDetailsDrawer}
        onCancel={closeBranchDetails}
        data={state?.selectedBranch}
        media={state?.selectedBranch?.media}
        onUpdate={onBranchUpdate}
      />
      <AddEditBusiness
        isVisible={state.showMarkedBusinessDrawer}
        onCancel={closeMarkedBusinessDrawer}
        onSubmit={
          state.updateBusiness ? updateMarkedBusiness : appendMarkedBusiness
        }
        targetBusiness={JSON.parse(JSON.stringify(state.selectedBusiness))}
        editBusiness={state.updateBusiness}
      />
      <AddEditBranch
        isVisible={state.showMarkedBranchDrawer}
        onCancel={closeMarkedBranchDrawer}
        onSubmit={state.updateBranch ? updateMarkedBranch : appendMarkedBranch}
        // JSON.parse(JSON.stringify(state.selectedBranch))
        targetBranch={branchToEdit}
        editBranch={state.updateBranch}
        business_uuid={state?.businessDetails?.uuid}
      />
    </div>
  );
};

Details.propTypes = {
  branchStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Details;
