import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

import './List.less';
import Loader from '../../../components/Loader/Loader';
import CompanyCard from '../../../components/BusinessCard/BusinessCard';

const List = ({ businesses, loading, pageLoaded, page, setPage }) => {
  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      console.log("business set page called");
      setPage(page + 1);
    }
  };

  return (
    <div>
      {!businesses.length && !loading ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div
          className="businesses-list"
          onScroll={handleScroll}
          style={!pageLoaded ? { position: 'relative' } : null}
        >
          {loading && <Loader infiniteLoader={pageLoaded} />}
          {businesses &&
            businesses.map(business => (
              <CompanyCard key={business.uuid} data={business} />
            ))}
        </div>
      )}
    </div>
  );
};

List.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loading: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};

export default List;
