import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Row, Col, message } from 'antd';

import Details from '../../pages/Branches/Details/DetailsHOC';
import UpdateBranchStatusDropdown from '../UpdateBranchStatusDropdown/UpdateBranchStatusDropdown';
import api from '../../api';

const BranchDetailsDrawer = ({
  isVisible,
  data,
  media,
  onCancel,
  onUpdate
}) => {
  const [branchDetails, setBranchDetails] = useState({});
  useEffect(() => {
    console.log('data = ', data);
    if (JSON.stringify(data) !== '{}') {
      api.branches.get(data.uuid).then(resp => {
        setBranchDetails(resp);
      });
    }
  }, [data]);

  // useEffect(() => {
  //   if (data && data.uuid) {
  //     api.branches.get(data.uuid).then(resp => {
  //       setBranchDetails(resp);
  //     });
  //   }
  // }, [data, data.uuid]);

  // useEffect(() => {
  //   console.log('AGAIN: ', data);
  //   api.branches.get(data.uuid).then(resp => {
  //     setBranchDetails(resp);
  //   });
  // }, [media]);

  const drawerTitleGenerator = () => {
    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col>{branchDetails?.name}</Col>
        <Col>
          <UpdateBranchStatusDropdown
            className="m-r-2"
            data={branchDetails}
            onUpdate={onUpdate}
          />
        </Col>
      </Row>
    );
  };

  // const close = () => {
  //   api.branches.get(data.uuid).then(resp => {
  //     setBranchDetails(resp);
  //   });
  // }

  return (
    <Drawer
      title={drawerTitleGenerator()}
      placement="right"
      width="50%"
      className="brach-details-drawer"
      onClose={onCancel}
      visible={isVisible}
      getContainer={false}
      destroyOnClose={true}
    >
      <Details data={branchDetails} />
    </Drawer>
  );
};

BranchDetailsDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  media: PropTypes.array,
  onUpdate: PropTypes.func.isRequired
};

BranchDetailsDrawer.defaultProps = {
  data: {}
};

export default BranchDetailsDrawer;
