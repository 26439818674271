import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from 'antd';

const InputValue = ({ defaultValue, type, subType, onUpdate }) => {
  const [inputType, setInputType] = useState();
  const [value, setValue] = useState(undefined);
  const [placeholder, setPlaceholder] = useState('Enter Value');

  useEffect(() => {
    type === 'integer' || type === 'float'
      ? setInputType('number')
      : setInputType('any');
    if (type === 'array') {
      subType === 'integer' || subType === 'float'
        ? setPlaceholder('Enter Comma separated Numbers!')
        : setPlaceholder('Enter Comma separated Values!');
    }
  }, [type, subType]);

  useEffect(() => {
    onUpdate(value);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      if (Array.isArray(defaultValue)) {
        setValue(defaultValue.toString());
      } else {
        setValue(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <>
      {type === 'boolean' ? (
        <Select
          size="large"
          placeholder="Select value"
          value={value}
          onChange={val => setValue(val)}
        >
          <Select.Option value={true}>True</Select.Option>
          <Select.Option value={false}>False</Select.Option>
        </Select>
      ) : (
        <Input
          type={inputType}
          size="large"
          placeholder={placeholder}
          value={value}
          onChange={e =>
            type === 'integer'
              ? setValue(Math.trunc(e.target.value))
              : setValue(e.target.value)
          }
        />
      )}
    </>
  );
};

InputValue.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  type: PropTypes.string,
  subType: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};

InputValue.defaultProps = {
  defaultValue: '',
  type: 'any',
  subType: ''
};

export default InputValue;
