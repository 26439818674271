import React from 'react';

const errorStyles = {
  color: 'red'
};
const toUpperCase = field => {
  const str = field.replace('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const isValidUrl = url => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(url);
};
export const validate = (targetObj, requiredFields) => {
  const errors = {};
  let isValid = true;
  requiredFields.forEach(field => {
    if (!(targetObj[field] || targetObj[field] === 0)) {
      isValid = false;
      errors[field] = {
        message: `${field} is required!`
      };
    }

    if (field === 'website' && !isValidUrl(targetObj[field])) {
      isValid = false;
      if (!errors[field])
        errors[field] = {
          message: `Invalid Website !`
        };
    }
  });
  return {
    isValid,
    errors
  };
};
export const validateIfNegative = (targetObj, requiredFields) => {
  const errors = {};
  let isValid = true;
  requiredFields.forEach(field => {
    if (targetObj[field] < 0) {
      isValid = false;
      errors[field] = {
        message: `${field} is negative!`
      };
    }
  });
  return {
    isValid,
    errors
  };
};
export const FormItemWithError = (children, key, errors) => {
  return (
    <div>
      {children}
      {errors && errors[key] && (
        <span style={errorStyles}>{toUpperCase(errors[key].message)}</span>
      )}
    </div>
  );
};
