import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message } from 'antd';

import './AddAmenityDialog.less';
import api from '../../api';
import { validate, FormItemWithError } from '../../shared/validations';
import Loader from '../Loader/Loader';

const requiredFields = ['name'];

const AddAmenityDialog = ({
  isVisible,
  onSubmit,
  onCancel,
  editAmenityData
}) => {
  const initialState = {
    name: ''
  };
  const [state, setState] = useState({
    amenity: editAmenityData && initialState,
    error: {},
    loading: false
  });

  const resetState = () => {
    const stateObj = { ...state };
    stateObj.amenity = initialState;
    stateObj.error = {};
    stateObj.loading = false;
    setState(stateObj);
  };

  const addUpdateAmenity = data => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    const call = data.uuid
      ? api.amenities.update(data)
      : api.amenities.add(data);
    call
      .then(resp => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        if (resp && resp.uuid) {
          resetState();
          onSubmit(resp);
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  };

  const submit = () => {
    const valid = validate(state.amenity, requiredFields);
    // eslint-disable-next-line no-console
    console.log('is valid : ', valid);
    !valid.isValid
      ? setState(stateObj => ({ ...stateObj, error: valid.errors }))
      : addUpdateAmenity(state.amenity);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  const handleChange = (field, value) => {
    const stateObject = { ...state };
    stateObject.amenity[field] = value;
    setState(stateObject);
  };

  useEffect(() => {
    if (editAmenityData && editAmenityData.uuid) {
      setState(stateObj => ({ ...stateObj, amenity: editAmenityData }));
    }
    // eslint-disable-next-line
  }, [editAmenityData]);

  return (
    <Modal
      title={
        state.amenity && state.amenity.uuid ? 'Edit Amenity' : 'Add New Amenity'
      }
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-skill-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Name" required>
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Name"
                value={state.amenity && state.amenity.name}
                onChange={e => handleChange('name', e.target.value)}
              />,
              'name',
              state.error
            )}
          </Form.Item>
          <Form.Item label="Slug">
            <Input
              size="large"
              placeholder="Slug"
              value={state.amenity && state.amenity.slug}
              onChange={e => handleChange('slug', e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddAmenityDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editAmenityData: PropTypes.objectOf(PropTypes.any)
};

AddAmenityDialog.defaultProps = {
  editAmenityData: null
};

export default AddAmenityDialog;
