import React, { useState } from 'react';
import { Button, Form, Icon, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Signin.less';
import { validate, FormItemWithError } from '../../shared/validations';
import api from '../../api';
import Loader from '../../components/Loader/Loader';

const Signin = ({ login }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    email: null,
    password: null
  });

  const loginHandler = () => {
    const { isValid, errors } = validate(user, ['email', 'password']);
    if (isValid) {
      setLoading(true);
      api.user
        .login(user)
        .then(resp => {
          setLoading(false);
          login(resp);
          history.push('dashboard');
        })
        .catch(err => {
          setLoading(false);
          if (err.message) message.error(err.message);
        });
    } else {
      setError(errors);
    }
  };

  return (
    <div className="signin-page">
      <div className="custom-card">
        <h2 className="t-c">Recce CMS</h2>
        {loading && <Loader />}
        <div>
          <Form>
            <Form.Item label="Email">
              {FormItemWithError(
                <Input
                  value={user.email}
                  placeholder="Email"
                  size="large"
                  className={error ? 'error' : ''}
                  onChange={e => setUser({ ...user, email: e.target.value })}
                />,
                'email',
                error
              )}
            </Form.Item>
            <Form.Item label="Password">
              {FormItemWithError(
                <Input
                  value={user.password}
                  placeholder="password"
                  size="large"
                  className={error ? 'error' : ''}
                  type="password"
                  onChange={e => setUser({ ...user, password: e.target.value })}
                />,
                'password',
                error
              )}
            </Form.Item>
            <Button
              size="large"
              className="full-width"
              type="primary"
              htmlType="submit"
              onClick={loginHandler}
            >
              Log In
              <Icon type="login" theme="outlined" />
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

Signin.propTypes = {
  login: PropTypes.func.isRequired
};

export default Signin;
