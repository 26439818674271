import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  message,
  Divider,
  Upload,
  Icon,
  Modal
} from 'antd';
import { useSelector } from 'react-redux';

import { validate, FormItemWithError } from '../../../../shared/validations';

import api from '../../../../api';

const { Option } = Select;
const requiredFields = [
  'name',
  'status',
  'sector',
  'city',
  'street_address',
  'categories',
  'amenities'
  // 'type',
  // 'is_advertiser',
  // 'price_range',
  // 'is_claimed'
];

const Basic = ({ targetBranch, onSubmit }) => {
  const meta = useSelector(state => state.config.meta);
  // const inputRef = useRef(null);
  const [name, setName] = useState('');
  const [areas, setAreas] = useState();
  const [tags, setTags] = useState();
  const [categories, setCategories] = useState();
  const [amenities, setAmenities] = useState();
  const [enteredTags, setEnteredTags] = useState([]);
  const [enteredCategories, setEnteredCategories] = useState([]);
  const [enteredAmenities, setEnteredAmenities] = useState([]);
  const branchInitialState = {};

  // const [imageLoading, setImageLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState('');

  // const [previewVisible, setPreviewVisible] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewFileList, setPreviewFileList] = useState([]);

  const [branch, setBranch] = useState({});
  // {
  //   is_advertiser: false,
  //   is_featured: false,
  //   is_main: false
  // }
  //   const [business, setBusiness] = useState(businessInitialState);
  const [error, setError] = useState({});

  const onNameChange = event => {
    setName(event.target.value);
  };

  const addItem = e => {
    e.preventDefault();
    setAreas([...areas, name]);
    setName('');
    // setTimeout(() => {
    //   return inputRef.current?.focus();
    // }, 0);
  };

  const fetchAreas = () => {
    if (branch && branch.city) {
      api.location
        .getSectors(branch.city)
        .then(resp => {
          const sectors = resp.items.map(function(sector) {
            return sector.long_name;
          });
          setAreas(sectors);
          // eslint-disable-next-line no-console
          console.log('areas: ', resp);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  };

  const fetchTags = () => {
    if (branch) {
      api.tags
        .getList()
        .then(resp => {
          const tagNames = resp.items.map(function(tag) {
            return { uuid: tag.uuid, name: tag.name };
          });
          setTags(tagNames);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  };

  const fetchCategories = () => {
    if (branch) {
      api.categories
        .getList('', 1, 1000)
        .then(resp => {
          resp.items.forEach(function(category) {
            // eslint-disable-next-line no-param-reassign
            delete category.slug;
            // eslint-disable-next-line no-param-reassign
            delete category.primary_photo;
            // return category;
          });
          setCategories(resp.items);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  };

  const fetchAmenities = () => {
    if (branch) {
      api.amenities
        .getList('', 1, 1000)
        .then(resp => {
          resp.items.forEach(function(amenity) {
            // eslint-disable-next-line no-param-reassign
            delete amenity.slug;
            // eslint-disable-next-line no-param-reassign
            delete amenity.primary_photo;
          });
          setAmenities(resp.items);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  };

  // useEffect(() => {
  //   api.location
  //     .getSectors(branch.city)
  //     .then(resp => {
  //       setAreas(resp);
  //       // eslint-disable-next-line no-console
  //       console.log('areas: ', resp);
  //     })
  //     .catch(err => {
  //       message.error(err.message);
  //     });
  // }, [branch]);

  useEffect(() => {
    let data = branch;
    // let data = { ...businessInitialState };
    if (targetBranch && targetBranch.uuid) {
      console.log('targetBranch v2: ', targetBranch);
      data = {
        uuid: targetBranch.uuid,
        name: targetBranch.name,
        city: targetBranch.city,
        sector: targetBranch.sector,
        phone_no: targetBranch.phone_no,
        price_per_person: targetBranch.price_per_person,
        is_featured: targetBranch.is_featured,
        status: targetBranch.status,
        is_advertiser: targetBranch.is_advertiser,
        is_main: targetBranch.is_main,
        street_address: targetBranch.address_line1,
        created_at: targetBranch.created_at,
        tags: targetBranch.tags.map(item => {
          return item.uuid;
        }),
        categories: targetBranch.categories.map(item => {
          return item.uuid;
        }),
        amenities: targetBranch.amenities.map(item => {
          return item.uuid;
        })
      };
      if (targetBranch && targetBranch.tags) {
        setEnteredTags(
          targetBranch.tags.map(item => {
            return item.name;
          })
        );
      }
      if (targetBranch && targetBranch.categories) {
        setEnteredCategories(
          targetBranch.categories.map(item => {
            return item.name;
          })
        );
      }
      if (targetBranch && targetBranch.amenities) {
        setEnteredAmenities(
          targetBranch.amenities.map(item => {
            return item.name;
          })
        );
      }
      setBranch(data);
    }
    // eslint-disable-next-line
  }, [targetBranch]);

  const submitBasicTab = () => {
    console.log('SUBMITTED: ', branch);
    const valid = validate(branch, requiredFields);

    if (!valid.isValid) {
      console.log('ERRORS: ', valid.errors);
      setError({ ...valid.errors });
    } else {
      console.log('NO ERRORS');
      setError({});
      onSubmit(branch);
    }
  };

  const selectTag = value => {
    setEnteredTags(value);
    const tagList = value.map(item => {
      const tag = tags.find(tagObj => tagObj.name === item);
      return tag ? tag.uuid : item;
    });
    setBranch({ ...branch, tags: tagList });
  };

  const selectCategory = value => {
    setEnteredCategories(value);
    const categoryList = value.map(item => {
      const category = categories.find(
        categoryObj => categoryObj.name === item
      );
      if (category) {
        return category.uuid;
      }
    });
    if (categoryList.length > 0) {
      setBranch({ ...branch, categories: categoryList });
    }
  };

  const selectAmenity = value => {
    setEnteredAmenities(value);
    const amenityList = value.map(item => {
      const amenity = amenities.find(amenityObj => amenityObj.name === item);
      if (amenity) {
        return amenity.uuid;
      }
    });
    if (amenityList.length > 0) {
      setBranch({ ...branch, amenities: amenityList });
    }
  };

  const showTags = () => {
    const tagList = branch.tags.map(item => {
      return item.name;
    });
    return tagList;
  };

  useEffect(() => {
    console.log('BRRRAAANCH: ', branch);
    if (branch && branch.uuid) {
      // console.log('branch.tags: ', branch.tags);
      // setTagsProp(branch.tags.map(item => item.name));
      // console.log('branch: ', branch);
      // console.log('tagsProp: ', tagsProp);
      // onSubmit(branch, true);
    }
  }, [branch]);

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };

  // const uploadButton = (
  //   <div>
  //     <Icon type={imageLoading ? 'loading' : 'plus'} />
  //     <div className="ant-upload-text">Upload</div>
  //   </div>
  // );

  // const beforeUpload = file => {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  // const handleChange = info => {
  //   if (info.file.status === 'uploading') {
  //     setImageLoading(true);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, imageUrl => {
  //       setImageLoading(false);
  //       setImageUrl(imageUrl);
  //     });
  //   }
  // };

  // const getBase64 = file => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     // eslint-disable-next-line no-shadow
  //     reader.onerror = error => reject(error);
  //   });
  // };

  // const handlePreview = async file => {
  //   if (!file.url && !file.preview) {
  //     console.log('PREVIEW');
  //     // eslint-disable-next-line no-param-reassign
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewVisible(true);
  // };

  // const handleChange = ({ fileList }) => setPreviewFileList(fileList);
  // const handleCancel = () => setPreviewVisible(false);

  // useEffect(() => {
  //   console.log('PREVIEW_FILE_LIST: ', previewFileList);
  // }, [previewFileList]);

  // const uploadButton = (
  //   <div>
  //     <Icon type="plus" />
  //     <div className="ant-upload-text">Upload</div>
  //   </div>
  // );

  // const beforeUpload = (file, fileList) => {
  //   console.log('file: ', file);
  //   console.log('fileList: ', fileList);
  // };

  return (
    <div className="branch-basic-form">
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Name"
                  name="name"
                  value={branch.name}
                  maxLength={60}
                  onChange={e => setBranch({ ...branch, name: e.target.value })}
                />,
                'name',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" required>
              {FormItemWithError(
                <Select
                  size="large"
                  name="city"
                  showArrow={true}
                  autoFocus={true}
                  defaultValue={branch.city || meta.default_city}
                  onChange={value => setBranch({ ...branch, city: value })}
                  onBlur={value => setBranch({ ...branch, city: value })}
                >
                  {meta.cities &&
                    meta.cities.map(city => (
                      <Option key={city} value={city}>
                        {city}
                      </Option>
                    ))}
                </Select>,
                'city',
                error
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Sector" required>
              {FormItemWithError(
                <Select
                  size="large"
                  name="sector"
                  showArrow={true}
                  // autoFocus={true}
                  showSearch
                  mode="tags"
                  value={branch?.sector || undefined}
                  // defaultActiveFirstOption={false}
                  // filterOption={(inputValue, option) => {
                  //   console.log('inputValue: ', inputValue);
                  //   console.log('option: ', option);
                  // }}
                  // tagRender={selected =>
                  //   selected.map(item => <Tag key={item.key}>{item.label}</Tag>)
                  // }
                  onFocus={fetchAreas}
                  // defaultValue={meta.default_city}
                  onChange={value => setBranch({ ...branch, sector: value[0] })}
                  // onBlur={value => setBranch({ ...branch, sector: value })}
                >
                  {areas &&
                    areas.map(area => (
                      <Option key={area} value={area}>
                        {area}
                      </Option>
                    ))}
                </Select>,
                'sector',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tags">
              {FormItemWithError(
                <Select
                  size="large"
                  name="tags"
                  showArrow={true}
                  // autoFocus={true}
                  showSearch
                  value={enteredTags}
                  mode="tags"
                  // defaultActiveFirstOption={false}
                  // filterOption={(inputValue, option) => {
                  //   console.log('inputValue: ', inputValue);
                  //   console.log('option: ', option);
                  // }}
                  // tagRender={selected =>
                  //   selected.map(item => <Tag key={item.key}>{item.label}</Tag>)
                  // }
                  onFocus={fetchTags}
                  // defaultValue={meta.default_city}
                  onChange={value => selectTag(value)}
                  // onBlur={value => setBranch({ ...branch, sector: value })}
                >
                  {tags &&
                    tags.map(tag => (
                      <Option key={tag.name} value={tag.name}>
                        {tag.name}
                      </Option>
                    ))}
                </Select>,
                'sector',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Categories" required>
              {FormItemWithError(
                <Select
                  size="large"
                  name="categories"
                  showArrow={true}
                  showSearch
                  value={enteredCategories}
                  mode="multiple"
                  onFocus={fetchCategories}
                  onChange={value => selectCategory(value)}
                  // onChange={value =>
                  //   setBranch({ ...branch, categories: value })
                  // }
                >
                  {categories &&
                    categories.map(category => (
                      <Option key={category.name} value={category.name}>
                        {category.name}
                      </Option>
                    ))}
                </Select>,
                'categories',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Amenities" required>
              {FormItemWithError(
                <Select
                  size="large"
                  name="amenities"
                  showArrow={true}
                  showSearch
                  value={enteredAmenities}
                  mode="multiple"
                  onFocus={fetchAmenities}
                  onChange={value => selectAmenity(value)}
                  // onChange={value => setBranch({ ...branch, amenities: value })}
                >
                  {amenities &&
                    amenities.map(amenity => (
                      <Option key={amenity.name} value={amenity.name}>
                        {amenity.name}
                      </Option>
                    ))}
                </Select>,
                'amenities',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone No">
              <Input
                size="large"
                placeholder="phone no"
                name="phone_no"
                value={branch.phone_no}
                onChange={e =>
                  setBranch({ ...branch, phone_no: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Is Featured">
              <Select
                size="large"
                name="is_featured"
                showArrow={true}
                autoFocus={true}
                // defaultValue={false}
                value={branch?.is_featured || false}
                onChange={value => setBranch({ ...branch, is_featured: value })}
                onBlur={value => setBranch({ ...branch, is_featured: value })}
              >
                <Option key={true} value={true}>
                  Yes
                </Option>
                <Option key={false} value={false}>
                  No
                </Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Email">
              <Input
                size="large"
                placeholder="email"
                name="email"
                value={business.email}
                onChange={e =>
                  setBusiness({ ...business, email: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}
        </Row>
        {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Is Advertiser">
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Is Advertiser"
                  showArrow={true}
                  value={business.is_advertiser}
                  onChange={value =>
                    setBusiness({ ...business, is_advertiser: value })
                  }
                >
                  <Option key="yes" value="yes">
                    Yes
                  </Option>
                  <Option key="no" value="no">
                    No
                  </Option>
                </Select>,
                'is_advertiser',
                error
              )}
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          {/* <Col span={12}>
            <Form.Item label="Is Featured" required>
              {FormItemWithError(
                <Select
                  size="large"
                  name="is_featured"
                  showArrow={true}
                  autoFocus={true}
                  defaultValue={false}
                  onChange={value =>
                    setBranch({
                      ...branch,
                      is_featured: value
                    })
                  }
                  onBlur={value =>
                    setBranch({
                      ...branch,
                      is_featured: value
                    })
                  }
                >
                  <Option key="Yes" value={true}>
                    Yes
                  </Option>
                  <Option key="No" value={false}>
                    No
                  </Option>
                </Select>,
                'is_featured',
                error
              )}
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item label="Status" required>
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Status"
                  showArrow={true}
                  autoFocus={true}
                  // defaultValue={1}
                  value={branch?.status || 1}
                  onChange={value => setBranch({ ...branch, status: value })}
                  onBlur={value => setBranch({ ...branch, status: value })}
                >
                  {meta.branch &&
                    meta.branch.status.map(status => (
                      <Option key={status.name} value={status.value}>
                        {status.name}
                      </Option>
                    ))}
                </Select>,
                'status',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Is Advertiser">
              <Select
                size="large"
                name="is_advertiser"
                showArrow={true}
                autoFocus={true}
                // defaultValue={false}
                value={branch?.is_advertiser || false}
                onChange={value =>
                  setBranch({ ...branch, is_advertiser: value })
                }
                onBlur={value => setBranch({ ...branch, is_advertiser: value })}
              >
                <Option key={true} value={true}>
                  Yes
                </Option>
                <Option key={false} value={false}>
                  No
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Is Main">
              <Select
                size="large"
                name="is_main"
                showArrow={true}
                autoFocus={true}
                // defaultValue={false}
                value={branch?.is_main || false}
                onChange={value => setBranch({ ...branch, is_main: value })}
                onBlur={value => setBranch({ ...branch, is_main: value })}
              >
                <Option key={true} value={true}>
                  Yes
                </Option>
                <Option key={false} value={false}>
                  No
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Address"
                  name="address"
                  value={branch.street_address}
                  maxLength={600}
                  onChange={e =>
                    setBranch({ ...branch, street_address: e.target.value })
                  }
                />,
                'street_address',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Price Per Person">
              <Input
                size="large"
                placeholder="Price Per Person"
                name="price_per_person"
                value={branch.price_per_person}
                onChange={e =>
                  setBranch({ ...branch, price_per_person: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          {branch.uuid && (
            <Col span={12}>
              <Form.Item label="Created At">
                {FormItemWithError(
                  <Input
                    size="large"
                    placeholder="Don't add anything"
                    name="created_at"
                    value={branch.created_at || undefined}
                    // maxLength={60}
                    onChange={e =>
                      setBranch({ ...branch, created_at: e.target.value })
                    }
                  />,
                  'street_address',
                  error
                )}
              </Form.Item>
            </Col>
          )}
          {/* <Col span={12}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={true}
              action="https://api.cloudinary.com/v1_1/xstate/upload"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col> */}
          {/* <Col span={12}>
            <div className="clearfix">
              <Upload
                action="https://api.cloudinary.com/v1_1/recce/upload"
                listType="picture-card"
                fileList={previewFileList}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {previewFileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Col> */}
        </Row>
        {/* <Form.Item label="Description">
          <Input.TextArea
            rows={4}
            placeholder="Description"
            name="description"
            value={branch.description}
            onChange={e =>
              setBranch({ ...branch, description: e.target.value })
            }
          />
        </Form.Item> */}
      </Form>
      <div className="m-b">
        <Button onClick={submitBasicTab} type="primary" size="large">
          Submit
        </Button>
      </div>
    </div>
  );
};

Basic.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  targetBranch: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired
  // branchTypes: PropTypes.arrayOf(PropTypes.any).isRequired
};

Basic.defaultProps = {
  targetBranch: {}
};

export default Basic;
