import React from 'react';
import PropTypes from 'prop-types';

import './Loader.less';

const Loader = ({ infiniteLoader }) => {
  return (
    <div
      className={
        infiniteLoader ? 'infinite-loader-container' : 'loader-container'
      }
    >
      <div className="loader">Loading</div>
    </div>
  );
};

Loader.propTypes = {
  infiniteLoader: PropTypes.bool
};

Loader.defaultProps = {
  infiniteLoader: false
};

export default Loader;
