import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Row, Col, Button, message } from 'antd';
import StackEdit from '../../../../components/StackEdit/StackEdit';

import { validate, FormItemWithError } from '../../../../shared/validations';
import InputTypes from '../../../../assets/json/InputTypes.json';

import api from '../../../../api';

const InputSubTypes = InputTypes.filter(inputType => inputType !== 'array');
const { Option } = Select;
const requiredFields = [
  'name'
  // 'type',
  // 'is_advertiser',
  // 'price_range',
  // 'is_claimed'
];

const Basic = ({ targetBusiness, onSubmit, businessTypes }) => {
  const businessInitialState = {
    output: {
      name: '',
      type: undefined,
      sub_type: undefined
    }
  };
  const [business, setBusiness] = useState({});
  //   const [business, setBusiness] = useState(businessInitialState);
  const [error, setError] = useState({});

  const updateBusinessBody = body => {
    setBusiness(businessObj => ({ ...businessObj, body }));
  };

  useEffect(() => {
    let data = business;
    // let data = { ...businessInitialState };
    if (targetBusiness && targetBusiness.uuid) {
      data = {
        uuid: targetBusiness.uuid,
        name: targetBusiness.name,
        type: targetBusiness.type,
        description: targetBusiness.description,
        website: targetBusiness.website,
        email: targetBusiness.email,
        is_advertiser: targetBusiness.is_advertiser,
        // alternate_names: targetBusiness.alternate_names,
        // price_range: targetBusiness.price_range,
        is_claimed: targetBusiness.is_claimed,
        is_claimable: targetBusiness.is_claimable
      };
      setBusiness(data);
    }
    // eslint-disable-next-line
  }, [targetBusiness]);
  const submit = () => {
    // const requiredOutputFields = ['name', 'type'];
    // if (business.output.type === 'array') {
    //   requiredOutputFields.push('sub_type');
    // }
    const valid = validate(business, requiredFields);
    // const validOutput = validate(business.output, requiredOutputFields);

    if (!valid.isValid) {
      setError({ ...valid.errors });
    } else {
      setError({});
      // const call = business.uuid
      //   ? api.business.update(business)
      //   : api.business.add(business);
      // call.then(resp => {
      //   if (resp && resp.uuid) {
      //     business.uuid
      //       ? message.success('Business Updated!')
      //       : message.success('Business Added!');
      //     onSubmit(resp);
      //     onDrawerClose();
      //   }
      // })
      onSubmit(business);
    }
  };

  const updateOutput = (name, value) => {
    const businessObj = { ...business };
    businessObj.output[name] = value;
    setBusiness(businessObj);
  };

  useEffect(() => {
    if (business && business.uuid) {
      onSubmit(business, true);
    }
    // eslint-disable-next-line
  }, [business]);

  return (
    <div className="business-basic-form">
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Name" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Name"
                  name="name"
                  value={business.name}
                  maxLength={60}
                  onChange={e =>
                    setBusiness({ ...business, name: e.target.value })
                  }
                />,
                'name',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Type">
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Type"
                  showArrow={true}
                  autoFocus={true}
                  defaultValue="Restaurant"
                  onChange={value => setBusiness({ ...business, type: value })}
                  onBlur={value => setBusiness({ ...business, type: value })}
                >
                  <Option key="restaurant" value="Restaurant">
                    Restaurant
                  </Option>
                </Select>,
                'type',
                error
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Website">
              <Input
                size="large"
                placeholder="Website"
                name="website"
                value={business.website}
                onChange={e =>
                  setBusiness({ ...business, website: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email">
              <Input
                size="large"
                placeholder="email"
                name="email"
                value={business.email}
                onChange={e =>
                  setBusiness({ ...business, email: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Is Advertiser">
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Is Advertiser"
                  showArrow={true}
                  value={business.is_advertiser}
                  onChange={value =>
                    setBusiness({ ...business, is_advertiser: value })
                  }
                >
                  <Option key="yes" value="yes">
                    Yes
                  </Option>
                  <Option key="no" value="no">
                    No
                  </Option>
                </Select>,
                'is_advertiser',
                error
              )}
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Is Claimed">
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Is Claimed"
                  showArrow={true}
                  autoFocus={true}
                  defaultValue="Yes"
                  onChange={value =>
                    setBusiness({
                      ...business,
                      is_claimed: value === 'Yes'
                    })
                  }
                  onBlur={value =>
                    setBusiness({
                      ...business,
                      is_claimed: value === 'Yes'
                    })
                  }
                >
                  <Option key="yes" value="Yes">
                    Yes
                  </Option>
                  <Option key="no" value="No">
                    No
                  </Option>
                </Select>,
                'is_claimed',
                error
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Description">
          <Input.TextArea
            rows={4}
            placeholder="Description"
            name="description"
            value={business.description}
            onChange={e =>
              setBusiness({ ...business, description: e.target.value })
            }
          />
        </Form.Item>
      </Form>
      <div className="m-b">
        <Button onClick={() => submit()} type="primary" size="large">
          Submit
        </Button>
      </div>
    </div>
  );
};

Basic.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  targetBusiness: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  businessTypes: PropTypes.arrayOf(PropTypes.any).isRequired
};

Basic.defaultProps = {
  targetBusiness: {}
};

export default Basic;
