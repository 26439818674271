import http from '../../api/http';

export const saveMeta = meta => ({
  type: 'SAVE_META',
  meta
});

export const fetchAndSetMeta = () => {
  return dispatch => {
    return http
      .get('/meta')
      .then(resp => {
        if (resp) {
          dispatch(saveMeta(resp));
        }
      })
      .catch(error => console.log(error));
  };
};

export const toggleBusinessUpdated = businessUpdated => {
  return dispatch => {
    dispatch({ type: 'BUSINESS_UPDATED', businessUpdated });
  };
};

export const toggleBlogUpdated = blogUpdated => {
  return dispatch => {
    dispatch({ type: 'BLOG_UPDATED', blogUpdated });
  };
};
