/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Divider,
  Row,
  Col,
  Select,
  message,
  Empty,
  Button,
  Upload,
  Modal,
  Icon
} from 'antd';

import './Details.less';
import MenuCard from '../../../components/MenuCard/MenuCard';
import ApplicantDetailsDialog from '../../../components/ApplicantDetailsDialog/ApplicantDetailsDialog';
import Loader from '../../../components/Loader/Loader';
import api from '../../../api';
import CategorySet from '../../../components/CategorySet/CategorySet';
import AmenitySet from '../../../components/AmenitySet/AmenitySet';
import AddMenuCategoryDialog from '../../../components/AddMenuCategoryDialog/AddMenuCategoryDialog';
import AddMenuImageDialog from '../../../components/AddMenuImageDialog/AddMenuImageDialog';

const Details = ({ data, applicantStatus, branchCommitment }) => {
  const [state, setState] = useState({
    applicants: {},
    selectedApplicant: {},
    selectedStatus: null,
    applicantsCount: 0,
    selectedApplicantsCount: 0,
    branchName: '',
    loading: false,
    loadingBranchName: false,
    showApplicantDetailsDialog: false,
    showAddMenuCategoryDialog: false,
    showUploadMenuImageDialog: false
  });
  const [branchDetails, setBranchDetails] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [menuFileList, setMenuFileList] = useState([]);
  const [uploadData, setUploadData] = useState(null);
  const [defaultMedia, setDefaultMedia] = useState([]);
  const [defaultMenuMedia, setDefaultMenuMedia] = useState([]);
  const [count, setCount] = useState(0);
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [menuMediaLoaded, setMenuMediaLoaded] = useState(false);
  // const commitments = {};
  // branchCommitment.map(item => (commitments[item.value] = item.name));

  const getTotalApplicantCount = Applicants => {
    let count = 0;
    Object.keys(Applicants).forEach(statusKey => {
      count += Applicants[statusKey].length;
    });
    return count;
  };

  const deleteMenuCategory = uuid => {
    api.menuCategory
      .deleteMenuCategory(uuid)
      .then(() => {
        message.success('Menu Category deleted successfully!');
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const deleteMenuItem = uuid => {
    api.menuItem
      .deleteMenuItem(uuid)
      .then(() => {
        message.success('Menu Item deleted successfully!');
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const fetchBranchDetails = useCallback(uuid => {
    setState(stateObj => ({ ...stateObj, loadingBranchName: true }));
    api.branches
      .get(uuid)
      .then(resp => {
        setState(stateObj => ({
          ...stateObj,
          branchName: resp.name,
          selectedStatus: null,
          loadingBranchName: false
        }));
        setBranchDetails(resp);
        console.log('fetchBranchDetails media: ', resp.media);
        const updatedMedia = resp.media.map(item => {
          return { uid: item.uuid, thumbUrl: item.url };
        });
        setDefaultMedia(updatedMedia);
        setFileList(updatedMedia);
        setMediaLoaded(true);

        const filteredOne = resp.media.filter(obj => obj.category === 'Menu');
        const updatedMenuMedia = filteredOne.map(item => {
          return { uid: item.uuid, thumbUrl: item.url };
        });
        setDefaultMenuMedia(updatedMenuMedia);
        setMenuFileList(updatedMenuMedia);
        setMenuMediaLoaded(true);
      })
      .catch(error => {
        setState(stateObj => ({
          ...stateObj,
          loadingBranchName: false,
          selectedStatus: null
        }));
        message.error(error.message);
      });
  }, []);

  // const fetchBranchApplicants = useCallback(branch => {
  // setState(stateObj => ({ ...stateObj, loading: true }));
  // api.branches
  //   .getApplicants(branch.uuid)
  //   .then(resp => {
  //     if (resp) {
  //       const applicantsCount = getTotalApplicantCount(resp);
  //       setState(stateObj => ({
  //         ...stateObj,
  //         applicants: resp,
  //         applicantsCount,
  //         loading: false
  //       }));
  //     } else {
  //       setState(stateObj => ({ ...stateObj, loading: false }));
  //     }
  //   })
  //   .catch(error => {
  //     setState(stateObj => ({ ...stateObj, loading: false }));
  //     message.error(error.message);
  //   }); // eslint-disable-next-line
  // }, []);

  const openApplicantDetails = selectedApplicant =>
    setState(stateObj => ({
      ...stateObj,
      selectedApplicant,
      showApplicantDetailsDialog: true
    }));

  const closeApplicantDetails = () => {
    setState(stateObj => ({
      ...stateObj,
      selectedApplicant: {},
      showApplicantDetailsDialog: false
    }));
  };

  const onApplicantStatusChange = status => {
    setState(stateObj => ({
      ...stateObj,
      selectedApplicantsCount: state.applicants[status]
        ? state.applicants[status].length
        : 0,
      selectedStatus: status
    }));
  };

  useEffect(() => {
    console.log('useEffect');
    if (data?.uuid) {
      // console.log('useEffect details branch: ', data);
      fetchBranchDetails(data.uuid);
    }
  }, [data]);

  // useEffect(() => {
  //   const updatedMedia = branchDetails.media.map(item => {
  //     return { uid: item.uuid, thumbUrl: item.url };
  //   });
  //   setDefaultMedia(updatedMedia);
  //   setFileList(updatedMedia);
  //   setMediaLoaded(true);
  // }, [branchDetails]);

  // useEffect(() => {
  //   console.log('UPLOAD DATA STATE: ', uploadData);
  // }, [uploadData]);

  // useEffect(() => {
  //   console.log('Default Media State: ', defaultMedia);
  // }, [defaultMedia]);

  // useEffect(() => {
  //   console.log('fileList State: ', fileList);
  // }, [fileList]);

  // useEffect(() => {
  //   if (data?.uuid) {
  //     setCount(count + 1);
  //     console.log('BRANCH_DATA: ', data);
  //     fetchBranchDetails(data.uuid);
  //     const updatedMedia = data.media.map(item => {
  //       return { uid: item.uuid, thumbUrl: item.url };
  //     });
  //     // console.log('Updated Media Object: ', updatedMedia);
  //     setDefaultMedia(updatedMedia);
  //     setFileList(updatedMedia);
  //     // if (count == 2) {
  //     setMediaLoaded(true);
  //     // }
  //   }
  // }, [data, fetchBranchDetails]);

  const prepareData = file => {
    const uuid = `${data.uuid}/`;
    return {
      upload_preset: 'recce_prod',
      public_id: `${uuid}/${file.name
        .replace('/', '')
        .split('.')
        .shift()}`
    };
  };

  const prepareMenuData = file => {
    const uuid = `${data.uuid}/`;
    return {
      upload_preset: 'recce_prod',
      public_id: `${uuid}/${file.name
        .replace('/', '')
        .split('.')
        .shift()}`
    };
  };

  const removeFile = file => {
    console.log('onRemove File: ', file);
    // const tempFileList = fileList;
    // if (file?.response?.secure_url) {
    // console.log('poped');
    // console.log('poped = ', fileList.pop());
    // const indexOfObject = fileList.findIndex(object => {
    //   // eslint-disable-next-line eqeqeq
    //   return object.thumbUrl == file.response.secure_url;
    // });
    // fileList.splice(indexOfObject, 1);
    // // console.log('tempFileList: ', tempFileList);
    // setFileList(fileList);
    // }
    // console.log('onRemove File: ', file.response.secure_url.split('//')[1]);
    // const targetUuid = file.response.public_id.split('/')[1];
    // api.media
    //   .softDelete(file.response.secure_url.split('//')[1])
    //   .then(resp => {
    //     if (resp) {
    //       message.success('Image Deleted!');
    //     }
    //   })
    //   .catch(err => {
    //     message.error(err.message);
    //   });
  };

  const handlePreview = async file => {
    // console.log('onPreview: ', file);
    // if (!file.url && !file.preview) {
    //   console.log('PREVIEW');
    //   // eslint-disable-next-line no-param-reassign
    //   file.preview = await getBase64(file.originFileObj);
    // }
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  // eslint-disable-next-line no-shadow
  const handleChange = ({ file, fileList, event }) => {
    console.log('handleChange File: ', file);
    console.log('handleChange Event: ', event);
    if (file?.status === 'removed') {
      console.log('handleChange default Media before remove: ', defaultMedia);
      if (file?.response) {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.thumbUrl == file.response.secure_url;
        });
        console.log('deleted src: ', defaultMedia[indexOfObject].uid);
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      } else {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.uid == file.uid;
        });
        console.log('deleted src: ', defaultMedia[indexOfObject].uid);
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      }
    }
    console.log('handleChange default Media: ', defaultMedia);
    // eslint-disable-next-line eqeqeq
    if (file?.response && file?.status != 'removed') {
      console.log('handleChange File: ', file);
      api.media
        .add({
          target_uuid: data.uuid,
          target: 'branch',
          category: 'ambience',
          url: file?.response?.secure_url,
          is_featured: false
        })
        .then(resp => {
          if (resp && resp.uuid) {
            message.success('Image Uploaded!');
            // fileList.pop();
            // fileList.push({
            //   uid: resp.uuid,
            //   thumbUrl: resp.url
            // });
            defaultMedia.push({
              uid: resp.uuid,
              thumbUrl: resp.url
            });
            setDefaultMedia(defaultMedia);
            // setFileList(fileList);
            // setDefaultMedia(...defaultMedia, {
            //   uid: resp.uuid,
            //   thumbUrl: resp.secure_url
            // });
          }
        })
        .catch(err => {
          message.error(err.message);
        });
      console.log('SECURE URL: ', file?.response?.secure_url);
    }
    // console.log('EVENT: ', event);
    // console.log('handleChange File: ', file);
    console.log('handleChange FileList: ', fileList);
    setFileList(fileList);
  };

  // eslint-disable-next-line no-shadow
  const handleMenuImageChange = ({ file, fileList, event }) => {
    console.log('handleChange File: ', file);
    console.log('handleChange Event: ', event);
    if (file?.status === 'removed') {
    console.log('handleChange default Media before remove: ', defaultMedia);
      if (file?.response) {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.thumbUrl == file.response.secure_url;
        });
        console.log('deleted src: ', defaultMedia[indexOfObject].uid);
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      } else {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.uid == file.uid;
        });
        console.log('deleted src: ', defaultMedia[indexOfObject].uid);
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      }
    }
    console.log('handleChange default Media: ', defaultMedia);
    // eslint-disable-next-line eqeqeq
    if (file?.response && file?.status != 'removed') {
      console.log('handleChange File: ', file);
      api.media
        .add({
          target_uuid: data.uuid,
          target: 'branch',
          category: 'Menu',
          url: file?.response?.secure_url,
          is_featured: false
        })
        .then(resp => {
          if (resp && resp.uuid) {
            message.success('Image Uploaded!');
            // fileList.pop();
            // fileList.push({
            //   uid: resp.uuid,
            //   thumbUrl: resp.url
            // });
            defaultMedia.push({
              uid: resp.uuid,
              thumbUrl: resp.url
            });
            setDefaultMedia(defaultMedia);
            // setFileList(fileList);
            // setDefaultMedia(...defaultMedia, {
            //   uid: resp.uuid,
            //   thumbUrl: resp.secure_url
            // });
          }
        })
        .catch(err => {
          message.error(err.message);
        });
      console.log('SECURE URL: ', file?.response?.secure_url);
    }
    // console.log('EVENT: ', event);
    // console.log('handleChange File: ', file);
    console.log('handleChange FileList: ', fileList);
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const openUploadMenuImageDialog = () => {
    setState(stateObj => ({ ...stateObj, showUploadMenuImageDialog: true }));
  };

  const closeUploadMenuImageDialog = () => {
    setState(stateObj => ({ ...stateObj, showUploadMenuImageDialog: false }));
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const showImagePart = () => {
    return mediaLoaded ? (
      <div className="clearfix">
        <Upload
          action="https://api.cloudinary.com/v1_1/recce/upload"
          listType="picture-card"
          // [
          //   {
          //     uid: '9e43ffb2-a560-4f3a-8f7e-05a4b55f2143',
          //     thumbUrl:
          //       'https://res.cloudinary.com/recce/image/upload/v1668277811/recce_prod/ed912070-e32e-49ef-a9fd-14445f79d546/0-2.jpg'
          //   }
          // ]
          defaultFileList={defaultMedia}
          // fileList={fileList}
          data={prepareData}
          multiple={true}
          // beforeUpload={beforeUpload}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={removeFile}
        >
          {/* {fileList.length >= 8 ? null : uploadButton} */}
          {uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    ) : null;
  };

  const showMenuImagePart = () => {
    return (
      <div>
        <Button type="link" icon="plus" onClick={openUploadMenuImageDialog}>
          Upload Menu Image
        </Button>
      </div>
      // <div className="clearfix">
      //   <Upload
      //     action="https://api.cloudinary.com/v1_1/recce/upload"
      //     listType="picture-card"
      //     // [
      //     //   {
      //     //     uid: '9e43ffb2-a560-4f3a-8f7e-05a4b55f2143',
      //     //     thumbUrl:
      //     //       'https://res.cloudinary.com/recce/image/upload/v1668277811/recce_prod/ed912070-e32e-49ef-a9fd-14445f79d546/0-2.jpg'
      //     //   }
      //     // ]
      //     defaultFileList={defaultMenuMedia}
      //     // fileList={fileList}
      //     data={prepareMenuData}
      //     multiple={true}
      //     // beforeUpload={beforeUpload}
      //     onPreview={handlePreview}
      //     onChange={handleMenuImageChange}
      //     onRemove={removeFile}
      //   >
      //     {/* {fileList.length >= 8 ? null : uploadButton} */}
      //     {uploadButton}
      //   </Upload>
      //   <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
      //     <img alt="example" style={{ width: '100%' }} src={previewImage} />
      //   </Modal>
      // </div>
    );
  };

  const generateMenuList = () => {
    return (
      <div className="menu-items-list">
        {data && data.menu
          ? data.menu.map(category => (
              <MenuCard
                data={category}
                onItemDelete={deleteMenuItem}
                onCategoryDelete={deleteMenuCategory}
              />
            ))
          : null}
      </div>
    );
  };

  const openAddMenuCategoryDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddMenuCategoryDialog: true }));
  };

  const closeAddMenuCategoryDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddMenuCategoryDialog: false }));
  };

  const addMenuCategory = categoryData => {
    console.log('MENU_CATEGORY_DATA: ', categoryData);
    console.log('BRANCH_UUID: ', data.uuid);
    // setState(stateObj => ({ ...stateObj, businessDetails: { ...data } }));
    // dispatch(toggleBusinessUpdated(!businessUpdated));
    closeAddMenuCategoryDialog();
  };

  const addMenuImage = imageData => {
    console.log('ADD MENU IMAGE DATA: ', imageData);
    closeUploadMenuImageDialog();
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      // eslint-disable-next-line no-shadow
      reader.onerror = error => reject(error);
    });
  };

  // eslint-disable-next-line no-shadow
  // const beforeUpload = (file, fileList) => {
  // const fileNames = fileList.map(fileItem => fileItem.name);
  // const fileData = [];
  // const uuid = `${data.uuid}/`;
  // fileNames.forEach(fileName =>
  //   fileData.push({
  //     upload_preset: 'recce_prod',
  //     public_id: `${uuid}/${fileName
  //       .replace('/', '')
  //       .split('.')
  //       .shift()}`
  //   })
  // );
  // setUploadData(fileData);
  // console.log('filename: ', file.name);
  // console.log('beforeUpload file: ', file);
  // console.log('fileList: ', fileList);
  // console.log("before uploaod!");
  // const uuid = `${data.uuid.replaceAll('-', '')}/`;
  // const uuid = `${data.uuid}/`;
  // setUploadData({
  //   upload_preset: 'recce_prod',
  //   public_id: `${uuid}/${file.name
  //     .replace('/', '')
  //     .split('.')
  //     .shift()}`
  // });
  // };

  return (
    <div className="branch-details">
      {(state?.loading || state?.loadingBranchName) && <Loader />}
      <div className="meta m-b">
        {/* <div className="t-b m-b">
          Branch:
          <span className="branch-name">{state?.branchName}</span>
        </div> */}
        <Descriptions className="p-t">
          <Descriptions.Item label="Phone No">
            {data?.phone_no}
          </Descriptions.Item>
          <Descriptions.Item label="Total Reviews">
            {data?.total_reviews}
          </Descriptions.Item>
          <Descriptions.Item label="Is Main Branch">
            {data?.is_main ? 'Yes' : 'No'}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Commitment">
            {commitments[data?.commitment]}
          </Descriptions.Item> */}
          <Descriptions.Item label="Views">
            {data?.total_view_count}
          </Descriptions.Item>
          <Descriptions.Item label="Rating">{data?.rating}</Descriptions.Item>
          <Descriptions.Item label="Is Advertiser">
            {data?.is_advertiser ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Directions Availibility">
            {data?.directions_availability ? 'Yes' : 'No'}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Menu Link">
            {data?.menu_link}
          </Descriptions.Item> */}
          <Descriptions.Item label="Slug">{data?.slug}</Descriptions.Item>
          {/* {data?.location_uuid ? (
            <Descriptions.Item label="Location">
              {data?.location?.long_name}
            </Descriptions.Item>
          ) : null} */}
        </Descriptions>
      </div>
      <div className="categories m-b">
        <div className="t-b m-b">Categories:</div>
        {data && data?.categories && (
          <CategorySet categories={data.categories} />
        )}
      </div>
      <div className="amenities m-b">
        <div className="t-b m-b">Amenities:</div>
        {data && data?.amenities && <AmenitySet amenities={data.amenities} />}
      </div>
      {/* <div className="clearfix">
        <Upload
          action="https://api.cloudinary.com/v1_1/recce/upload"
          listType="picture-card"
          fileList={previewFileList}
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {previewFileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div> */}
      <div className="description m-b">
        <div className="t-b m-b">Description:</div>
        <div>{data?.description}</div>
      </div>
      {showImagePart()}
      <Divider className="description-divider" />
      <div className="menu">
        <Row type="flex" justify="space-between" align="middle">
          <Col className="t-b m-b">
            Menu ({data?.menu?.length})
            {/* {state?.selectedStatus
              ? state.selectedApplicantsCount
              : state.applicantsCount}
            ) */}
          </Col>
          <Col className="t-b m-b">
            <Button type="link" icon="plus" onClick={openAddMenuCategoryDialog}>
              Add Category
            </Button>
          </Col>
          {/* <Col className="m-b">
            <Select
              defaultValue="All"
              style={{ width: '120px' }}
              onChange={onApplicantStatusChange}
              value={state.selectedStatus}
            >
              <Select.Option value={null}>All</Select.Option>
              {Object.keys(applicantStatus).map(key => (
                <Select.Option value={applicantStatus[key].value} key={key}>
                  {applicantStatus[key].name}
                </Select.Option>
              ))}
            </Select>
          </Col> */}
        </Row>
        <Divider className="menu-divider" />
        {data && data.menu && data.menu.length > 0
          ? generateMenuList()
          : showMenuImagePart()}
      </div>
      <AddMenuCategoryDialog
        branchUuid={data?.uuid}
        isVisible={state?.showAddMenuCategoryDialog}
        onSubmit={addMenuCategory}
        onCancel={closeAddMenuCategoryDialog}
      />
      <AddMenuImageDialog
        branchUuid={data?.uuid}
        isVisible={state?.showUploadMenuImageDialog}
        onSubmit={addMenuImage}
        onCancel={closeUploadMenuImageDialog}
      />
      <ApplicantDetailsDialog
        isVisible={state?.showApplicantDetailsDialog}
        onCancel={closeApplicantDetails}
        data={state?.selectedApplicant}
        branchMaxSalary={data.max_salary}
      />
    </div>
  );
};

Details.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  applicantStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  branchCommitment: PropTypes.arrayOf(PropTypes.any).isRequired
};

Details.defaultProps = {
  data: {}
};

export default Details;
