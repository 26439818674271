import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Divider, Empty } from 'antd';
import { CalculateExperienceInYears } from '../../../../shared/dateFilter';
import SkillSet from '../../../../components/CategorySet/CategorySet';

const Work = ({ ninjaWork }) => {
  return (
    <div className="work m-b">
      <div className="t-b m-b">Work:</div>
      {ninjaWork.length ? (
        ninjaWork.map(work => <WorkItem work={work} key={work.uuid} />)
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

const WorkItem = ({ work }) => (
  <div className="work-item">
    <Descriptions>
      <Descriptions.Item label="Experience">
        {`${CalculateExperienceInYears(
          work.from_date,
          work.currently_working ? Date() : work.end_date
        )} yrs`}
      </Descriptions.Item>
      <Descriptions.Item label="Currently Working">
        {work.currently_working ? 'True' : 'False'}
      </Descriptions.Item>
      <Descriptions.Item label="Company">{work.company_name}</Descriptions.Item>
      <Descriptions.Item label="Position">{work.position}</Descriptions.Item>
      <Descriptions.Item label="Location">
        { work.location && work.location.long_name}
      </Descriptions.Item>
    </Descriptions>
    <div className="work-skills">
      <span className="label">Skills :</span>
      {work && work.skills && <SkillSet skills={work.skills} />}
    </div>
    <Descriptions>
      <Descriptions.Item label="Activities" className='d-flex'>
        {
          work.activities.length > 0 ? 
          <ul>
            { work.activities.map((item, index) => <li key={'activity' + index}>{item}</li>) }
          </ul> 
        : <span className='text-italic'>No activities</span>
        }
      </Descriptions.Item>
    </Descriptions>
    <Divider className="small divider" />
  </div>
);

Work.propTypes = {
  ninjaWork: PropTypes.arrayOf(PropTypes.any).isRequired
};

WorkItem.propTypes = {
  work: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Work;
