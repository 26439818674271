import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './AmenitySet.less';

const AmenitySet = ({ amenities, cardAmenities }) => (
  <div className="amenities">
    {cardAmenities && amenities.length > 2 ? (
      <div>
        {amenities[0] && (
          <Tag key={`${amenities[0].uuid}`}>{amenities[0].name}</Tag>
        )}
        {amenities[1] && (
          <Tag key={`${amenities[1].uuid}`}>{amenities[1].name}</Tag>
        )}
        {amenities.length > 2 && (
          <Tag key={`${amenities[2].uuid}`}>{`+${amenities.length -
            2} more`}</Tag>
        )}
      </div>
    ) : (
      amenities.map((amenity, index) => (
        <Tag key={`${amenity.uuid}-${index}`}>{amenity.name}</Tag>
      ))
    )}
  </div>
);

AmenitySet.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  amenities: PropTypes.arrayOf(PropTypes.any).isRequired,
  cardAmenities: PropTypes.bool
};

AmenitySet.defaultProps = {
  cardAmenities: false
};

export default AmenitySet;
