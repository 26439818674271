import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Row, Col, Switch } from 'antd';

import InputValue from '../../../../../components/InputValue/InputValue';
import {
  validate,
  FormItemWithError,
  validateIfNegative
} from '../../../../../shared/validations';

const requiredFields = ['memory_limit', 'time_limit'];

const AddTestCaseDialog = ({
  isVisible,
  onSubmit,
  onCancel,
  editTestCaseData,
  problemInputs,
  problemOutput
}) => {
  const initialState = {
    memory_limit: null,
    time_limit: null,
    is_public: true,
    inputs: [...problemInputs],
    output: { ...problemOutput }
  };
  const [testCase, setTestCase] = useState(initialState);
  const [error, setError] = useState({});

  const resetState = () => {
    setTestCase({
      ...initialState,
      inputs: [],
      output: {}
    });
  };

  const updateInputValue = (index, value) => {
    const testCaseObj = { ...testCase };
    testCaseObj.inputs[index].value = value;
    setTestCase(testCaseObj);
  };

  const updateOutputValue = value => {
    const testCaseObj = { ...testCase };
    testCaseObj.output.value = value;
    setTestCase(testCaseObj);
  };

  const commaSeparatedArray = string => {
    return string.split(',');
  };

  const stringToNumber = (value, type) =>
    type === 'integer' ? parseInt(value, 10) : parseFloat(value);

  const stringToNumbersArray = (array, type) =>
    array.map(value => stringToNumber(value, type));

  const modifyArrayInputs = () => {
    let inputs = JSON.parse(JSON.stringify(testCase.inputs));
    inputs = inputs.map(inputParam => {
      const input = { ...inputParam };
      if (input.value && (input.type === 'integer' || input.type === 'float')) {
        input.value = stringToNumber(input.value, input.type);
      } else if (input.value && input.type === 'array') {
        input.value = commaSeparatedArray(input.value);
        if (input.sub_type === 'integer' || input.sub_type === 'float') {
          input.value = stringToNumbersArray(input.value, input.sub_type);
        }
      }
      return input;
    });
    return inputs;
  };

  const modifyArrayOutput = () => {
    const output = { ...testCase.output };
    if (
      output.value &&
      (output.type === 'integer' || output.type === 'float')
    ) {
      output.value = stringToNumber(output.value, output.type);
    } else if (output.value && output.type === 'array') {
      output.value = commaSeparatedArray(output.value);
      if (output.sub_type === 'integer' || output.sub_type === 'float') {
        output.value = stringToNumbersArray(output.value, output.sub_type);
      }
    }
    return output;
  };

  const validateIOValues = (inputs, output) => {
    const errors = {};
    let isValid = true;
    if (output.value === undefined) {
      isValid = false;
      errors.output_value = {
        message: 'output_value is required!'
      };
    } else if (output.value.length && output.value.includes(NaN)) {
      isValid = false;
      errors.output_value = {
        message: 'output_value is invalid!'
      };
    }
    inputs.forEach((input, index) => {
      if (input.value === undefined) {
        isValid = false;
        errors[`input_value_${index}`] = {
          message: `input_value_${index} is required!`
        };
      } else if (input.value.length && input.value.includes(NaN)) {
        isValid = false;
        errors[`input_value_${index}`] = {
          message: `input_value_${index} is invalid!`
        };
      }
    });
    return {
      isValid,
      errors
    };
  };

  const submit = () => {
    const inputs = modifyArrayInputs();
    const output = modifyArrayOutput();
    const valid = validate(testCase, requiredFields);
    const validValue = validateIfNegative(testCase, requiredFields);
    const IOValidate = validateIOValues(inputs, output);
    if (!valid.isValid || !IOValidate.isValid || !validValue) {
      const errors = {
        ...valid.errors,
        ...IOValidate.errors,
        ...validValue.errors
      };
      setError(errors);
      return;
    }
    const testCaseObj = { ...testCase };
    testCaseObj.inputs = inputs;
    testCaseObj.output = output;
    onSubmit(testCaseObj);
    resetState();
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  useEffect(() => {
    if (
      isVisible &&
      problemOutput &&
      problemOutput.name &&
      problemInputs.length
    ) {
      setTestCase({
        ...testCase,
        output: { ...problemOutput },
        inputs: [...problemInputs]
      });
    }
    // eslint-disable-next-line
  }, [isVisible, problemInputs, problemOutput]);

  useEffect(() => {
    if (editTestCaseData && editTestCaseData.memory_limit) {
      setTestCase(JSON.parse(JSON.stringify(editTestCaseData)));
    }
  }, [editTestCaseData]);

  return (
    <Modal
      title={
        editTestCaseData && editTestCaseData.memory_limit
          ? 'Edit Test Case'
          : 'Add Test Case'
      }
      centered
      visible={isVisible}
      okText={
        editTestCaseData && editTestCaseData.memory_limit ? 'Update' : 'Add'
      }
      width={720}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-test-case-dialog"
    >
      <div>
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Memory Limit (bytes)" required>
                {FormItemWithError(
                  <Input
                    type="number"
                    size="large"
                    min={0}
                    placeholder="Memory Limit (bytes)"
                    value={testCase.memory_limit}
                    onChange={e =>
                      setTestCase({
                        ...testCase,
                        memory_limit: Math.abs(e.target.value)
                      })
                    }
                  />,
                  'memory_limit',
                  error
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time Limit (milliseconds)" required>
                {FormItemWithError(
                  <Input
                    type="number"
                    size="large"
                    min={0}
                    placeholder="Time Limit (milliseconds)"
                    value={testCase.time_limit}
                    onChange={e =>
                      setTestCase({
                        ...testCase,
                        time_limit: Math.abs(e.target.value)
                      })
                    }
                  />,
                  'time_limit',
                  error
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form layout="inline">
          <Row className="m-b">
            <Col>
              <Form.Item label="Public">
                <Switch
                  checked={testCase.is_public}
                  onChange={value =>
                    setTestCase({ ...testCase, is_public: value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form>
          <h3>Inputs</h3>
          {testCase.inputs.map((input, index) => (
            <Row key={index} gutter={16} className="m-b">
              <Col span={6}>
                <Input
                  size="large"
                  placeholder="Name"
                  value={input.name}
                  disabled={true}
                />
              </Col>
              <Col span={4}>
                <Input
                  size="large"
                  placeholder="Type"
                  value={input.type}
                  disabled={true}
                />
              </Col>
              <Col span={4}>
                <Input
                  size="large"
                  placeholder="Sub Type"
                  value={input.sub_type ? input.sub_type : '-'}
                  disabled={true}
                />
              </Col>
              <Col span={10}>
                {FormItemWithError(
                  <InputValue
                    defaultValue={input.value}
                    type={input.type}
                    subType={input.sub_type}
                    onUpdate={value => updateInputValue(index, value)}
                  />,
                  `input_value_${index}`,
                  error
                )}
              </Col>
            </Row>
          ))}
          <h3>Output</h3>
          <Row gutter={16} className="m-b">
            <Col span={6}>
              <Input
                size="large"
                placeholder="Name"
                value={testCase.output.name}
                disabled={true}
              />
            </Col>
            <Col span={4}>
              <Input
                size="large"
                placeholder="Type"
                value={testCase.output.type}
                disabled={true}
              />
            </Col>
            <Col span={4}>
              <Input
                size="large"
                placeholder="Sub Type"
                value={
                  testCase.output.sub_type ? testCase.output.sub_type : '-'
                }
                disabled={true}
              />
            </Col>
            <Col span={10}>
              {FormItemWithError(
                <InputValue
                  defaultValue={testCase.output.value}
                  type={testCase.output.type}
                  subType={testCase.output.sub_type}
                  onUpdate={value => updateOutputValue(value)}
                />,
                'output_value',
                error
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

AddTestCaseDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editTestCaseData: PropTypes.objectOf(PropTypes.any),
  problemInputs: PropTypes.arrayOf(PropTypes.any).isRequired,
  problemOutput: PropTypes.objectOf(PropTypes.any).isRequired
};

AddTestCaseDialog.defaultProps = {
  editTestCaseData: null
};

export default AddTestCaseDialog;
