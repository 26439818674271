/* eslint-disable camelcase */
import './Blogs.less';

import { Button, Empty, Modal, Select, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AddBlogDialog from '../../components/AddBlogDialog/AddBlogDialogHOC';
import List from './List/List';
import Loader from '../../components/Loader/Loader';
import api from '../../api';

const { Option } = Select;
const { confirm } = Modal;
const Blogs = () => {
  const [state, setState] = useState({
    blogs: [],
    userGeneratedBlogs: [],
    editBlogData: {},
    page: 1,
    ugPage: 1, // user generated skills pagination
    pageEnd: false,
    loading: false,
    blogsLoaded: false,
    showAddBlogDialog: false,
    searchedBlogs: [],
    selectedBlogs: []
  });

  const fetchBlogs = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.blog
        .getList(
          updatedState.searchAmenities,
          updatedState.page,
          process.env.REACT_APP_PAGE_LIMIT
        )
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              blogs: [...updatedState.blogs, ...resp.items],
              blogsLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.page]
  );

  const fetchUserGeneratedBlogs = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.blog
        .getList('', updatedState.page, process.env.REACT_APP_PAGE_LIMIT, {
          is_user_generated: true
        })
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              userGeneratedBlogs: [
                ...updatedState.userGeneratedBlogs,
                ...resp.items
              ],
              blogsLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.ugPage]
  );

  const searchCalled = options => {
    const stateObj = { ...state };
    stateObj.blogs = [];
    stateObj.blogsLoaded = false;
    stateObj.editBlogData = {};
    const blogsArray = options.map(option => option.key);
    stateObj.searchBlogs = blogsArray.length
      ? blogsArray.reduce((query, item) => `${query},${item}`)
      : '';
    stateObj.selectedBlogs = options.map(option => option.props.value);
    setState({ ...stateObj });
    fetchBlogs(stateObj);
  };

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: stateObj.page + 1 }));
    }
  };

  const openAddBlogDialog = () =>
    setState(stateObj => ({ ...stateObj, showAddBlogDialog: true }));

  const closeAddBlogDialog = () =>
    setState(stateObj => ({
      ...stateObj,
      showAddBlogDialog: false,
      editBlogData: {}
    }));

  const updateBlogsList = data => {
    if (state.editBlogData.uuid) {
      const index = state.blogs.findIndex(blog => blog.uuid === data.uuid);
      const updatedBlogs = [...state.blogs];
      updatedBlogs[index] = data;
      setState(stateObj => ({ ...stateObj, blogs: updatedBlogs }));
      message.success('Blog edited successfully!');
    } else {
      setState(stateObj => ({
        ...stateObj,
        blogs: [data, ...stateObj.blogs]
      }));
      message.success('Blog added successfully!');
    }
    closeAddBlogDialog();
  };

  const editBlogHandler = (uuid, user_generated = false) => {
    let BlogToEdit;
    if (user_generated) {
      BlogToEdit = state.userGeneratedBlogs.find(blog => blog.uuid === uuid);
    } else {
      BlogToEdit = state.blogs.find(blog => blog.uuid === uuid);
    }
    setState(stateObj => ({
      ...stateObj,
      showAddBlogDialog: true,
      editBlogData: {
        uuid: BlogToEdit.uuid,
        title: BlogToEdit.title,
        slug: BlogToEdit.slug,
        description: BlogToEdit.description,
        featured_photo_url: BlogToEdit.featured_photo_url,
        content: BlogToEdit.content,
        status: BlogToEdit.status,
        seo_title: BlogToEdit.seo_title,
        seo_description: BlogToEdit.seo_description,
        is_featured: BlogToEdit.is_featured,
        read_time: BlogToEdit.read_time
      }
    }));
  };

  const deleteBlogHandler = (uuid, index, user_generated = false) => {
    api.blog.softDelete(uuid);
    const updatedBlogs = user_generated
      ? [...state.userGeneratedBlogs]
      : [...state.blogs];
    updatedBlogs.splice(index, 1);
    if (user_generated) {
      setState(stateObj => ({
        ...stateObj,
        userGeneratedBlogs: updatedBlogs
      }));
    } else {
      setState(stateObj => ({ ...stateObj, blogs: updatedBlogs }));
    }
    message.success('Blog deleted successfully!');
  };

  const showDeleteConfirmDialog = (uuid, index, user_generated = false) => {
    confirm({
      title: 'Do you want to Delete Blog?',
      content: `The ${state.blogs[index].title} blog will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBlogHandler(uuid, index, user_generated);
      }
    });
  };

  //   const fetchAmenityOptions = (query = '') => {
  //     api.ninja
  //       .fetchAmenities(query)
  //       .then(resp => {
  //         if (resp && resp.items.length) {
  //           setState(stateObj => ({
  //             ...stateObj,
  //             searchedAmenities: [...resp.items]
  //           }));
  //         }
  //       })
  //       .catch(err => {
  //         message.error(err.message);
  //       });
  //   };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchBlogs({ ...state });
      // eslint-disable-next-line no-console
      fetchUserGeneratedBlogs({ ...state });
      //   fetchAmenityOptions();
    } // eslint-disable-next-line
  }, [state.page, state.pageEnd, fetchBlogs]);

  return (
    <div className="skills-page">
      <div className="header">
        <h2>Blogs</h2>
        <div className="search-bar">
          <Select
            mode="multiple"
            placeholder="Search blogs"
            loading={state.loading}
            value={state?.selectedBlogs}
            size="large"
            style={{ width: '100%', paddingRight: '1rem' }}
            onChange={(values, options) => searchCalled(options)}
            // onSearch={query => fetchAmenityOptions(query)}
            maxTagCount={2}
          >
            {state?.searchedBlogs &&
              state?.searchedBlogs.length &&
              state?.searchedBlogs.map(blog => (
                <Option key={blog.uuid} value={blog.title}>
                  {blog.title}
                </Option>
              ))}
          </Select>
          <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={openAddBlogDialog}
          >
            Add Blog
          </Button>
        </div>
      </div>
      <div
        className="skills-list-wrapper"
        style={!state.blogsLoaded ? { position: 'relative' } : null}
        onScroll={handleScroll}
      >
        {state.loading && <Loader infiniteLoader={state.blogsLoaded} />}
        {!state.blogs.length && !state.loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <List
            blogs={state.blogs}
            editBlogHandler={editBlogHandler}
            deleteBlogHandler={showDeleteConfirmDialog}
            userGeneratedBlogs={state.userGeneratedBlogs}
          />
        )}
      </div>
      <AddBlogDialog
        isVisible={state.showAddBlogDialog}
        onSubmit={updateBlogsList}
        onCancel={closeAddBlogDialog}
        editBlogData={state.editBlogData}
      />
    </div>
  );
};

export default Blogs;
