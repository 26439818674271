import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  message,
  Tabs,
  InputNumber,
  Modal,
  Select
} from 'antd';

import './AddTestDrawer.less';
import api from '../../../api';
import ProblemsList from '../../Problems/ProblemsList/ProblemsListHOC';
import ProblemDetails from '../../../components/ProblemDetails/ProblemDetails';
import Loader from '../../../components/Loader/Loader';
import { removeNullValues } from '../../../shared/utility';

const { TabPane } = Tabs;
const { confirm } = Modal;

const AddTestDrawer = ({
  isVisible,
  onSubmit,
  onCancel,
  editTestData,
  problemLevel
}) => {
  const initialState = {
    title: '',
    attempts_limit: null,
    total_score: null,
    time_limit: null,
    problems: [],
    is_draft: false
  };
  const [test, setTest] = useState(editTestData && initialState);
  const [loading, setLoading] = useState(false);
  const [allProblems, setAllProblems] = useState([]);
  const [problemDrawerVisible, setProblemDrawerVisible] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState({});
  const [activeKey, setActiveKey] = useState('1');
  const [page, setPage] = useState(1);
  const [pageEnd, setPageEnd] = useState(false);
  const [problemsLoaded, setProblemsLoaded] = useState(false);
  const [problemsLoading, setProblemsLoading] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState();

  const fetchProblems = useCallback(
    (level = null) => {
      setProblemsLoading(true);
      api.problems
        .getProblems('', page, process.env.REACT_APP_PAGE_LIMIT, level)
        .then(resp => {
          setProblemsLoading(false);
          if (resp && resp.items.length) {
            setAllProblems(resp.items);
            setProblemsLoaded(false);
          } else {
            setPageEnd(true);
          }
        })
        .catch(err => {
          setProblemsLoading(false);
          message.error(err.message);
        });
    },
    [page]
  );

  const resetState = () => setTest(initialState);

  const addUpdateTest = data => {
    setLoading(true);
    const call = data.uuid ? api.test.update(data) : api.test.add(data);
    call
      .then(resp => {
        setLoading(false);
        if (resp && resp.uuid) {
          resetState();
          onSubmit(resp);
        }
      })
      .catch(err => {
        setLoading(false);
        if (err) message.error(err.message);
      });
  };

  const deleteProblemHandler = index => {
    const updatedTestProblems = [...test.problems];
    updatedTestProblems.splice(index, 1);
    setTest({ ...test, problems: updatedTestProblems });
  };
  const showProblemDrawer = problem => {
    setSelectedProblem(problem);
    setProblemDrawerVisible(true);
  };

  const showDeleteConfirmDialog = index => {
    confirm({
      title: 'Do you want to remove test problem?',
      content: `The ${test.problems[index].title} problem will be removed.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteProblemHandler(index);
      }
    });
  };

  const getUuids = problems => {
    return problems.map(({ uuid }) => uuid);
  };

  const addProblem = problem => {
    if (getUuids(test.problems).includes(problem.uuid)) {
      message.error('Problem is already in test');
    } else {
      message.success('Problem added to test successfully!');
      setTest({ ...test, problems: [...test.problems, problem] });
    }
  };

  const submit = () => {
    const problemUuids = test.problems.map(({ uuid }) => uuid);
    const testData = { ...test, problems: problemUuids };

    addUpdateTest(removeNullValues(testData));
  };

  const cancel = () => {
    resetState();
    onCancel();
    setActiveKey('1');
  };

  const onClose = () => {
    setProblemDrawerVisible(false);
  };

  const handleChange = value => {
    setAllProblems([]);
    setDifficultyLevel(value);
    setProblemsLoaded(false);
    if (page === 1) {
      fetchProblems(value);
    } else {
      setPageEnd(false);
      setPage(1);
    }
  };

  const handleProblemsScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      if (!pageEnd) {
        setPage(page + 1);
      }
    }
  };

  const tabBarExtraContent = () => {
    return (
      <div className="problem-filter">
        <Select
          defaultValue="All Problems"
          style={{ width: '120px' }}
          onChange={handleChange}
        >
          <Select.Option value={null}>All Problems</Select.Option>
          {Object.keys(problemLevel).map((key, index) => (
            <Select.Option value={index} key={key}>
              {problemLevel[key].name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  useEffect(() => {
    if (!pageEnd) fetchProblems(difficultyLevel);
    // eslint-disable-next-line
  }, [fetchProblems, page]);

  useEffect(() => {
    if (editTestData && editTestData.uuid) {
      setTest(editTestData);
    }
  }, [editTestData]);

  return (
    <div>
      {loading && <Loader />}
      <Drawer
        title={editTestData && editTestData.uuid ? 'Edit Test' : 'Add Test'}
        onClose={cancel}
        visible={isVisible}
        className="test-drawer"
        maskClosable={false}
        destroyOnClose={true}
      >
        <Tabs
          onChange={key => setActiveKey(key)}
          activeKey={activeKey}
          tabBarExtraContent={activeKey === '2' && tabBarExtraContent()}
        >
          <TabPane tab="Test Details" key="1">
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Title" required>
                    <Input
                      size="large"
                      placeholder="Test Title"
                      value={test.title}
                      onChange={e =>
                        setTest({ ...test, title: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Time Limit (minutes)">
                    <InputNumber
                      type="number"
                      size="large"
                      placeholder="Time Limit (minutes)"
                      min={0}
                      max={999}
                      defaultValue={0}
                      value={test.time_limit}
                      onChange={value =>
                        setTest({ ...test, time_limit: value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Attempts Limit">
                    <InputNumber
                      size="large"
                      placeholder="Attempts Limit"
                      min={0}
                      maxLength={3}
                      defaultValue={0}
                      value={test.attempts_limit}
                      onChange={value =>
                        setTest({ ...test, attempts_limit: value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Total Score">
                    <InputNumber
                      size="large"
                      placeholder="Greater than zero (i.e. 10)"
                      min={1}
                      maxLength={3}
                      defaultValue={1}
                      value={test.total_score}
                      onChange={value =>
                        setTest({ ...test, total_score: value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="problems-table-title">Problems</div>
            <ProblemsList
              problems={test.problems}
              showLevel={false}
              editProblem={false}
              showDrawer={showProblemDrawer}
              deleteProblemHandler={showDeleteConfirmDialog}
            />
          </TabPane>
          <TabPane tab="Add Problems" key="2">
            {problemsLoading && <Loader infiniteLoader={problemsLoaded} />}
            <div
              className="problems-list-wrapper"
              onScroll={handleProblemsScroll}
            >
              <ProblemsList
                problems={allProblems}
                showLevel={true}
                editProblem={false}
                showDrawer={showProblemDrawer}
                showAddIcon={true}
                addProblemToTest={addProblem}
                testProblems={getUuids(test.problems)}
                deleteProblemHandler={deleteProblemHandler}
              />
            </div>
          </TabPane>
        </Tabs>
        <div className="drawer-buttons">
          <Button className="cancel-button" onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={submit} type="primary">
            Submit
          </Button>
        </div>
        <Drawer
          title={
            selectedProblem.title ? selectedProblem.title : 'Marked Problem'
          }
          closable={true}
          onClose={onClose}
          visible={problemDrawerVisible}
          className="nested-problem-drawer"
          destroyOnClose={true}
        >
          <ProblemDetails problem={selectedProblem} />
        </Drawer>
      </Drawer>
    </div>
  );
};

AddTestDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editTestData: PropTypes.objectOf(PropTypes.any),
  problemLevel: PropTypes.arrayOf(PropTypes.any).isRequired
};

AddTestDrawer.defaultProps = {
  editTestData: null
};

export default AddTestDrawer;
