import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Button, Modal } from 'antd';

import { branchStatusColor } from '../../shared/utility';
import CategorySet from '../CategorySet/CategorySet';
import AddEditBranch from '../../pages/Branches/AddEditBranch/AddEditBranch';

const { confirm } = Modal;

const BranchCard = ({
  data,
  openDetails,
  col,
  branchStatus,
  branchCommitment,
  onDuplicateBranch,
  onDelete,
  onEdit
}) => {
  const { branchId } = useParams();
  const [showMarkedBranchDrawer, setShowMarkedBranchDrawer] = useState(false);
  const commitments = {};
  // eslint-disable-next-line no-return-assign
  branchCommitment.map(item => (commitments[item.value] = item.name));

  const showDuplicateBranchConfirmDialog = () => {
    confirm({
      title: 'Are you sure you want to duplicate this branch?',
      content: `${data.name} will be duplicated.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDuplicateBranch(data.uuid);
      }
    });
  };

  const showConfirmDialog = () => {
    confirm({
      title: 'Are you sure you want to delete this branch?',
      content: `${data.name} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDelete(data.uuid);
      }
    });
  };

  const closeMarkedBranchDrawer = () => {
    setShowMarkedBranchDrawer(false);
  };

  const openMarkedBranchDrawer = () => {
    setShowMarkedBranchDrawer(true);
  };

  const onEditBranch = () => {
    console.log('HAHAHAHAHAHAHAHAHA ON EDIT BRANCH');
    openMarkedBranchDrawer();
  };

  const updateMarkedBranch = data => {
    // const index = state.branches.findIndex(branch => branch.uuid === data.uuid);
    // const updatedBranches = [...state.branches];
    // updatedBranches[index] = data;
    // setState(stateObj => ({ ...stateObj, branches: updatedBranches }));
    closeMarkedBranchDrawer();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Button
          type="link"
          icon="copy"
          onClick={showDuplicateBranchConfirmDialog}
          style={{ display: 'flex', marginRight: 'auto', marginTop: '0.5rem' }}
        />
        <Button type="link" icon="edit" onClick={() => onEdit(data.uuid)}>
          Edit
        </Button>
        <Button type="link" icon="delete" onClick={showConfirmDialog}>
          Delete
        </Button>
      </div>
      <div
        className={`branch-card ${data.uuid === branchId ? 'selected' : ''}`}
        style={{ padding: '1rem' }}
        onClick={() => openDetails(data)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>
            {data.name}
            {data.status !== null && branchStatus && branchStatus[0] ? (
              <>
                {' | '}
                <Tag color={branchStatusColor(data.status)}>
                  {branchStatus.find(item => item.value === data.status)?.name}
                </Tag>
              </>
            ) : (
              ''
            )}
          </h4>
        </div>
        {/* <div className="salary-range">
          {data.currency} {currencyFilter(data.min_salary)}
        </div> */}
        {data && <CategorySet categories={data.categories} />}
        <Descriptions column={col}>
          <Descriptions.Item label="City">{data.city}</Descriptions.Item>
          <Descriptions.Item label="Sector">{data.sector}</Descriptions.Item>
          <Descriptions.Item label="Phone No">
            {data.phone_no}
          </Descriptions.Item>
          <Descriptions.Item label="Total Reviews">
            {data.total_reviews}
          </Descriptions.Item>
          <Descriptions.Item label="Total Views">
            {data.total_view_count}
          </Descriptions.Item>
          <Descriptions.Item label="Price Per Person">
            {data.price_per_person ? data.price_per_person : `N/A`}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Status">
            {commitments[data.commitment]}
          </Descriptions.Item> */}
          <Descriptions.Item label="Is Main Branch">
            {data.is_main ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Rating">{data.rating}</Descriptions.Item>
          <Descriptions.Item label="Is Advertiser">
            {data.is_advertiser ? 'Yes' : 'No'}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Directions Availibility">
            {data.directions_availability ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Menu Link">
            {data.menu_link}
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Features">
            {data ? <FeatureSet features={data.features} /> : 'N/A'}
          </Descriptions.Item> */}
        </Descriptions>
      </div>
      {/* <AddEditBranch
        isVisible={showMarkedBranchDrawer}
        onCancel={closeMarkedBranchDrawer}
        onSubmit={updateMarkedBranch}
        targetBranch={data}
        editBranch={false}
        business_uuid={data.business_uuid}
      /> */}
      {/* <AddEditBranch
        isVisible={showMarkedBranchDrawer}
        onCancel={closeMarkedBranchDrawer}
        onSubmit={updateMarkedBranch}
        targetBranch={data}
        editBranch={false}
        business_uuid={data.business_uuid}
      /> */}
    </>
  );
};

BranchCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  col: PropTypes.number.isRequired,
  openDetails: PropTypes.func,
  branchStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  branchCommitment: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDuplicateBranch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

BranchCard.defaultProps = {
  openDetails: () => {}
};

export default BranchCard;
