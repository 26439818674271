import { connect } from 'react-redux';
import Basic from './Basic';

const mapStateToProps = state => ({
  problemLevels:
    state.config.meta.problem && state.config.meta.problem.level
      ? state.config.meta.problem.level
      : [],
  solutionLanguages:
    state.config.meta && state.config.meta.languages
      ? state.config.meta.languages
      : []
});

export default connect(mapStateToProps)(Basic);
