import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Select, Row, Col, Button } from 'antd';
import StackEdit from '../../../../components/StackEdit/StackEdit';

import { validate, FormItemWithError } from '../../../../shared/validations';
import InputTypes from '../../../../assets/json/InputTypes.json';

const InputSubTypes = InputTypes.filter(inputType => inputType !== 'array');
const { Option } = Select;
const requiredFields = [
  'code',
  'title',
  'short_body',
  'body',
  'solution',
  'marks',
  'level',
  'solution_language'
];

const Basic = ({
  targetProblem,
  onSubmit,
  problemLevels,
  solutionLanguages
}) => {
  const problemInitialState = {
    output: {
      name: '',
      type: undefined,
      sub_type: undefined
    }
  };
  const [problem, setProblem] = useState(problemInitialState);
  const [error, setError] = useState({});

  const updateProblemBody = body => {
    setProblem(problemObj => ({ ...problemObj, body }));
  };

  useEffect(() => {
    let data = { ...problemInitialState };
    if (targetProblem && targetProblem.uuid) {
      data = {
        uuid: targetProblem.uuid,
        code: targetProblem.code,
        title: targetProblem.title,
        short_body: targetProblem.short_body,
        body: targetProblem.body,
        solution: targetProblem.solution,
        total_attempts: targetProblem.total_attempts,
        marks: targetProblem.marks,
        total_score: targetProblem.total_score,
        level: targetProblem.level,
        output: targetProblem.output,
        solution_language: targetProblem.solution_language
      };
      setProblem(data);
    }
    // eslint-disable-next-line
  }, [targetProblem]);
  const submit = () => {
    const requiredOutputFields = ['name', 'type'];
    if (problem.output.type === 'array') {
      requiredOutputFields.push('sub_type');
    }
    const valid = validate(problem, requiredFields);
    const validOutput = validate(problem.output, requiredOutputFields);

    if (!valid.isValid || !validOutput.isValid) {
      setError({ ...valid.errors, ...validOutput.errors });
    } else {
      setError({});
      onSubmit(problem);
    }
  };

  const updateOutput = (name, value) => {
    const problemObj = { ...problem };
    problemObj.output[name] = value;
    setProblem(problemObj);
  };

  useEffect(() => {
    if (problem && problem.uuid) {
      onSubmit(problem, true);
    }
    // eslint-disable-next-line
  }, [problem]);

  return (
    <div className="problem-basic-form">
      <Form>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Short Code" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Short Code"
                  name="code"
                  value={problem.code}
                  maxLength={20}
                  onChange={e =>
                    setProblem({ ...problem, code: e.target.value })
                  }
                />,
                'code',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Title" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Problem Title (Simplified)"
                  name="title"
                  value={problem.title}
                  onChange={e =>
                    setProblem({ ...problem, title: e.target.value })
                  }
                />,
                'title',
                error
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Short Body" required>
          {FormItemWithError(
            <Input
              size="large"
              placeholder="Problem Short Body"
              name="short_body"
              value={problem.short_body}
              onChange={e =>
                setProblem({ ...problem, short_body: e.target.value })
              }
            />,
            'short_body',
            error
          )}
        </Form.Item>
        {problem.uuid ? (
          <Form.Item label="Body" required>
            {FormItemWithError(
              <StackEdit
                value={problem.body}
                onUpdate={updateProblemBody}
                id="problem-body"
                placeholder="Write detailed problem body here"
              />,
              'body',
              error
            )}
          </Form.Item>
        ) : null}
        {problem && !problem.uuid ? (
          <Form.Item label="Body" required>
            {FormItemWithError(
              <StackEdit
                value={problem.body}
                onUpdate={updateProblemBody}
                id="problem-body"
                placeholder="Write detailed problem body here"
              />,
              'body',
              error
            )}
          </Form.Item>
        ) : null}
        <Form.Item label="Select Solution Language" required>
          {FormItemWithError(
            <Select
              size="large"
              placeholder="Select Language"
              showArrow={true}
              value={problem.solution_language}
              onChange={value =>
                setProblem({ ...problem, solution_language: value })
              }
            >
              {solutionLanguages &&
                solutionLanguages.map(language => (
                  <Option key={language.value} value={language.value}>
                    {language.name}
                  </Option>
                ))}
            </Select>,
            'solution_language',
            error
          )}
        </Form.Item>
        <Form.Item label="Solution" required>
          {FormItemWithError(
            <Input.TextArea
              rows={4}
              placeholder="Write soultion code here"
              name="soultion"
              value={problem.solution}
              onChange={e =>
                setProblem({ ...problem, solution: e.target.value })
              }
            />,
            'solution',
            error
          )}
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Marks" required>
              {FormItemWithError(
                <InputNumber
                  type="number"
                  size="large"
                  min={0}
                  placeholder="Total Marks"
                  className="full-width"
                  name="marks"
                  value={problem.marks}
                  onChange={value => setProblem({ ...problem, marks: value })}
                />,
                'marks',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Attempts">
              <InputNumber
                type="number"
                size="large"
                min={0}
                placeholder="Total Attempts"
                className="full-width"
                name="total_attempts"
                value={problem.total_attempts}
                onChange={value =>
                  setProblem({ ...problem, total_attempts: value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Total Score">
              <InputNumber
                type="number"
                size="large"
                min={1}
                placeholder="Greater than zero (i.e. 10)"
                className="full-width"
                name="total_score"
                value={problem.total_score}
                onChange={value =>
                  setProblem({ ...problem, total_score: value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Difficulty Level" required>
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Select Level"
                  className="full-width"
                  name="level"
                  value={
                    problem.level !== undefined
                      ? String(problem.level)
                      : undefined
                  }
                  onChange={value => setProblem({ ...problem, level: value })}
                >
                  {Object.keys(problemLevels).map(key => (
                    <Select.Option value={key} key={key}>
                      {problemLevels[key].name}
                    </Select.Option>
                  ))}
                </Select>,
                'level',
                error
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Output Name" required>
              {FormItemWithError(
                <Input
                  size="large"
                  placeholder="Output Name"
                  value={problem.output && problem.output.name}
                  onChange={e => updateOutput('name', e.target.value)}
                />,
                'name',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Select Type" required>
              {FormItemWithError(
                <Select
                  size="large"
                  placeholder="Select Type"
                  showArrow={true}
                  value={problem.output && problem.output.type}
                  onChange={value => updateOutput('type', value)}
                >
                  {InputTypes &&
                    InputTypes.map((type, index) => (
                      <Option key={index} value={type}>
                        {type}
                      </Option>
                    ))}
                </Select>,
                'type',
                error
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            {problem.output && problem.output.type === 'array' ? (
              <Form.Item label="Select Sub Type" required>
                {FormItemWithError(
                  <Select
                    size="large"
                    placeholder="Select Sub Type"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    value={problem.output && problem.output.sub_type}
                    style={{ width: '100%' }}
                    onChange={value => updateOutput('sub_type', value)}
                  >
                    {InputSubTypes &&
                      InputSubTypes.map((type, index) => (
                        <Option key={index} value={type}>
                          {type}
                        </Option>
                      ))}
                  </Select>,
                  'sub_type',
                  error
                )}
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      </Form>
      <div className="m-b">
        <Button onClick={() => submit()} type="primary" size="large">
          Next
        </Button>
      </div>
    </div>
  );
};

Basic.propTypes = {
  targetProblem: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  problemLevels: PropTypes.arrayOf(PropTypes.any).isRequired,
  solutionLanguages: PropTypes.arrayOf(PropTypes.any).isRequired
};

Basic.defaultProps = {
  targetProblem: {}
};

export default Basic;
