import http from './http';
import { removeEmpty } from '../shared/utility';

const RESOURCE_URL = '/branch';

const get = uuid => {
  return new Promise((resolve, reject) => {
    http
      .get(`/branch/${uuid}?with=categories,amenities,menu,media`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const getList = (
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT,
  filters
) => {
  return new Promise((resolve, reject) => {
    const params = {
      p: page,
      l: limit,
      with: 'location,categories',
      ...filters
    };
    http
      .get('/branch', removeEmpty(params))
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const update = (uuid, data) => {
  return new Promise((resolve, reject) => {
    http
      .put(`/branch/${uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};
const getApplicants = (uuid, query = '') => {
  return new Promise((resolve, reject) => {
    http
      .get(`/branch/${uuid}/ninjas`, query)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const duplicate = uuid => {
  return new Promise((resolve, reject) => {
    http
      .post(`/branch/${uuid}/duplicate`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const remove = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`/branch/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  get,
  getList,
  update,
  getApplicants,
  add,
  duplicate,
  remove
};
