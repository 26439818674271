import http from './http';

const RESOURCE_URL = '/media';

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const update = data => {
  return new Promise((resolve, reject) => {
    http
      .put(`${RESOURCE_URL}/${data.uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getList = (targetUuid, category, page = 1, limit = 1000) => {
  return new Promise((resolve, reject) => {
    const params = {
      p: page,
      l: limit,
      target_uuid: targetUuid,
      category
    };
    http
      .get(RESOURCE_URL, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const get = uuid => {
  return new Promise((resolve, reject) => {
    http
      .get(`${RESOURCE_URL}/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const softDelete = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`${RESOURCE_URL}/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  add,
  update,
  getList,
  get,
  softDelete
};
