import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Divider, Button, Icon } from 'antd';

import './ApplicantDetails.less';
import SkillSet from '../CategorySet/CategorySet';
import {
  currencyFilter,
  copyToClipboard,
  openMail
} from '../../shared/utility';

const ApplicantDetails = ({
  data,
  applicantStatus,
  jobCommitment,
  jobMaxSalary
}) => {
  const commitments = {};
  jobCommitment.map(item => commitments[item.value] = item.name);

  return (
    <div className="applicant-details">
      <div className="meta m-b">
        <div className="m-b">
          <h2 className="name t-b">
            {data.user.first_name} {data.user.last_name}
          </h2>
        </div>
        <Descriptions>
          <Descriptions.Item label="Status">
            {applicantStatus[data.pivot.status].name}
          </Descriptions.Item>
          <Descriptions.Item label="Commitment">
            {commitments[data.commitment]}
          </Descriptions.Item>
          <Descriptions.Item label="Total Experience">
            {data.experience_started.years} years
          </Descriptions.Item>
          <Descriptions.Item label="Salary Interval">
            {data.salary_interval}
          </Descriptions.Item>
          <Descriptions.Item label="Expected Salary">
            {data.currency} {currencyFilter(data.salary)}
          </Descriptions.Item>
          <Descriptions.Item label="Job's Salary">
            {data.currency} {currencyFilter(jobMaxSalary)}
          </Descriptions.Item>
          <Descriptions.Item label="Views">
            {data.total_views}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Result URL">
            {data.test_result_url}
          </Descriptions.Item> */}
          <Descriptions.Item label="Location">
            {data.user.location.long_name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {data.phone || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Button
              type="link"
              className="link-button"
              style={{ paddingRight: 0 }}
              onClick={e => openMail(e, data.user.email)}
            >
              {data.user.email || 'N/A'}
            </Button>
            <Icon
              type="copy"
              theme="outlined"
              onClick={() => copyToClipboard(data.user.email)}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="skills m-b">
        <div className="t-b m-b">Skills:</div>
        {data && data.skills && <SkillSet skills={data.skills} />}
      </div>
      <div className="description m-b">
        <div className="t-b m-b">Description:</div>
        <div>{data.description}</div>
      </div>
      <Divider className="description-divider" />
      <div className="cover-letter m-b">
        <div className="t-b m-b">Cover Letter:</div>
        <div>{data.pivot.cover_letter}</div>
      </div>
      <Divider className="cover-letter-divider" />
    </div>
  );
};

ApplicantDetails.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  applicantStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobCommitment: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobMaxSalary: PropTypes.number
};

ApplicantDetails.defaultProps = {
  jobMaxSalary: 0
};

export default ApplicantDetails;
