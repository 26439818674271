import React from 'react';
import { Descriptions, Divider } from 'antd';

import PropTypes from 'prop-types';

const ProblemTestCase = ({ testCases }) => {
  return (
    <div className="test-case">
      {testCases.map((testCase, index) => (
        <div key={index}>
          <h2>{`Test Case ${index}`}</h2>
          <Descriptions className="m-t">
            <Descriptions.Item label="Public">
              {JSON.stringify(testCase.is_public)}
            </Descriptions.Item>
            <Descriptions.Item label="Memory Limit">
              {`${testCase.memory_limit} bytes`}
            </Descriptions.Item>
            <Descriptions.Item label="Time Limit">
              {`${testCase.time_limit} ms`}
            </Descriptions.Item>
          </Descriptions>
          <h3>Inputs</h3>
          {testCase.inputs.map((input, i) => (
            <Descriptions key={i}>
              <Descriptions.Item label="Name">{input.name}</Descriptions.Item>
              <Descriptions.Item label="Type">{input.type}</Descriptions.Item>
              {input.type === 'array' ? (
                <Descriptions.Item label="Sub Type">
                  {input.sub_type}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Value">
                {JSON.stringify(input.value)}
              </Descriptions.Item>
            </Descriptions>
          ))}
          <h3>Output</h3>
          <div className="m-b">
            <Descriptions>
              <Descriptions.Item label="Name">
                {testCase.output.name}
              </Descriptions.Item>
              <Descriptions.Item label="Type">
                {testCase.output.type}
              </Descriptions.Item>
              {testCase.output.type === 'array' ? (
                <Descriptions.Item label="Sub Type">
                  {testCase.output.sub_type}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Value">
                {JSON.stringify(testCase.output.value)}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Divider className="m-t-0" />
        </div>
      ))}
    </div>
  );
};

ProblemTestCase.propTypes = {
  testCases: PropTypes.arrayOf(PropTypes.any)
};

ProblemTestCase.defaultProps = {
  testCases: []
};

export default ProblemTestCase;
