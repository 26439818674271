import { connect } from 'react-redux';
import Basic from './Basic';

const mapStateToProps = state => ({
  // problemLevels:
  //   state.config.meta.problem && state.config.meta.problem.level
  //     ? state.config.meta.problem.level
  //     : [],
  // businessTypes:
  //   state.config.meta && state.config.meta.business.types
  //     ? state.config.meta.business.types
  //     : []
});

export default connect(mapStateToProps)(Basic);
