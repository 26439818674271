import './Branches.less';

import { Card, Col, Divider, Icon, Row, Tag, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Details from './Details/DetailsHOC';
import ExtensiveSearch from '../../components/ExtensiveSearch/ExtensiveSearchHOC';
import List from './List/List';
import UpdateBranchStatusDropdown from '../../components/UpdateBranchStatusDropdown/UpdateBranchStatusDropdown';
import api from '../../api';

const Branches = () => {
  const { branchId } = useParams();
  const meta = useSelector(state => state.config.meta);
  const commitments = {};
  // meta?.branch?.commitment?.length && meta.branch.commitment.map(item => commitments[item.value] = item.name);

  const experiences = {};
  // meta?.branch?.experience?.length && meta.branch.experience.map(item => experiences[item.value] = item.name);

  const status = {};
  meta?.branch?.status?.length &&
    // eslint-disable-next-line no-return-assign
    meta.branch.status.map(item => (status[item.value] = item.name));

  const [state, setState] = useState({
    branches: [],
    branchFilters: {},
    selectedBranch: {},
    page: 1,
    loading: false,
    pageLoaded: false,
    pageEnd: false,
    branchStatus: null,
    searchQuery: '',
    showBranchesSearchDialog: false
  });
  const history = useHistory();

  const fetchBranches = useCallback(
    updatedState => {
      if (!updatedState.loading) {
        setState(stateObj => ({ ...stateObj, loading: true }));
        api.branches
          .getList(updatedState.page, 10, updatedState.branchFilters)
          .then(resp => {
            if (resp && resp.items.length) {
              setState(stateObj => ({
                ...stateObj,
                branches: [...updatedState.branches, ...resp.items],
                pageLoaded: true,
                loading: false
              }));
            } else if (!resp.items.length) {
              setState(stateObj => ({
                ...stateObj,
                pageEnd: true,
                loading: false
              }));
            } else {
              setState(stateObj => ({
                ...stateObj,
                loading: false
              }));
            }
          })
          .catch(err => {
            setState(stateObj => ({ ...stateObj, loading: false }));
            message.error(err.message);
          });
      }
    }, // eslint-disable-next-line
    [state.page]
  );

  const handleApplyFilters = filters => {
    const stateObj = { ...state };
    stateObj.branches = [];
    stateObj.branchFilters = filters;
    stateObj.loading = false;
    stateObj.pageLoaded = false;
    stateObj.showBranchesSearchDialog = false;
    if (state.page === 1) {
      setState({ ...stateObj });
      history.push('/braches');
      fetchBranches(stateObj);
    } else {
      stateObj.page = 1;
      stateObj.pageEnd = false;
      history.push('/branches');
      setState({ ...stateObj });
    }
  };

  const openNinjasSearch = () =>
    setState(stateObj => ({ ...stateObj, showJobsSearchDialog: true }));

  const closeNinjasSearch = () =>
    setState(stateObj => ({ ...stateObj, showJobsSearchDialog: false }));

  const setPage = pageNumber =>
    setState(stateObj => ({ ...stateObj, page: pageNumber }));

  const setSelectedBranch = selectedBranch =>
    setState(stateObj => ({ ...stateObj, selectedBranch }));

  const setPageEnd = endPage =>
    setState(stateObj => ({ ...stateObj, pageEnd: endPage }));

  const removeFilter = filterItem => {
    const updatedState = { ...state };
    updatedState.branches = [];

    if (filterItem === 'salary') {
      updatedState.branchFilters.min_salary = 0;
      updatedState.branchFilters.max_salary = 10000;
    } else {
      delete updatedState.branchFilters[filterItem];
    }

    setState({ ...updatedState });
    fetchBranches(updatedState);
  };

  const onBranchStatusUpdate = updatedBranch => {
    const branches = [...state.branches];
    const index = branches.findIndex(
      branch => branch.uuid === updatedBranch.uuid
    );
    branches[index].status = updatedBranch.status;
    setState(stateObj => ({
      ...stateObj,
      branches,
      selectedBranch: updatedBranch
    }));
  };

  const cardTitleGenerator = () => {
    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col>{state?.selectedBranch?.name}</Col>
        <Col>
          <UpdateBranchStatusDropdown
            data={state.selectedBranch}
            onUpdate={onBranchStatusUpdate}
          />
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchBranches(state);
    } // eslint-disable-next-line
  }, [state.pageEnd, fetchBranches]);

  return (
    <div className="branches-page">
      <Row>
        <Col className="branches-list-section" span={8}>
          <div className="search-container">
            <div className="search-box" onClick={openNinjasSearch}>
              Search Branches
              <Icon type="search" theme="outlined" />
            </div>
          </div>
          <div className="filters">
            {state?.branchFilters?.q ? (
              <Tag color="blue" closable onClose={() => removeFilter('q')}>
                Name: {state.branchFilters.q}
              </Tag>
            ) : null}
            {state?.branchFilters?.categories?.length ? (
              <Tag
                color="blue"
                closable
                onClose={() => removeFilter('categories')}
              >
                {state.branchFilters.categories.length} category(s)
              </Tag>
            ) : null}
            {state?.branchFilters?.min_salary &&
            state?.branchFilters?.max_salary &&
            (state.branchFilters.min_salary > 0 ||
              state.branchFilters.experience_started[1] < 10) ? (
              <Tag color="blue" closable onClose={() => removeFilter('salary')}>
                {state.branchFilters.min_salary}-{state.branchFilters.max_salary}/hr
              </Tag>
            ) : null}
            {state?.branchFilters?.experience ? (
              <Tag
                color="blue"
                closable
                onClose={() => removeFilter('experience')}
              >
                {experiences[state.branchFilters.experience]}
              </Tag>
            ) : null}
            {state?.branchFilters?.status ? (
              <Tag color="blue" closable onClose={() => removeFilter('status')}>
                Status: {meta.branch.status[state.branchFilters.status].name}
              </Tag>
            ) : null}
            {state?.branchFilters?.commitment ? (
              <Tag
                color="blue"
                closable
                onClose={() => removeFilter('commitment')}
              >
                {commitments[state.branchFilters.commitment]}
              </Tag>
            ) : null}
          </div>
          <Divider />
          <List
            branches={state?.branches}
            loading={state?.loading}
            pageLoaded={state?.pageLoaded}
            page={state?.page}
            setPage={setPage}
            setSelectedJob={setSelectedBranch}
            setPageEnd={setPageEnd}
          />
        </Col>
        <Col className="branch-details-page" span={16}>
          {branchId && (
            <>
              <Card title={cardTitleGenerator()} className="branch-detail-card">
                <Details data={state?.selectedBranch} />
              </Card>
            </>
          )}
        </Col>
      </Row>
      <ExtensiveSearch
        handleApplyFilters={handleApplyFilters}
        isVisible={state?.showBranchesSearchDialog}
        onCancel={closeNinjasSearch}
        ninjaFilters={state?.branchFilters}
        searchNinjas={false}
      />
    </div>
  );
};

export default Branches;
