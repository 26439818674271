import { connect } from 'react-redux';
import Details from './Details';

const mapStateToProps = state => ({
  branchStatus:
    state.config.meta.branch && state.config.meta.branch.status
      ? state.config.meta.branch.status
      : []
});

export default connect(mapStateToProps)(Details);
