import { connect } from 'react-redux';
import Basic from './Basic';

const mapStateToProps = state => ({
  // branchTypes:
  //   state.config.meta && state.config.meta.branch.types
  //     ? state.config.meta.branch.types
  //     : []
});

export default connect(mapStateToProps)(Basic);
