import http from './http';

const fetchMeta = () => {
  return new Promise((resolve, reject) => {
    http
      .get('/meta')
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  fetchMeta
};
