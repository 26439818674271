/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Icon, Empty } from 'antd';
import PropTypes from 'prop-types';

const ProblemsList = ({
  problems,
  testProblems,
  showDrawer,
  showLevel,
  editProblem,
  showAddIcon,
  deleteProblemHandler,
  addProblemToTest,
  onSelect,
  problemLevels
}) => {
  const prepareProblemsRow = (problem, index) => {
    return (
      <div className="problems-row" key={`${problem.uuid}-${index}`}>
        <div className="problems-row-item" style={{ width: '100px' }}>
          {problem.code}
        </div>
        <div className="problems-row-item capitalize">{problem.title}</div>
        {showLevel ? (
          <div className="problems-row-item capitalize">
            {problemLevels &&
              problemLevels[problem.level] &&
              problemLevels[problem.level].name}
          </div>
        ) : null}
        <div className="problems-row-item capitalize">0</div>
        <div className="problems-row-item actions">
          <Icon
            type="eye"
            theme="outlined"
            onClick={() => showDrawer(problem)}
          />
          {editProblem ? (
            <Icon
              type="edit"
              theme="outlined"
              onClick={() => onSelect(problem)}
            />
          ) : null}
          {!showLevel ? (
            <Icon
              type="delete"
              theme="outlined"
              onClick={() => deleteProblemHandler(index)}
            />
          ) : null}
          {showAddIcon ? (
            testProblems.includes(problem.uuid) ? (
              <Icon
                type="check"
                theme="outlined"
                onClick={() =>
                  deleteProblemHandler(testProblems.indexOf(problem.uuid))
                }
              />
            ) : (
              <Icon
                type="plus"
                theme="outlined"
                onClick={() => addProblemToTest(problem)}
              />
            )
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="problems-list">
      <div className="problems-row header-row">
        <div className="problems-row-item column-title">Code</div>
        <div className="problems-row-item column-title">Title</div>
        {showLevel ? (
          <div className="problems-row-item column-title">Difficulty</div>
        ) : null}
        <div className="problems-row-item column-title">Used Count</div>
        <div className="problems-row-item column-title">Actions</div>
      </div>
      {!problems.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        problems.map((problem, index) => prepareProblemsRow(problem, index))
      )}
    </div>
  );
};

ProblemsList.propTypes = {
  problems: PropTypes.array.isRequired,
  showDrawer: PropTypes.func.isRequired,
  showLevel: PropTypes.bool,
  editProblem: PropTypes.bool,
  deleteProblemHandler: PropTypes.func,
  addProblemToTest: PropTypes.func,
  showAddIcon: PropTypes.bool,
  onSelect: PropTypes.func,
  testProblems: PropTypes.array,
  problemLevels: PropTypes.arrayOf(PropTypes.any).isRequired
};

ProblemsList.defaultProps = {
  showLevel: true,
  editProblem: true,
  deleteProblemHandler: null,
  addProblemToTest: null,
  showAddIcon: false,
  onSelect: null,
  testProblems: []
};

export default ProblemsList;
