import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider, message, Tabs } from 'antd';

import './Details.less';
import Loader from '../../../components/Loader/Loader';
import api from '../../../api';
import BasicInfo from './BasicInfo/BasicInfoHOC';
import Work from './Work/Work';
import Education from './Education/Education';
import Portfolio from './Portfolio/Portfolio';
import SocialAccount from './SocialAccount/SocialAccount';
import Certification from './Certification/Certification';
import Jobs from './Jobs/Jobs';
import Search from '../../../components/Search/SearchHOC';

const Details = ({ data }) => {
  const [state, setState] = useState({
    ninjaDetails: {},
    activeKey: '1',
    loading: false,
    jobStatus: null,
    searchQuery: '',
    jobs: []
  });

  const fetchNinjaDetails = useCallback(uuid => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.ninja
      .get(uuid)
      .then(resp => {
        if (resp) {
          setState(stateObj => ({
            ...stateObj,
            ninjaDetails: resp,
            loading: false
          }));
        } else {
          setState(stateObj => ({ ...stateObj, loading: false }));
        }
      })
      .catch(error => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(error.message);
      });
  }, []);

  const fetchJobs = useCallback(
    (uuid, updatedState) => {
      if (!state.loading) {
        setState(stateObj => ({ ...stateObj, loading: true }));
        api.ninja
          .getJobs(uuid, {
            q: updatedState.searchQuery,
            status: updatedState.jobStatus
          })
          .then(resp => {
            if (resp && resp.items.length) {
              setState(stateObj => ({
                ...stateObj,
                jobs: [...resp.items],
                loading: false
              }));
            } else if (!resp.items.length) {
              setState(stateObj => ({
                ...stateObj,
                jobs: [],
                loading: false
              }));
            } else {
              setState(stateObj => ({
                ...stateObj,
                loading: false
              }));
            }
          })
          .catch(err => {
            setState(stateObj => ({ ...stateObj, loading: false }));
            message.error(err.message);
          });
      }
    }, // eslint-disable-next-line
    [state.page]
  );

  const changeTab = activeKey =>
    setState(stateObj => ({ ...stateObj, activeKey }));

  const handleChange = value => {
    const stateObj = { ...state };
    stateObj.jobStatus = value;
    stateObj.jobs = [];
    setState({ ...stateObj });
    fetchJobs(data.uuid, stateObj);
  };

  const searchCalled = query => {
    const stateObj = { ...state };
    stateObj.searchQuery = query;
    stateObj.loading = true;
    stateObj.jobs = [];
    setState({ ...stateObj });
    fetchJobs(data.uuid, stateObj);
  };

  const tabBarExtraContent = () => {
    return (
      <Search
        name="Job"
        handleChange={handleChange}
        searchCalled={searchCalled}
      />
    );
  };

  useEffect(() => {
    if (data.work) {
      setState(stateObj => ({
        ...stateObj,
        ninjaDetails: data,
        loading: false
      }));
    } else if (data.uuid) {
      fetchNinjaDetails(data.uuid);
      fetchJobs(data.uuid, state);
    }
    return () => setState(stateObj => ({ ...stateObj, activeKey: '1' })); // eslint-disable-next-line
  }, [data, fetchNinjaDetails]);

  return (
    <div className="ninja-details">
      {state.loading && <Loader />}
      <Tabs
        activeKey={state.activeKey}
        onChange={changeTab}
        tabBarExtraContent={state.activeKey === '2' && tabBarExtraContent()}
      >
        <Tabs.TabPane tab="Basic Info" key="1">
          <BasicInfo
            title={data.title}
            email={state.ninjaDetails.user && state.ninjaDetails.user.email}
            phone={state.ninjaDetails && state.ninjaDetails.phone}
            experience={data.experience_started}
            totalViews={data.total_views}
            commitment={data.commitment}
            salaryInterval={data.salary_interval}
            salary={data.salary}
            currency={data.currency}
            location={data?.user?.location?.long_name}
            skills={data.skills}
          />
          {state.ninjaDetails && state.ninjaDetails.work && (
            <Work ninjaWork={state.ninjaDetails.work} />
          )}
          <Divider className="divider" />
          {state.ninjaDetails && state.ninjaDetails.education && (
            <Education ninjaEducation={state.ninjaDetails.education} />
          )}
          <Divider className="divider" />
          {state.ninjaDetails && state.ninjaDetails.portfolio && (
            <Portfolio ninjaPortfolio={state.ninjaDetails.portfolio} />
          )}
          <Divider className="divider" />
          {state.ninjaDetails && state.ninjaDetails.social && (
            <SocialAccount ninjaSocialAccount={state.ninjaDetails.social} />
          )}
          <Divider className="divider" />
          {state.ninjaDetails && state.ninjaDetails.certification && (
            <Certification
              ninjaCertification={state.ninjaDetails.certification}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={`Jobs Applied (${state?.jobs ? state.jobs.length : 0})`}
          key="2"
        >
          {state.ninjaDetails && state.jobs && <Jobs ninjaJobs={state.jobs} />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

Details.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Details;
