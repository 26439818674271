import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message } from 'antd';

import './AddCategoryDialog.less';
import api from '../../api';
import { validate, FormItemWithError } from '../../shared/validations';
import Loader from '../Loader/Loader';

const requiredFields = ['name'];

const AddCategoryDialog = ({
  isVisible,
  onSubmit,
  onCancel,
  editCategoryData
}) => {
  const initialState = {
    name: ''
  };
  const [state, setState] = useState({
    category: editCategoryData && initialState,
    error: {},
    loading: false
  });

  const resetState = () => {
    const stateObj = { ...state };
    stateObj.category = initialState;
    stateObj.error = {};
    stateObj.loading = false;
    setState(stateObj);
  };

  const addUpdateCategory = data => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    const call = data.uuid
      ? api.categories.update(data)
      : api.categories.add(data);
    call
      .then(resp => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        if (resp && resp.uuid) {
          resetState();
          onSubmit(resp);
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  };

  const submit = () => {
    const valid = validate(state.category, requiredFields);
    // eslint-disable-next-line no-console
    !valid.isValid
      ? setState(stateObj => ({ ...stateObj, error: valid.errors }))
      : addUpdateCategory(state.category);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  const handleChange = (field, value) => {
    const stateObject = { ...state };
    stateObject.category[field] = value;
    setState(stateObject);
  };

  useEffect(() => {
    if (editCategoryData && editCategoryData.uuid) {
      setState(stateObj => ({ ...stateObj, category: editCategoryData }));
    }
    // eslint-disable-next-line
  }, [editCategoryData]);

  return (
    <Modal
      title={
        state.category && state.category.uuid
          ? 'Edit Category'
          : 'Add New Category'
      }
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={() => cancel()}
      onOk={submit}
      className="add-category-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Name" required>
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Name"
                value={state.category && state.category.name}
                onChange={e => handleChange('name', e.target.value)}
              />,
              'name',
              state.error
            )}
          </Form.Item>
          <Form.Item label="Slug">
            <Input
              size="large"
              placeholder="Slug"
              value={state.category && state.category.slug}
              onChange={e => handleChange('slug', e.target.value)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddCategoryDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editCategoryData: PropTypes.objectOf(PropTypes.any)
};

AddCategoryDialog.defaultProps = {
  editCategoryData: null
};

export default AddCategoryDialog;
