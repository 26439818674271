import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Select } from 'antd';
import api from '../../api';

const { Option } = Select;

const SearchByCategories = ({ handleCategoriesChange, selectedCategories }) => {
  const [categories, setCategories] = useState([...selectedCategories]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const fetchCategories = (query = '') => {
    api.ninja
      .fetchCategories(query)
      .then(resp => {
        if (resp && resp.items.length) {
          setCategories(resp.items);
          setLoadingCategories(false);
        }
      })
      .catch(err => {
        message.error(err.message);
        setLoadingCategories(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className="categories">
      <Select
        mode="multiple"
        placeholder="Search by Categories"
        className="categories"
        loading={loadingCategories}
        value={selectedCategories.map(
          selectedCategory => selectedCategory.name
        )}
        size="large"
        style={{ width: '100%', padding: '0.5rem' }}
        onChange={(values, options) => handleCategoriesChange(options)}
        onSearch={query => fetchCategories(query)}
      >
        {categories &&
          categories.length &&
          categories.map(category => (
            <Option key={category.uuid} value={category.name}>
              {category.name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

SearchByCategories.propTypes = {
  handleCategoriesChange: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default SearchByCategories;
