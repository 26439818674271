import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Icon } from 'antd';

import ApplicantDetails from '../ApplicantDetails/ApplicantDetailsHOC';
import './ApplicantDetailsDialog.less';

const ApplicantDetailsDialog = ({
  isVisible,
  data,
  onCancel,
  jobMaxSalary
}) => {
  const history = useHistory();
  return (
    <Modal
      title={data.title}
      centered
      visible={isVisible}
      width={720}
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      closeIcon={<Icon type="close" theme="outlined" onClick={onCancel} />}
      className="applicant-details-dialog"
    >
      <div className="ninja-details-Btn">
        <Button
          type="link"
          onClick={() => history.push(`/ninjas/${data.uuid}`)}
        >
          View Ninja Details
        </Button>
      </div>
      <ApplicantDetails data={data} jobMaxSalary={jobMaxSalary} />
    </Modal>
  );
};

ApplicantDetailsDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  jobMaxSalary: PropTypes.number
};

ApplicantDetailsDialog.defaultProps = {
  data: {},
  jobMaxSalary: 0
};

export default ApplicantDetailsDialog;
