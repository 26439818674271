import http from './http';

const RESOURCE_URL = '/test';

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const update = data => {
  return new Promise((resolve, reject) => {
    http
      .put(`${RESOURCE_URL}/${data.uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getList = (
  query = '',
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT
) => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      p: page,
      l: limit
    };
    http
      .get(RESOURCE_URL, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const get = uuid => {
  return new Promise((resolve, reject) => {
    const params = {
      with: 'problems'
    };
    http
      .get(`${RESOURCE_URL}/${uuid}`, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const softDelete = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`${RESOURCE_URL}/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const deleteTestCase = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`/test_case/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  add,
  update,
  getList,
  get,
  softDelete,
  deleteTestCase
};
