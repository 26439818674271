import actionTypes from './actionTypes';

export const login = user => ({
  type: actionTypes.login,
  user
});

export const logout = () => ({
  type: actionTypes.logout
});
