import http from './http';

const RESOURCE_URL = '/blog/category';

const get = uuid => {
  return new Promise((resolve, reject) => {
    const params = {};
    http
      .get(`/blog/category/${uuid}`, params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const getList = (
  query = '',
  page = 1,
  limit = process.env.REACT_APP_PAGE_LIMIT
) => {
  return new Promise((resolve, reject) => {
    const params = {
      q: query,
      p: page,
      l: limit,
      o: 'created_at,DESC'
    };
    http
      .get('/blog/category', params)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const update = (uuid, data) => {
  return new Promise((resolve, reject) => {
    http
      .put(`/blog/category/${uuid}`, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const remove = uuid => {
  return new Promise((resolve, reject) => {
    http
      .delete(`/blog/category/${uuid}`)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

const add = data => {
  return new Promise((resolve, reject) => {
    http
      .post(RESOURCE_URL, data)
      .then(resp => resolve(resp))
      .catch(error => reject(error));
  });
};

export default {
  get,
  getList,
  update,
  remove,
  add
};
