/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */

import './ExtensiveSearch.less';

import { Button, Col, Input, Modal, Row, Select, Slider } from 'antd';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import SearchByCategories from '../SearchByCategories/SearchByCategories';
import { currencyFormatter } from '../../shared/utility';

const defaultFilters = {
  email: '',
  name: '',
  categories: [],
  experience_started: [0, 10],
  status: null,
  q: null,
  commitment: null,
  experience: null,
  min_salary: 0,
  max_salary: 10000
};

const experienceMarks = {
  0: {
    style: {
      color: '#757575'
    },
    label: <strong>0</strong>
  },
  10: {
    style: {
      color: '#757575'
    },
    label: <strong>10+</strong>
  }
};

const salaryMarks = {
  0: {
    style: {
      color: '#757575'
    },
    label: <strong>0</strong>
  },
  10000: {
    style: {
      color: '#757575'
    },
    label: <strong>10K+</strong>
  }
};

const Search = ({
  ninjasTitles,
  ninjasStatus,
  ninjasCommitment,
  isVisible,
  onCancel,
  handleApplyFilters,
  ninjaFilters,
  branchesExperience,
  branchesCommitment,
  branchesStatus,
  searchNinjas
}) => {
  const experiencesObj = {};
  branchesExperience.map(item => (experiencesObj[item.value] = item.name));

  const branchesName = [];
  branchesCommitment.forEach((item, i) =>
    branchesName.push({ value: i, name: `${item.name} Software Engineer` })
  );

  const ninjaCommitmentObj = {};
  ninjasCommitment.map(item => (ninjaCommitmentObj[item.value] = item.name));

  const branchCommitmentObj = {};
  branchesCommitment.map(item => (branchCommitmentObj[item.value] = item.name));

  const [state, setState] = useState({
    selectedCategories: [],
    modalFilters: Object.keys(ninjaFilters).length
      ? ninjaFilters
      : defaultFilters
  });

  useEffect(() => {
    if (!ninjaFilters.categories)
      setState(stateObj => ({ ...stateObj, selectedCategories: [] }));
  }, [ninjaFilters.categories]);

  const handleCategoriesChange = options => {
    const modalFilters = { ...state.modalFilters };
    modalFilters.categories = options.map(option => option.key);
    const selectedCategories = options.map(option => ({
      uuid: option.key,
      name: option.props.value
    }));
    setState({ selectedCategories, modalFilters });
  };

  const handleChange = (name, value) => {
    const filters = { ...state.modalFilters };

    if (name === 'salary') {
      filters.min_salary = value[0];
      filters.max_salary = value[1];
    } else filters[name] = value;

    setState(stateObj => ({
      ...stateObj,
      modalFilters: filters
    }));
  };

  const closeModal = () => {
    let categories = [...state.selectedCategories];
    if (
      !ninjaFilters.categories ||
      (ninjaFilters.categories && !ninjaFilters.categories.length)
    ) {
      categories = [];
    }
    setState(stateObj => ({
      ...stateObj,
      selectedCategories: [...categories],
      modalFilters: Object.keys(ninjaFilters).length
        ? ninjaFilters
        : defaultFilters
    }));
  };

  const handleApply = () => {
    const modalFilters = { ...state.modalFilters };
    if (searchNinjas) {
      delete modalFilters.min_salary;
      delete modalFilters.max_salary;
      delete modalFilters.experience;
    } else {
      delete modalFilters.experience_started;
    }
    handleApplyFilters(modalFilters);
  };

  return (
    <Modal
      title={`Search ${searchNinjas ? 'Ninjas' : 'Branches'}`}
      centered
      visible={isVisible}
      destroyOnClose
      onCancel={onCancel}
      className="ninja-filter"
      afterClose={closeModal}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="apply" type="primary" onClick={() => handleApply()}>
          Apply
        </Button>
      ]}
    >
      {searchNinjas && (
        <div className="search-box">
          <Input
            size="large"
            name="searchQuery"
            value={state?.modalFilters?.email}
            placeholder="Search Ninja by Email"
            onChange={e => handleChange('email', e.target.value)}
          />
        </div>
      )}
      {searchNinjas && (
        <div className="search-box">
          <Input
            size="large"
            name="searchQuery"
            value={state?.modalFilters?.name}
            placeholder="Search Ninja by name"
            onChange={e => handleChange('name', e.target.value)}
          />
        </div>
      )}
      {!searchNinjas && (
        <FilterDropdown
          name="brach name"
          filterName="q"
          filters={branchesName}
          handleChange={handleChange}
          defaultValue={state?.modalFilters?.q}
        />
      )}
      <SearchByCategories
        handleCategoriesChange={handleCategoriesChange}
        selectedCategories={state?.selectedCategories}
      />
      {searchNinjas ? (
        <RangeFilter
          rangeValues={state?.modalFilters?.experience_started}
          handleChange={handleChange}
          searchNinjas={searchNinjas}
        />
      ) : (
        <RangeFilter
          rangeValues={[
            state?.modalFilters?.min_salary,
            state?.modalFilters?.max_salary
          ]}
          handleChange={handleChange}
          searchNinjas={searchNinjas}
        />
      )}
      {!searchNinjas && (
        <FilterDropdown
          filterName="experience"
          filters={experiencesObj}
          handleChange={handleChange}
          defaultValue={state?.modalFilters?.experience}
        />
      )}
      <FilterDropdown
        filterName="status"
        filters={searchNinjas ? ninjasStatus : branchesStatus}
        handleChange={handleChange}
        defaultValue={
          state?.modalFilters?.status
            ? searchNinjas
              ? ninjasStatus[state?.modalFilters?.status].name
              : branchesStatus[state?.modalFilters?.status].name
            : ''
        }
      />
      {searchNinjas && (
        <FilterDropdown
          filterName="title"
          filters={ninjasTitles}
          handleChange={handleChange}
          defaultValue={state?.modalFilters?.title}
        />
      )}
      <FilterDropdown
        filterName="commitment"
        filters={searchNinjas ? ninjaCommitmentObj : branchCommitmentObj}
        handleChange={handleChange}
        defaultValue={state?.modalFilters?.commitment}
      />
    </Modal>
  );
};

const FilterDropdown = ({
  name,
  filterName,
  filters,
  handleChange,
  defaultValue
}) => {
  const getValue = key => {
    if (filterName === 'name' || filterName === 'q') return filters[key].name;
    return key;
  };

  return (
    <div className="filter">
      <Row>
        <Col span={6}>
          <h4 className="capitalize">{name || filterName}</h4>
        </Col>
        <Col span={18}>
          <Select
            defaultValue={defaultValue || 'Any'}
            size="large"
            onChange={value => handleChange(filterName, value)}
          >
            <Select.Option value={null}>Any</Select.Option>
            {Object.keys(filters).map(key => (
              <Select.Option value={getValue(key)} key={key}>
                {filters[key].name ? filters[key].name : filters[key]}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
}

const RangeFilter = ({ rangeValues, handleChange, searchNinjas }) => (
  <div className="range">
    <div className="range-title">
      <h4>{searchNinjas ? 'Experience:' : 'Salary:'}</h4>
      {searchNinjas ? (
        <h4>{`${rangeValues[0]}-${rangeValues[1]}${
          rangeValues[1] === 10 ? '+' : ''
        } years`}</h4>
      ) : (
        <h4>{`${currencyFormatter(rangeValues[0])}-${currencyFormatter(
          rangeValues[1]
        )}${rangeValues[1] === 10000 ? '+' : ''} /hr`}</h4>
      )}
    </div>
    <Slider
      min={0}
      max={searchNinjas ? 10 : 10000}
      range
      onChange={value =>
        handleChange(searchNinjas ? 'experience_started' : 'salary', value)
      }
      marks={searchNinjas ? experienceMarks : salaryMarks}
      value={rangeValues}
    />
  </div>
);

Search.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  ninjasCommitment: PropTypes.arrayOf(PropTypes.any).isRequired,
  ninjasStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  ninjasTitles: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  ninjaFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  branchesExperience: PropTypes.arrayOf(PropTypes.any).isRequired,
  branchesCommitment: PropTypes.arrayOf(PropTypes.any).isRequired,
  branchesStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchNinjas: PropTypes.bool.isRequired
};

FilterDropdown.propTypes = {
  filters: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any)
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any
};

FilterDropdown.defaultProps = {
  defaultValue: null
};

RangeFilter.propTypes = {
  rangeValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  searchNinjas: PropTypes.bool.isRequired
};
export default Search;
