import React from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Icon } from 'antd';
import {
  currencyFilter,
  copyToClipboard,
  openMail
} from '../../../../shared/utility';
import SkillSet from '../../../../components/CategorySet/CategorySet';

const BasicInfo = ({
  title,
  email,
  phone,
  experience,
  location,
  totalViews,
  commitment,
  skills,
  salaryInterval,
  salary,
  currency,
  ninjaCommitment
}) => {
  const getNinjaCommitment = () => {
    const commitmentMeta = ninjaCommitment.find(i => i.value === commitment);
    return commitmentMeta ? commitmentMeta.name : 'N/A';
  };

  return (
    <div className="m-b">
      <div className="meta m-b">
        <Descriptions className="p-t">
          <Descriptions.Item label="Title">{title}</Descriptions.Item>
          <Descriptions.Item label="Email">
            <Button
              type="link"
              className="link-button"
              style={{ paddingRight: 0 }}
              onClick={e => openMail(e, email)}
            >
              {email || 'N/A'}
            </Button>
            <Icon
              type="copy"
              theme="outlined"
              onClick={() => copyToClipboard(email)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Phone">{phone || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label="Total Experience">
            {experience.years || 0} {experience.years === 1 ? 'yr' : 'yrs'}
          </Descriptions.Item>
          <Descriptions.Item label="Views">{totalViews}</Descriptions.Item>
          <Descriptions.Item label="Commitment">
            {getNinjaCommitment()}
          </Descriptions.Item>
          <Descriptions.Item label="Salary Interval">
            {salaryInterval}
          </Descriptions.Item>
          <Descriptions.Item label="Expected Salary">
            {currency} {currencyFilter(salary)}
          </Descriptions.Item>
          <Descriptions.Item label="Location">{location}</Descriptions.Item>
          <Descriptions.Item label="Skills">
            {skills && <SkillSet skills={skills} />}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

BasicInfo.propTypes = {
  ninjaCommitment: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  experience: PropTypes.objectOf(PropTypes.any),
  totalViews: PropTypes.number.isRequired,
  commitment: PropTypes.number.isRequired,
  salaryInterval: PropTypes.string.isRequired,
  salary: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.any).isRequired
};

BasicInfo.defaultProps = {
  experience: {},
  email: 'N/A',
  phone: 'N/A'
};

export default BasicInfo;
