import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row, Select } from 'antd';

import './Search.less';

const Search = ({
  handleChange,
  branchesStatus,
  name,
  // searchCalled,
  searchChanged
}) => {
  return (
    <Row gutter={8}>
      <Col span={name === 'Business' ? 24 : 16}>
        <div className="search-box">
          <Input.Search
            size="large"
            name="searchQuery"
            placeholder={`Search ${name} by Name`}
            // onSearch={value => searchCalled(value)}
            onChange={e => searchChanged(e.target.value)}
            allowClear={true}
          />
        </div>
      </Col>
      {name === 'Branch' && (
        <Col span={8}>
          <div className="branch-filter">
            <Select
              defaultValue="All Branches"
              size="large"
              onChange={handleChange}
            >
              <Select.Option value={null}>All Branches</Select.Option>
              {branchesStatus.map(item => (
                <Select.Option value={item.value} key={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      )}
    </Row>
  );
};

Search.propTypes = {
  handleChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  branchesStatus: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string.isRequired,
  // searchCalled: PropTypes.func.isRequired,
  searchChanged: PropTypes.func.isRequired
};

Search.defaultProps = {
  handleChange: () => {},
  branchesStatus: {}
};

export default Search;
