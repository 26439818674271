import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message } from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const AddMenuCategoryDialog = ({
  //   businessDetails,
  branchUuid,
  isVisible,
  onSubmit,
  onCancel
}) => {
  //   const initialState = {
  //     name: businessDetails.name,
  //     website: businessDetails.website
  //   };
  const [error, setError] = useState({});
  const requiredFields = ['name'];
  const [state, setState] = useState({
    // business: initialState,
    menuCategory: {
      name: '',
      category_desc: ''
    },
    loading: false
  });

  // useEffect(() => {
  //   if (categoryData?.uuid) {
  //     console.log('EDIT CATEGORY: ', categoryData.uuid);
  //     setState({
  //       ...state,
  //       menuCategory: {
  //         name: categoryData.name,
  //         category_desc: categoryData.category_desc,
  //         uuid: categoryData.uuid
  //       }
  //     });
  //   }
  // }, [categoryData]);

  const resetState = () =>
    setState(stateObj => ({
      ...stateObj,
      //   business: initialState,
      menuCategory: {},
      loading: false
    }));

  const addMenuCategory = useCallback(
    menuCategory => {
      setState(stateObj => ({ ...stateObj, loading: true }));
      setError({});
      api.menuCategory
        .add({ ...menuCategory, branch_uuid: branchUuid })
        .then(resp => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          if (resp && resp.uuid) {
            onSubmit(resp);
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    },
    [onSubmit]
  );

  const submit = menuCategory => {
    const valid = validate(state.menuCategory, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    // setState({
    //   ...state,
    //   menuCategory: {
    //     ...state.menuCategory,
    //     branch_uuid: branchUuid
    //   }
    // });
    addMenuCategory(menuCategory);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  //   useEffect(() => {
  //     setState(stateObj => ({
  //       ...stateObj,
  //       business: {
  //         name: businessDetails.name,
  //         website: businessDetails.website
  //       }
  //     }));
  //   }, [businessDetails]);

  return (
    <Modal
      title="Add Menu Category"
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(state.menuCategory)}
      className="add-menu-category-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Category Name" name="name" required>
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Category Name"
                value={state.menuCategory.name}
                onChange={e =>
                  setState({
                    ...state,
                    menuCategory: {
                      ...state.menuCategory,
                      name: e.target.value
                    }
                  })
                }
              />,
              'name',
              error
            )}
          </Form.Item>
          <Form.Item label="Category Description" name="description">
            <Input.TextArea
              rows={5}
              size="large"
              placeholder="Category Description"
              value={state.menuCategory.category_desc}
              onChange={e =>
                setState({
                  ...state,
                  menuCategory: {
                    ...state.menuCategory,
                    category_desc: e.target.value
                  }
                })
              }
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddMenuCategoryDialog.propTypes = {
  //   businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  branchUuid: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddMenuCategoryDialog;
