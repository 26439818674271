/* eslint-disable object-shorthand */
import { message } from 'antd';
import axios from 'axios';
import store from '../store/configStore';
import { logout } from '../store/user/userAction';

const http = {
  base_url: process.env.REACT_APP_HIRENINJA_API_HOST,

  setDefaultHeader: function(name, value) {
    axios.defaults.headers.common[name] = value;
  },

  getList: function(endpoint, params) {
    const url = `${this.base_url}${endpoint}`;
    const config = {};
    config.params = params;
    return axios.get(url, config).then(resp => resp.data);
  },

  get: function(endpoint, params, isFullURL = false) {
    let url = `${this.base_url}${endpoint}`;
    if (isFullURL) {
      url = endpoint;
    }
    const config = {};
    config.params = params;

    return axios.get(url, config).then(resp => resp.data);
  },

  post: function(endpoint, data, queryParams) {
    const url = `${this.base_url}${endpoint}`;
    const config = {};
    if (queryParams) {
      config.params = queryParams;
    }
    return axios.post(url, data, config).then(resp => resp.data);
  },

  put: function(endpoint, data) {
    const url = `${this.base_url}${endpoint}`;
    const config = {};
    return axios.put(url, data, config).then(resp => resp.data);
  },

  delete: function(endpoint, params) {
    const url = `${this.base_url}${endpoint}`;
    const config = {};
    config.params = params;
    return axios.delete(url, config).then(resp => resp.data);
  }
};

axios.interceptors.response.use(
  response => response,
  err => {
    let errorObj = {};
    if (!err.response || (err.response && !err.response.data.message)) {
      errorObj.status = err.response.status;
    } else {
      errorObj.status = err.response.status;
      errorObj.message = err.response.data.message;
    }
    if (err.response && err.response.status === 401) {
      if (
        err.config.url.includes('auth/register') ||
        err.config.url.includes('auth/login')
      ) {
        errorObj.status = err.response.status;
        errorObj = { message: err.response.data.message };
      } else if (
        err.config.headers &&
        err.config.headers.hasOwnProperty('Authorization')
      ) {
        errorObj.status = err.response.status;
        errorObj = { message: 'Your session is expired. Please login again.' };
        store.dispatch(logout());
      }
    }

    if (err.response.status === 422) {
      Object.values(err.response.data).forEach(errorItem => [
        errorItem.forEach(item => message.error(item))
      ]);
      return Promise.reject('');
    }
    return Promise.reject(errorObj);
  }
);

export default http;
