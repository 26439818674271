import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Divider, message } from 'antd';

import './Businesses.less';
import { useSelector } from 'react-redux';
import List from './List/List';
import Details from './Details/DetailsHOC';
import Search from '../../components/Search/Search';
import api from '../../api';

const Businesses = () => {
  const { businessId } = useParams();
  const history = useHistory();
  const businessUpdated = useSelector(state => state.config.businessUpdated);
  const [state, setState] = useState({
    businesses: [],
    page: 1,
    pageLoaded: false,
    loading: false,
    businessesLoaded: false,
    pageEnd: false
  });

  const setPage = pageNumber => setState({ ...state, page: pageNumber });

  const fetchBusinesses = useCallback(() => {
    if (!state.loading) {
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.business
        .getList('', state.page, process.env.REACT_APP_PAGE_LIMIT)
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              businesses: [...state.businesses, ...resp.items],
              pageLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: false }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    } // eslint-disable-next-line
  }, [state.page]);

  const searchCalled = useCallback(value => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.business
      .getList(value)
      .then(resp => {
        resp && resp.items
          ? setState(stateObj => ({
              ...stateObj,
              businesses: [...resp.items],
              loading: false
            }))
          : setState(stateObj => ({ ...stateObj, loading: false }));
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  }, []);

  const searchChanged = useCallback(value => {
    // console.log('searchChnaged Value: ', value);
  }, []);

  const deleteBusiness = uuid => {
    api.business.remove(uuid);
    const updatedBusinesses = state.businesses;
    const targetIndex = updatedBusinesses.findIndex(
      business => business.uuid === uuid
    );
    updatedBusinesses.splice(targetIndex, 1);
    setState(stateObj => ({ ...stateObj, businesses: updatedBusinesses }));
    history.push(
      `/businesses/${updatedBusinesses.length ? updatedBusinesses[0].uuid : ''}`
    );
    message.success('Business deleted successfully!');
  };

  useEffect(() => {
    if (!state.businesses.length) {
      history.push('/businesses');
      setState(stateObj => ({
        ...stateObj,
        pageEnd: false,
        businessesLoaded: false
      }));
    }
    if (state.businesses.length && !state.businessesLoaded) {
      setState(stateObj => ({ ...stateObj, businessesLoaded: true }));
      history.push(`/businesses/${state.businesses[0].uuid}`);
    } // eslint-disable-next-line
  }, [history, state.businesses]);

  useEffect(() => {
    if (!state.pageEnd) {
      fetchBusinesses();
    } // eslint-disable-next-line
  }, [state.pageEnd, fetchBusinesses, businessUpdated]);

  return (
    <div className="businesses-page">
      <Row>
        <Col className="businesses-list-section" span={8}>
          <Search
            searchChanged={searchCalled}
            // searchCalled={searchCalled}
            name="Business"
          />
          <Divider />
          <List
            businesses={state.businesses}
            loading={state.loading}
            pageLoaded={state.pageLoaded}
            page={state.page}
            setPage={setPage}
          />
        </Col>
        <Col span={16}>
          {businessId && <Details onDelete={deleteBusiness} />}
        </Col>
      </Row>
    </div>
  );
};

export default Businesses;
