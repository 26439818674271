import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Empty, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '../../../components/Loader/Loader';
import BranchCard from '../../../components/BranchCard/BranchCardCardHOC';
import api from '../../../api';

const List = ({
  branches,
  loading,
  pageLoaded,
  page,
  setPage,
  setSelectedBranch,
  setPageEnd
}) => {
  const history = useHistory();
  const { branchId } = useParams();

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setPage(page + 1);
    }
  };

  const openDetails = useCallback(
    branch => {
      setSelectedBranch(branch);
      history.push(`/branches/${branch.uuid}`);
    },
    [history, setSelectedBranch]
  );

  const fetchBranch = uuid => {
    api.branches
      .get(uuid)
      .then(resp => {
        setSelectedBranch(resp);
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (branchId && page === 1 && !branches.length) {
      fetchBranch(branchId);
    } else if (!branches.length) {
      setPageEnd(false);
    } else if (!branchId) {
      openDetails(branches[0]);
    } // eslint-disable-next-line
  }, [branches]);

  const duplicateBranch = uuid => {
    // console.log('duplicate branch', uuid); // eslint-disable-line no-console
    // api.business.remove(uuid);
    // const updatedBusinesses = state.businesses;
    // const targetIndex = updatedBusinesses.findIndex(
    //   business => business.uuid === uuid
    // );
    // updatedBusinesses.splice(targetIndex, 1);
    // setState(stateObj => ({ ...stateObj, businesses: updatedBusinesses }));
    // history.push(
    //   `/businesses/${updatedBusinesses.length ? updatedBusinesses[0].uuid : ''}`
    // );
    // message.success('Branch duplicated successfully!');
  };

  return (
    <div>
      {!branches.length && !loading ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div
          className="branches-list"
          onScroll={handleScroll}
          style={!pageLoaded ? { position: 'relative' } : null}
        >
          {loading && <Loader infiniteLoader={pageLoaded} />}
          {branches.map(branch => (
            <BranchCard
              key={branch.uuid}
              data={{ ...branch }}
              openDetails={openDetails}
              col={3}
              onDuplicateBranch={duplicateBranch}
            />
          ))}
        </div>
      )}
    </div>
  );
};

List.propTypes = {
  branches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number,
  pageLoaded: PropTypes.bool,
  setPage: PropTypes.func.isRequired,
  setSelectedBranch: PropTypes.func.isRequired,
  setPageEnd: PropTypes.func.isRequired
};

List.defaultProps = {
  branches: [],
  page: 1,
  pageLoaded: false
};

export default List;
