import { connect } from 'react-redux';
import BranchCard from './BranchCard';

const mapStateToProps = state => ({
  branchStatus:
    state.config.meta.branch && state.config.meta.branch.status
      ? state.config.meta.branch.status
      : [],
  branchCommitment:
    state.config.meta.branch && state.config.meta.branch.commitment
      ? state.config.meta.branch.commitment
      : []
});

export default connect(mapStateToProps)(BranchCard);
