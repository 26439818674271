import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Tabs, message } from 'antd';

import './AddEditBusiness.less';
import Basic from './Basic/BasicHOC';
import api from '../../../api';
import Loader from '../../../components/Loader/Loader';

const { TabPane } = Tabs;

const AddEditBusiness = ({
  isVisible,
  onCancel,
  onSubmit,
  targetBusiness,
  editBusiness
}) => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [business, setBusiness] = useState({});

  const changeTab = activeValue => {
    setActiveKey(activeValue);
  };

  const updateBusiness = (businessObj, editing = false) => {
    setBusiness({ ...businessObj });
    if (!editing) {
      changeTab('2');
    }
  };

  const onDrawerClose = () => {
    onCancel();
    setBusiness({});
    changeTab('1');
  };

  const addUpdateBusiness = data => {
    setLoading(true);
    const call = data.uuid ? api.business.update(data) : api.business.add(data);
    call
      .then(resp => {
        setLoading(false);
        if (resp && resp.uuid) {
          data.uuid
            ? message.success('Business Updated!')
            : message.success('Business Added!');
          onSubmit(resp);
          onDrawerClose();
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const submit = data => {
    // console.log('business submit: ', data);
    const businessObj = { ...data };
    // eslint-disable-next-line no-console
    // businessObj.inputs = businessInputs;
    // businessObj.test_cases = businessTestCases;
    addUpdateBusiness(businessObj);
  };

  useEffect(() => {
    if (isVisible && targetBusiness && targetBusiness.uuid) {
      // eslint-disable-next-line no-console
      setBusiness(targetBusiness);
      console.log('targetBusiness: ', targetBusiness);
    }
  }, [isVisible, targetBusiness]);

  return (
    <div>
      <Drawer
        title={editBusiness ? 'Edit Business' : 'Add Business'}
        placement="right"
        width="45%"
        className="add-edit-business"
        maskClosable={false}
        onClose={onDrawerClose}
        visible={isVisible}
        getContainer={false}
        destroyOnClose={true}
      >
        {loading && <Loader />}
        <Tabs activeKey={activeKey} onChange={changeTab}>
          <TabPane tab="Basic" key="1">
            <Basic targetBusiness={targetBusiness} onSubmit={submit} />
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

AddEditBusiness.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  targetBusiness: PropTypes.objectOf(PropTypes.any),
  editBusiness: PropTypes.bool.isRequired
};

AddEditBusiness.defaultProps = {
  onCancel: () => {},
  targetBusiness: {}
};

export default AddEditBusiness;
