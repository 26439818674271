import { connect } from 'react-redux';
import Problems from './Problems';

const mapStateToProps = state => ({
  problemLevel:
    state.config.meta.problem && state.config.meta.problem.level
      ? state.config.meta.problem.level
      : []
});

export default connect(mapStateToProps)(Problems);
