import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Divider, Empty } from 'antd';

const Education = ({ ninjaEducation }) => {
  return (
    <div className="education m-b">
      <div className="t-b m-b">Education:</div>
      {ninjaEducation.length ? (
        ninjaEducation.map(education => (
          <EducationItem education={education} key={education.uuid} />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

const EducationItem = ({ education }) => (
  <div className="education-item">
    <Descriptions>
      <Descriptions.Item label="University">
        {education.university}
      </Descriptions.Item>
      <Descriptions.Item label="Degree">{education.degree}</Descriptions.Item>
      <Descriptions.Item label="Field">{education.field}</Descriptions.Item>
      <Descriptions.Item label="Graduated at">
        {education.end_date}
      </Descriptions.Item>
      <Descriptions.Item label="Location">
      { education.location && education.location.long_name}
      </Descriptions.Item>
    </Descriptions>
    <Divider className="small divider" />
  </div>
);

Education.propTypes = {
  ninjaEducation: PropTypes.arrayOf(PropTypes.any).isRequired
};

EducationItem.propTypes = {
  education: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Education;
