import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import JobCard from '../../../../components/BranchCard/BranchCard';
import JobDetailsDrawer from '../../../../components/BranchDetailsDrawer/BranchDetailsDrawer';

const NinjaJob = ({ ninjaJobs }) => {
  const [state, setState] = useState({
    selectedJob: {},
    showJobDetailsDrawer: false
  });

  const openJobDetails = selectedJob =>
    setState({ selectedJob, showJobDetailsDrawer: true });

  const closeJobDetails = () =>
    setState(stateObj => ({ ...stateObj, showJobDetailsDrawer: false }));

  return (
    <div className="m-b">
      {ninjaJobs.length ? (
        ninjaJobs.map(job => (
          <JobCard
            key={job.uuid}
            data={{ ...job }}
            openDetails={openJobDetails}
            col={6}
          />
        ))
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <JobDetailsDrawer
        isVisible={state.showJobDetailsDrawer}
        onCancel={closeJobDetails}
        data={state.selectedJob}
      />
    </div>
  );
};

NinjaJob.propTypes = {
  ninjaJobs: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default NinjaJob;
