import React, { useState, useEffect, useCallback } from 'react';
import { Input, Button, message, Modal, Empty } from 'antd';

import Loader from '../../components/Loader/Loader';
import AddTestDrawer from './AddTestDrawer/AddTestDrawerHOC';
import api from '../../api';
import TestsList from './TestsList/TestsList';

const { confirm } = Modal;
const Tests = () => {
  const [state, setState] = useState({
    loading: false,
    showAddTestDialog: false,
    editTestData: {},
    tests: [],
    page: 1,
    pageEnd: false,
    testsLoaded: false
  });

  const fetchTests = useCallback(() => {
    if (state.loading) return;
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.test
      .getList('', state.page, process.env.REACT_APP_PAGE_LIMIT)
      .then(resp => {
        if (resp && resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            tests: [...stateObj.tests, ...resp.items],
            testsLoaded: true,
            loading: false
          }));
        } else if (resp && !resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            loading: false,
            pageEnd: true
          }));
        } else {
          setState(stateObj => ({ ...stateObj, loading: false }));
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      }); // eslint-disable-next-line
  }, [state.page]);

  const fetchTestWithProblems = useCallback(uuid => {
    if (state.loading) return;
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.test
      .get(uuid)
      .then(resp => {
        if (resp) {
          setState(stateObj => ({
            ...stateObj,
            editTestData: {
              uuid: resp.uuid,
              title: resp.title,
              attempts_limit: resp.attempts_limit,
              total_score: resp.total_score,
              total_attempts: resp.total_attempts,
              time_limit: resp.time_limit,
              problems: resp.problems,
              is_draft: resp.is_draft
            },
            loading: false
          }));
        } else {
          setState(stateObj => ({ ...stateObj, loading: true }));
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      }); // eslint-disable-next-line
  }, []);

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: state.page + 1 }));
    }
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchTests();
    }
  }, [fetchTests, state.page, state.pageEnd]);

  const openAddTestDialog = () => {
    setState(stateObj => ({ ...stateObj, showAddTestDialog: true }));
  };

  const resetEditTestData = () => {
    setState(stateObj => ({ ...stateObj, editTestData: {} }));
  };

  const closeAddTestDialog = () => {
    resetEditTestData();
    setState(stateObj => ({ ...stateObj, showAddTestDialog: false }));
  };

  const updateTestsList = data => {
    if (state.editTestData.uuid) {
      const index = state.tests.findIndex(test => test.uuid === data.uuid);
      const updatedTests = [...state.tests];
      updatedTests[index] = data;
      setState(stateObj => ({
        ...stateObj,
        tests: updatedTests,
        loading: false
      }));
      resetEditTestData();
      message.success('Test edited successfully!');
    } else {
      setState(stateObj => ({
        ...stateObj,
        tests: [data, ...state.tests],
        loading: false
      }));
      message.success('Test added successfully!');
    }
    closeAddTestDialog();
  };

  const editTestHandler = uuid => {
    const testUuid = state.tests.find(test => test.uuid === uuid).uuid;
    fetchTestWithProblems(testUuid);
    setState(stateObj => ({ ...stateObj, showAddTestDialog: true }));
  };

  const deleteTestHandler = (uuid, index) => {
    api.test.softDelete(uuid);
    const updatedTests = [...state.tests];
    updatedTests.splice(index, 1);
    setState(stateObj => ({ ...stateObj, tests: updatedTests }));
    message.success('Test deleted successfully!');
  };

  const showDeleteConfirmDialog = (uuid, index) => {
    confirm({
      title: 'Do you want to Delete Test?',
      content: `The ${state.tests[index].title} test will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTestHandler(uuid, index);
      }
    });
  };

  const searchCalled = useCallback(value => {
    setState(stateObj => ({ ...stateObj, loading: true }));
    api.test
      .getList(value)
      .then(resp => {
        if (resp && resp.items) {
          setState(stateObj => ({
            ...stateObj,
            tests: resp.items,
            loading: false
          }));
        }
      })
      .catch(err => {
        setState(stateObj => ({ ...stateObj, loading: false }));
        message.error(err.message);
      });
  }, []);

  return (
    <div className="tests-page">
      <div className="header">
        <h2>Tests</h2>
        <div className="search-bar">
          <Input.Search
            size="large"
            placeholder="Search tests by title"
            loading={state.loading}
            enterButton
            onSearch={value => searchCalled(value)}
          />
          <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={openAddTestDialog}
          >
            Add Test
          </Button>
        </div>
      </div>
      <div
        className="tests-list-wrapper"
        onScroll={handleScroll}
        style={!state.testsLoaded ? { position: 'relative' } : null}
      >
        {state.loading && <Loader infiniteLoader={state.testsLoaded} />}
        {!state.tests.length && !state.loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <TestsList
            tests={state.tests}
            editTestHandler={editTestHandler}
            deleteTestHandler={showDeleteConfirmDialog}
          />
        )}
      </div>
      <AddTestDrawer
        isVisible={state.showAddTestDialog}
        onSubmit={updateTestsList}
        onCancel={closeAddTestDialog}
        editTestData={state.editTestData}
      />
    </div>
  );
};

export default Tests;
