import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message } from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const EditBusinessDialog = ({
  businessDetails,
  isVisible,
  onSubmit,
  onCancel
}) => {
  const initialState = {
    name: businessDetails.name,
    website: businessDetails.website
  };
  const [error, setError] = useState({});
  const requiredFields = ['name', 'website'];
  const [state, setState] = useState({
    business: initialState,
    loading: false
  });

  const resetState = () =>
    setState(stateObj => ({
      ...stateObj,
      business: initialState,
      loading: false
    }));

  const updateBusinessDetails = useCallback(
    business => {
      setState(stateObj => ({ ...stateObj, loading: true }));
      setError({});
      api.business
        .update(businessDetails.uuid, business)
        .then(resp => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          if (resp && resp.uuid) {
            onSubmit(resp);
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    },
    [businessDetails.uuid, onSubmit]
  );

  const submit = business => {
    const valid = validate(state.business, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    updateBusinessDetails(business);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  useEffect(() => {
    setState(stateObj => ({
      ...stateObj,
      business: {
        name: businessDetails.name,
        website: businessDetails.website
      }
    }));
  }, [businessDetails]);

  return (
    <Modal
      title="Edit Business"
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(state.business)}
      className="edit-business-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Business Name" name="name" required>
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Business Name"
                value={state.business.name}
                onChange={e =>
                  setState({
                    ...state,
                    business: { ...state.business, name: e.target.value }
                  })
                }
              />,
              'name',
              error
            )}
          </Form.Item>
          <Form.Item
            label="Business Website"
            required
            rules={[{ required: true, message: 'Please enter Business name' }]}
          >
            {FormItemWithError(
              <Input
                size="large"
                placeholder="Business Website"
                value={state.business.website}
                onChange={e =>
                  setState({
                    ...state,
                    business: { ...state.business, website: e.target.value }
                  })
                }
              />,
              'website',
              error
            )}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

EditBusinessDialog.propTypes = {
  businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default EditBusinessDialog;
