import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message, InputNumber } from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const AddChildItemDialog = ({
  //   businessDetails,
  itemUuid,
  isVisible,
  onSubmit,
  onCancel
}) => {
  //   const initialState = {
  //     name: businessDetails.name,
  //     website: businessDetails.website
  //   };
  const [error, setError] = useState({});
  const requiredFields = ['item_price'];
  const [state, setState] = useState({
    // business: initialState,
    menuItem: {
      item_price: 0,
      item_serving: null,
      item_size: null
    },
    loading: false
  });

  const resetState = () =>
    setState(stateObj => ({
      ...stateObj,
      //   business: initialState,
      menuItem: {},
      loading: false
    }));

  const addMenuChildItem = useCallback(
    menuItem => {
      setState(stateObj => ({ ...stateObj, loading: true }));
      setError({});
      api.menuItem
        .addChildItem({ ...menuItem, parent_uuid: itemUuid })
        .then(resp => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          if (resp && resp.uuid) {
            onSubmit(resp);
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    },
    [onSubmit]
  );

  const submit = menuItem => {
    const valid = validate(state.menuItem, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    // setState({
    //   ...state,
    //   menuCategory: {
    //     ...state.menuCategory,
    //     branch_uuid: branchUuid
    //   }
    // });
    addMenuChildItem(menuItem);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  //   useEffect(() => {
  //     setState(stateObj => ({
  //       ...stateObj,
  //       business: {
  //         name: businessDetails.name,
  //         website: businessDetails.website
  //       }
  //     }));
  //   }, [businessDetails]);

  return (
    <Modal
      title="Add Child Item"
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(state.menuItem)}
      className="add-menu-item-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Form.Item label="Item Size" name="item_size">
            <Input
              size="large"
              placeholder="Item Size"
              value={state.menuItem.item_size}
              onChange={e =>
                setState({
                  ...state,
                  menuItem: {
                    ...state.menuItem,
                    item_size: e.target.value
                  }
                })
              }
            />
          </Form.Item>
          <Form.Item label="Item Price" name="item_price" required>
            {FormItemWithError(
              <InputNumber
                type="number"
                style={{ width: '25%' }}
                size="large"
                placeholder="Item Price"
                value={state.menuItem.item_price}
                onChange={value =>
                  setState({
                    ...state,
                    menuItem: {
                      ...state.menuItem,
                      item_price: value
                    }
                  })
                }
              />,
              'item_price',
              error
            )}
          </Form.Item>
          <Form.Item label="Item Serving" name="item_serving">
            <InputNumber
              size="large"
              placeholder="Item Serving"
              value={state.menuItem.item_serving}
              type="number"
              style={{ width: '25%' }}
              onChange={value =>
                setState({
                  ...state,
                  menuItem: {
                    ...state.menuItem,
                    item_serving: value
                  }
                })
              }
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddChildItemDialog.propTypes = {
  //   businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemUuid: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddChildItemDialog;
