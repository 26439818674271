import http from './http';
import localStorage from '../shared/localStorage';

const ENDPOINT_LOGIN_USER = '/auth/login';

const getAccessToken = () => {
  let localUser = localStorage.getItem('user');
  localUser = JSON.parse(localUser) || {};
  return localUser.access_token || '';
};

const setAuthHeader = token => {
  if (!token) {
    token = getAccessToken();
  }
  http.setDefaultHeader('Authorization', `Bearer ${token}`);
};

const login = data => {
  return new Promise((resolve, reject) => {
    http
      .post(ENDPOINT_LOGIN_USER, data)
      .then(resp => {
        setAuthHeader(resp.access_token);
        resolve(resp);
      })
      .catch(error => reject(error));
  });
};

export default {
  login,
  setAuthHeader
};
