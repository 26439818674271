import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './AdminSet.less';

const AdminSet = ({ admins, cardAdmins }) => (
  <div className="admins">
    {cardAdmins && admins.length > 2 ? (
      <div>
        {admins[0] && <Tag key={`${admins[0].uuid}`}>{admins[0].email}</Tag>}
        {admins[1] && <Tag key={`${admins[1].uuid}`}>{admins[1].email}</Tag>}
        {admins.length > 2 && (
          <Tag key={`${admins[2].uuid}`}>{`+${admins.length - 2} more`}</Tag>
        )}
      </div>
    ) : (
      admins.map((admin, index) => (
        <Tag key={`${admin.uuid}-${index}`}>{admin.email}</Tag>
      ))
    )}
  </div>
);

AdminSet.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.any).isRequired,
  cardAdmins: PropTypes.bool
};

AdminSet.defaultProps = {
  cardAdmins: false
};

export default AdminSet;
