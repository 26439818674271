import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Icon, Modal } from 'antd';
import api from '../../../../api';

import AddTestCaseDialog from './AddTestCaseDialog/AddTestCaseDialog';

const { confirm } = Modal;

const TestCases = ({
  onSubmit,
  problemInputs,
  problemOutput,
  targetProblem
}) => {
  const [state, setState] = useState({
    testCases: [],
    showAddTestCaseDialog: false,
    editTestCaseData: {},
    editTestCaseIndex: null
  });

  const openAddTestCaseDialog = () => {
    setState({ ...state, showAddTestCaseDialog: true });
  };

  const closeAddTestCaseDialog = () => {
    const stateObj = { ...state };
    stateObj.showAddTestCaseDialog = false;
    stateObj.editTestCaseData = {};
    stateObj.editTestCaseIndex = null;
    setState(stateObj);
  };

  const updateTestCaseList = data => {
    const stateObj = { ...state };
    if (stateObj.editTestCaseIndex === null) {
      stateObj.testCases.push(data);
    } else {
      stateObj.testCases[stateObj.editTestCaseIndex] = { ...data };
      stateObj.editTestCaseIndex = null;
    }
    stateObj.showAddTestCaseDialog = false;
    setState(stateObj);
    onSubmit(stateObj.testCases);
  };

  const editTestCaseHandler = (testCase, index) => {
    const stateObj = { ...state };
    const editTestCase = {
      is_public: testCase.is_public,
      memory_limit: testCase.memory_limit,
      time_limit: testCase.time_limit,
      inputs: testCase.inputs,
      output: testCase.output
    };
    if (testCase.uuid) {
      editTestCase.uuid = testCase.uuid;
    }
    stateObj.editTestCaseData = editTestCase;
    stateObj.editTestCaseIndex = index;
    stateObj.showAddTestCaseDialog = true;
    setState(stateObj);
  };

  const deleteTestCaseHandler = index => {
    const stateObj = { ...state };
    const { uuid } = stateObj.testCases[index];
    if (uuid) {
      api.test.deleteTestCase(uuid);
    }
    stateObj.testCases.splice(index, 1);
    setState(stateObj);
    onSubmit(stateObj.testCases);
  };

  const showDeleteConfirmDialog = (testCase, index) => {
    confirm({
      title: 'Do you want to Delete test case?',
      content: `Test Case ${index} will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTestCaseHandler(index);
      }
    });
  };

  const prepareTestCasesRow = (testCase, index) => {
    return (
      <div className="test-cases-row" key={index}>
        <div className="test-cases-row-item">{`Test Case ${index}`}</div>
        <div className="test-cases-row-item">
          {testCase.is_public ? 'True' : 'False'}
        </div>
        <div className="test-cases-row-item">{testCase.memory_limit}</div>
        <div className="test-cases-row-item">{testCase.time_limit}</div>
        <div className="test-cases-row-item actions">
          <Icon
            type="edit"
            theme="outlined"
            onClick={() => editTestCaseHandler(testCase, index)}
          />
          <Icon
            type="delete"
            theme="outlined"
            onClick={() => showDeleteConfirmDialog(testCase, index)}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (targetProblem && targetProblem.uuid) {
      const stateObj = { ...state };
      stateObj.testCases = targetProblem.test_cases;
      setState(stateObj);
    }
    // eslint-disable-next-line
  }, [targetProblem]);

  return (
    <div className="problem-test-cases-form">
      <h4>Test Cases</h4>
      <Button className="add-input" onClick={openAddTestCaseDialog}>
        Add Test Case
      </Button>
      <div className="test-cases-list">
        <div className="test-cases-row header-row">
          <div className="test-cases-row-item column-title">Title</div>
          <div className="test-cases-row-item column-title">Public</div>
          <div className="test-cases-row-item column-title">Memory</div>
          <div className="test-cases-row-item column-title">Time</div>
          <div className="test-cases-row-item column-title">Actions</div>
        </div>
        {!state.testCases.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          state.testCases.map((testCase, index) =>
            prepareTestCasesRow(testCase, index)
          )
        )}
      </div>
      <AddTestCaseDialog
        isVisible={state.showAddTestCaseDialog}
        editTestCaseData={state.editTestCaseData}
        onSubmit={updateTestCaseList}
        onCancel={closeAddTestCaseDialog}
        problemInputs={JSON.parse(JSON.stringify(problemInputs))}
        problemOutput={problemOutput}
      />
    </div>
  );
};

TestCases.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  problemInputs: PropTypes.arrayOf(PropTypes.any),
  problemOutput: PropTypes.objectOf(PropTypes.any),
  targetProblem: PropTypes.objectOf(PropTypes.any)
};

TestCases.defaultProps = {
  problemInputs: [],
  problemOutput: {},
  targetProblem: {}
};

export default TestCases;
