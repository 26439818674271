import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message, Drawer, Button, Select } from 'antd';

import './Problems.less';
import api from '../../api';
import ProblemsList from './ProblemsList/ProblemsListHOC';
import ProblemDetails from '../../components/ProblemDetails/ProblemDetails';
import Loader from '../../components/Loader/Loader';
import AddEditProblem from './AddEditProblem/AddEditProblem';

const Problems = ({ problemLevel }) => {
  const [state, setState] = useState({
    showMarkedProblemDrawer: false,
    problems: [],
    selectedProblem: {},
    loading: false,
    drawerVisible: false,
    updateProblem: false,
    page: 1,
    pageEnd: false,
    problemsLoaded: false,
    difficultyLevel: null
  });

  const fetchProblems = useCallback(
    (level = null) => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.problems
        .getProblems('', state.page, process.env.REACT_APP_PAGE_LIMIT, level)
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              problems: [...stateObj.problems, ...resp.items],
              problemsLoaded: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        }); // eslint-disable-next-line
  }, [state.page]);

  const openMarkedProblemDrawer = () => {
    setState(stateObj => ({ ...stateObj, showMarkedProblemDrawer: true }));
  };

  const closeMarkedProblemDrawer = () => {
    setState(stateObj => ({
      ...stateObj,
      selectedProblem: {},
      showMarkedProblemDrawer: false
    }));
  };

  const showDrawer = problem => {
    setState(stateObj => ({
      ...stateObj,
      selectedProblem: problem,
      drawerVisible: true
    }));
  };

  const onClose = () => {
    setState(stateObj => ({ ...stateObj, drawerVisible: false }));
  };

  const onAddProblem = () => {
    setState(stateObj => ({ ...stateObj, updateProblem: false }));
    openMarkedProblemDrawer();
  };

  const onSelectProblem = problem => {
    setState(stateObj => ({
      ...stateObj,
      selectedProblem: problem,
      updateProblem: true
    }));
    openMarkedProblemDrawer();
  };

  const appendMarkedProblem = problem => {
    setState(stateObj => ({
      ...stateObj,
      problems: [problem, ...state.problems]
    }));
    closeMarkedProblemDrawer();
  };

  const updateMarkedProblem = data => {
    const index = state.problems.findIndex(
      problem => problem.uuid === data.uuid
    );
    const updatedProblems = [...state.problems];
    updatedProblems[index] = data;
    setState(stateObj => ({ ...stateObj, problems: updatedProblems }));
    closeMarkedProblemDrawer();
  };

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      if (!state.pageEnd) {
        setState(stateObj => ({ ...stateObj, page: state.page + 1 }));
      }
    }
  };

  const handleChange = value => {
    setState(stateObj => ({
      ...stateObj,
      difficultyLevel: value,
      problems: [],
      problemsLoaded: false
    }));
    if (state.page === 1) {
      fetchProblems(value);
    } else {
      setState(stateObj => ({ ...stateObj, pageEnd: false, page: 1 }));
    }
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchProblems(state.difficultyLevel);
    } // eslint-disable-next-line
  }, [state.page, fetchProblems, state.pageEnd]);

  return (
    <div className="problems-page">
      <div className="header">
        <h2>Problems</h2>
        <Button
          icon="plus"
          type="primary"
          size="large"
          onClick={() => onAddProblem()}
        >
          Add Problem
        </Button>
      </div>
      <div className="problem-filter">
        <Select
          defaultValue="All Problems"
          style={{ width: '150px' }}
          size="large"
          onChange={handleChange}
        >
          <Select.Option value={null}>All Problems</Select.Option>
          {Object.keys(problemLevel).map((key, index) => (
            <Select.Option value={index} key={key}>
              {problemLevel[key].name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div
        className="problems-list-wrapper"
        onScroll={handleScroll}
        style={!state.problemsLoaded ? { position: 'relative' } : null}
      >
        {state.loading && <Loader infiniteLoader={state.problemsLoaded} />}
        <ProblemsList
          problems={state.problems}
          showDrawer={showDrawer}
          onSelect={onSelectProblem}
        />
      </div>
      <Drawer
        title={
          state.selectedProblem
            ? state.selectedProblem.title
              ? state.selectedProblem.title
              : 'Marked Problem'
            : 'Add Problem'
        }
        className="problem-details-drawer"
        width="60%"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={state.drawerVisible}
        destroyOnClose={true}
      >
        <ProblemDetails problem={state.selectedProblem} />
      </Drawer>
      <AddEditProblem
        isVisible={state.showMarkedProblemDrawer}
        onCancel={closeMarkedProblemDrawer}
        onSubmit={
          state.updateProblem ? updateMarkedProblem : appendMarkedProblem
        }
        targetProblem={JSON.parse(JSON.stringify(state.selectedProblem))}
        editProblem={state.updateProblem}
      />
    </div>
  );
};

Problems.propTypes = {
  problemLevel: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default Problems;
