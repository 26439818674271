/* eslint-disable camelcase */
import './Tags.less';

import { Button, Empty, Modal, Select, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AddTagDialog from '../../components/AddTagDialog/AddTagDialog';
import List from './List/List';
import Loader from '../../components/Loader/Loader';
import api from '../../api';

const { Option } = Select;
const { confirm } = Modal;
const Tags = () => {
  const [state, setState] = useState({
    tags: [],
    userGeneratedTags: [],
    editTagData: {},
    page: 1,
    ugPage: 1, // user generated skills pagination
    pageEnd: false,
    loading: false,
    tagsLoaded: false,
    showAddTagDialog: false,
    searchedTags: [],
    selectedTags: []
  });

  const fetchTags = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.tags
        .getList(
          updatedState.searchTags,
          updatedState.page,
          process.env.REACT_APP_PAGE_LIMIT
        )
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              tags: [...updatedState.tags, ...resp.items],
              tagsLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.page]
  );

  const fetchUserGeneratedTags = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.tags
        .getList('', updatedState.page, process.env.REACT_APP_PAGE_LIMIT, {
          is_user_generated: true
        })
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              userGeneratedTags: [
                ...updatedState.userGeneratedTags,
                ...resp.items
              ],
              tagsLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.ugPage]
  );

  const searchCalled = options => {
    const stateObj = { ...state };
    stateObj.tags = [];
    stateObj.tagsLoaded = false;
    stateObj.editTagData = {};
    const tagsArray = options.map(option => option.key);
    stateObj.searchTags = tagsArray.length
      ? tagsArray.reduce((query, item) => `${query},${item}`)
      : '';
    stateObj.selectedTags = options.map(option => option.props.value);
    setState({ ...stateObj });
    fetchTags(stateObj);
  };

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: stateObj.page + 1 }));
    }
  };

  const openAddTagDialog = () =>
    setState(stateObj => ({ ...stateObj, showAddTagDialog: true }));

  const closeAddTagDialog = () =>
    setState(stateObj => ({
      ...stateObj,
      showAddTagDialog: false,
      editTagData: {}
    }));

  const updateTagsList = data => {
    if (state.editTagData.uuid) {
      const index = state.tags.findIndex(tag => tag.uuid === data.uuid);
      const updatedTags = [...state.tags];
      updatedTags[index] = data;
      setState(stateObj => ({ ...stateObj, tags: updatedTags }));
      message.success('Tag edited successfully!');
    } else {
      setState(stateObj => ({
        ...stateObj,
        tags: [data, ...stateObj.tags]
      }));
      message.success('Tag added successfully!');
    }
    closeAddTagDialog();
  };

  const editTagHandler = (uuid, user_generated = false) => {
    let TagToEdit;
    if (user_generated) {
      TagToEdit = state.userGeneratedTags.find(tag => tag.uuid === uuid);
    } else {
      TagToEdit = state.tags.find(tag => tag.uuid === uuid);
    }
    setState(stateObj => ({
      ...stateObj,
      showAddTagDialog: true,
      editTagData: {
        uuid: TagToEdit.uuid,
        name: TagToEdit.name,
        slug: TagToEdit.slug
      }
    }));
  };

  const deleteTagHandler = (uuid, index, user_generated = false) => {
    api.tags.softDelete(uuid);
    const updatedTags = user_generated
      ? [...state.userGeneratedTags]
      : [...state.tags];
    updatedTags.splice(index, 1);
    if (user_generated) {
      setState(stateObj => ({
        ...stateObj,
        userGeneratedTags: updatedTags
      }));
    } else {
      setState(stateObj => ({ ...stateObj, categories: updatedTags }));
    }
    message.success('Tag deleted successfully!');
  };

  const showDeleteConfirmDialog = (uuid, index, user_generated = false) => {
    confirm({
      title: 'Do you want to Delete Tag?',
      content: `The ${state.tags[index].name} tag will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTagHandler(uuid, index, user_generated);
      }
    });
  };

  const fetchTagOptions = (query = '') => {
    api.ninja
      .fetchTags(query)
      .then(resp => {
        if (resp && resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            searchedTags: [...resp.items]
          }));
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchTags({ ...state });
      // eslint-disable-next-line no-console
      // fetchUserGeneratedCategories({ ...state });
      fetchTagOptions();
    } // eslint-disable-next-line
  }, [state.page, state.pageEnd, fetchTags]);

  return (
    <div className="tags-page">
      <div className="header">
        <h2>Tags</h2>
        <div className="search-bar">
          <Select
            mode="multiple"
            placeholder="Search tags"
            loading={state.loading}
            value={state?.selectedTags}
            size="large"
            style={{ width: '100%', paddingRight: '1rem' }}
            onChange={(values, options) => searchCalled(options)}
            onSearch={query => fetchTagOptions(query)}
            maxTagCount={2}
          >
            {state?.searchedTags &&
              state?.searchedTags.length &&
              state?.searchedTags.map(tag => (
                <Option key={tag.uuid} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
          </Select>
          <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={openAddTagDialog}
          >
            Add Tag
          </Button>
        </div>
      </div>
      <div
        className="tags-list-wrapper"
        style={!state.tagsLoaded ? { position: 'relative' } : null}
        onScroll={handleScroll}
      >
        {state.loading && <Loader infiniteLoader={state.tagsLoaded} />}
        {!state.tags.length && !state.loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <List
            tags={state.tags}
            editTagHandler={editTagHandler}
            deleteTagHandler={showDeleteConfirmDialog}
            userGeneratedTags={state.userGeneratedTags}
          />
        )}
      </div>
      <AddTagDialog
        isVisible={state.showAddTagDialog}
        onSubmit={updateTagsList}
        onCancel={closeAddTagDialog}
        editTagData={state.editTagData}
      />
    </div>
  );
};

export default Tags;
