import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Tabs, Button, message } from 'antd';

import './AddEditProblem.less';
import Basic from './Basic/BasicHOC';
import Inputs from './Inputs/Inputs';
import TestCases from './TestCases/TestCases';
import api from '../../../api';
import Loader from '../../../components/Loader/Loader';

const { TabPane } = Tabs;

const AddEditProblem = ({
  isVisible,
  onCancel,
  onSubmit,
  targetProblem,
  editProblem
}) => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [problem, setProblem] = useState({});
  const [problemInputs, setProblemInputs] = useState([]);
  const [problemOutput, setProblemOutput] = useState({});
  const [problemTestCases, setProblemTestCases] = useState([]);
  const [testCasePublic, setTestCasePublic] = useState(false);

  const changeTab = activeValue => {
    setActiveKey(activeValue);
  };

  const updateProblem = (problemObj, editing = false) => {
    setProblem({ ...problemObj });
    setProblemOutput({ ...problemObj.output });
    if (!editing) {
      changeTab('2');
    }
  };

  const updateProblemInputs = inputs => {
    setProblemInputs([...inputs]);
  };

  const isTestCasePublic = testCases => {
    const publicTestCase = testCases.findIndex(
      testCase => testCase.is_public === true
    );
    publicTestCase >= 0 ? setTestCasePublic(true) : setTestCasePublic(false);
  };

  const updateProblemTestCases = testCases => {
    isTestCasePublic(testCases);
    setProblemTestCases([...testCases]);
  };

  const onDrawerClose = () => {
    onCancel();
    setProblem({});
    setProblemInputs([]);
    setProblemOutput({});
    setProblemTestCases([]);
    setTestCasePublic(false);
    changeTab('1');
  };

  const addUpdateProblem = data => {
    setLoading(true);
    const call = data.uuid ? api.problems.update(data) : api.problems.add(data);
    call
      .then(resp => {
        setLoading(false);
        if (resp && resp.uuid) {
          data.uuid
            ? message.success('Problem Updated!')
            : message.success('Problem Added!');
          onSubmit(resp);
          onDrawerClose();
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const submit = () => {
    const problemObj = { ...problem };
    problemObj.inputs = problemInputs;
    problemObj.test_cases = problemTestCases;
    addUpdateProblem(problemObj);
  };

  useEffect(() => {
    if (isVisible && targetProblem && targetProblem.uuid) {
      setProblem(targetProblem);
      setProblemInputs(targetProblem.inputs);
      setProblemOutput(targetProblem.output);
      setProblemTestCases(targetProblem.test_cases);
      isTestCasePublic(targetProblem.test_cases);
    }
  }, [isVisible, targetProblem]);

  return (
    <div>
      <Drawer
        title={editProblem ? 'Edit Problem' : 'Add Problem'}
        placement="right"
        width="60%"
        className="add-edit-problem"
        maskClosable={false}
        onClose={onDrawerClose}
        visible={isVisible}
        getContainer={false}
        destroyOnClose={true}
      >
        {loading && <Loader />}
        <Tabs activeKey={activeKey} onChange={changeTab}>
          <TabPane tab="Basic" key="1">
            <Basic targetProblem={targetProblem} onSubmit={updateProblem} />
          </TabPane>
          <TabPane tab="Inputs" key="2" disabled={!(problem && problem.title)}>
            <Inputs
              onSubmit={updateProblemInputs}
              targetProblem={targetProblem}
            />
          </TabPane>
          <TabPane tab="Test Cases" key="3" disabled={!problemInputs.length}>
            <TestCases
              onSubmit={updateProblemTestCases}
              problemInputs={problemInputs}
              problemOutput={problemOutput}
              targetProblem={targetProblem}
            />
          </TabPane>
        </Tabs>
        {testCasePublic ? (
          <div className="submit-actions">
            <Button onClick={onDrawerClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={() => submit()} type="primary">
              Submit
            </Button>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
};

AddEditProblem.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  targetProblem: PropTypes.objectOf(PropTypes.any),
  editProblem: PropTypes.bool.isRequired
};

AddEditProblem.defaultProps = {
  onCancel: () => {},
  targetProblem: {}
};

export default AddEditProblem;
