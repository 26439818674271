import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import Stackedit from 'stackedit-js';

const StackEdit = ({ id, value, placeholder, onUpdate }) => {
  const openStackEdit = () => {
    // Get the textarea.
    const el = document.getElementById(id);
    el.value = value;

    // Create the Stackedit object.
    const stackedit = new Stackedit();

    // Open the iframe
    stackedit.openFile({
      name: 'Filename', // with a filename
      content: {
        text: el.value // and the Markdown content.
      }
    });

    // Listen to StackEdit events and apply the changes to the textarea.
    stackedit.on('fileChange', file => {
      el.value = file.content.text;
      onUpdate(file.content.text);
    });
  };

  return (
    <Input.TextArea
      rows={5}
      id={id}
      value={value}
      placeholder={placeholder}
      onClick={openStackEdit}
    />
  );
};

StackEdit.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};

StackEdit.defaultProps = {
  value: '',
  placeholder: 'Enter text here'
};

export default StackEdit;
