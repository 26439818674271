const defaultState = {
  meta: [],
  businessUpdated: false
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE_META': {
      const { meta } = action;
      return {
        ...state,
        meta
      };
    }
    case 'BUSINESS_UPDATED': {
      const { businessUpdated } = action;
      return { ...state, businessUpdated };
    }
    case 'BLOG_UPDATED': {
      const { blogUpdated } = action;
      return { ...state, blogUpdated };
    }
    default:
      return state;
  }
};
