import React from 'react';
import { Icon, Empty } from 'antd';
import PropTypes from 'prop-types';

const TestsList = ({ tests, editTestHandler, deleteTestHandler }) => {
  const prepareTestsRow = (test, index) => {
    return (
      <div className="tests-row" key={test.uuid}>
        <div className="tests-row-item capitalize">{test.title}</div>
        <div className="tests-row-item capitalize">{test.total_attempts}</div>
        <div className="tests-row-item capitalize">
          {test.is_draft.toString()}
        </div>
        <div className="tests-row-item actions">
          <Icon
            type="edit"
            theme="outlined"
            onClick={() => editTestHandler(test.uuid)}
          />
          <Icon
            type="delete"
            theme="outlined"
            onClick={() => deleteTestHandler(test.uuid, index)}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="tests-list">
      <div className="tests-row header-row">
        <div className="tests-row-item column-title">Title</div>
        <div className="tests-row-item column-title">Total Used</div>
        <div className="tests-row-item column-title">Draft</div>
        <div className="tests-row-item column-title">Actions</div>
      </div>
      {!tests.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        tests.map((test, index) => prepareTestsRow(test, index))
      )}
    </div>
  );
};

TestsList.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.any).isRequired,
  editTestHandler: PropTypes.func.isRequired,
  deleteTestHandler: PropTypes.func.isRequired
};

export default TestsList;
