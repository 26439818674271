import { connect } from 'react-redux';
import Search from './Search';

const mapStateToProps = state => ({
  branchesStatus:
    state.config.meta.branch && state.config.meta.branch.status
      ? state.config.meta.branch.status
      : {}
});

export default connect(mapStateToProps)(Search);
