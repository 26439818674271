import './BusinessCard.less';

import { Button, Descriptions, Icon, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

const BusinessCard = ({ data }) => {
  const { businessId } = useParams();
  const history = useHistory();
  const [subscribed, setSubscribed] = useState(false);
  const [isAdvertiser, setIsAdvertiser] = useState(false);

  useEffect(() => {
    if (data.subscription) {
      setSubscribed(!data.subscription.is_cancelled);
    }
    if (data.is_advertiser) {
      setIsAdvertiser(!data.is_advertiser);
    }
  }, [data]);

  const openDetails = () => history.push(`/businesses/${data.uuid}`);

  const open = e => {
    e.stopPropagation();
    window.open(
      `${data.website.includes('https://') ? '' : 'https://'}${data.website}`
    );
  };

  return (
    <div
      className={`business-card ${data.uuid === businessId ? 'selected' : ''}`}
      style={{ padding: '1rem' }}
      onClick={openDetails}
    >
      <Row type="flex" justify="space-between" align="middle">
        <h4>{data.name}</h4>
        <Button type="link" onClick={open}>
          <Icon type="global" theme="outlined" />
        </Button>
      </Row>
      <Descriptions column={2}>
        <Descriptions.Item label="Total Branches">
          {data.total_branches}
        </Descriptions.Item>
        <Descriptions.Item label="Total Deals">
          {data.total_deals}
        </Descriptions.Item>
        <Descriptions.Item label="Type">{data.type}</Descriptions.Item>
        <Descriptions.Item label="Is Advertiser">
          {isAdvertiser ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Subscribed">
          {subscribed ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Owner">
          {data.user ? `${data.user.first_name} ${data.user.last_name}` : ''}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

BusinessCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default BusinessCard;
