/* eslint-disable camelcase */

import './Ninjas.less';

import {
  Button,
  Card,
  Col,
  Divider,
  Icon,
  Modal,
  Row,
  Select,
  Tag,
  message
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Details from './Details/Details';
import List from './List/List';
import Search from '../../components/ExtensiveSearch/ExtensiveSearchHOC';
import api from '../../api';

const { confirm } = Modal;

const Ninjas = () => {
  const history = useHistory();
  const { ninjaId } = useParams();
  const meta = useSelector(state => state.config.meta);
  const commitments = {};
  const ninjaStatusObj = {};
  meta?.ninja?.commitment?.length && meta.ninja.commitment.map(item => commitments[item.value] = item.name);
  meta?.ninja?.status?.length && meta.ninja.status.map(item => ninjaStatusObj[item.value] = item.name);
  const [state, setState] = useState({
    ninjas: [],
    ninjaFilters: {},
    selectedNinja: {},
    page: 1,
    loading: false,
    pageEnd: false,
    pageLoaded: false,
    ninjasLoaded: false,
    showNinjasSearchDialog: false
  });

  const fetchNinjas = useCallback(
    updatedState => {
      if (updatedState.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.ninja
        .getList('', updatedState.page, 10, updatedState.ninjaFilters)
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              ninjas: [...stateObj.ninjas, ...resp.items],
              pageLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: false }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.page]
  );

  const fetchNinja = uuid => {
    api.ninja
      .get(uuid)
      .then(resp => {
        setState(stateObj => ({ ...stateObj, selectedNinja: resp }));
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const openDetails = useCallback(
    selectedNinja => {
      setState(stateObj => ({ ...stateObj, selectedNinja }));
      history.push(`/ninjas/${selectedNinja.uuid}`);
    },
    [history]
  );

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: state.page + 1 }));
    }
  };

  const handleApplyFilters = filters => {
    const stateObj = { ...state };
    stateObj.ninjas = [];
    stateObj.ninjaFilters = filters;
    stateObj.loading = false;
    stateObj.pageLoaded = false;
    stateObj.showNinjasSearchDialog = false;
    if (state.page === 1) {
      setState({ ...stateObj });
      history.push('/ninjas');
      fetchNinjas(stateObj);
    } else {
      stateObj.page = 1;
      stateObj.pageEnd = false;
      history.push('/ninjas');
      setState({ ...stateObj });
    }
  };

  const openNinjasSearch = () =>
    setState(stateObj => ({ ...stateObj, showNinjasSearchDialog: true }));

  const closeNinjasSearch = () =>
    setState(stateObj => ({ ...stateObj, showNinjasSearchDialog: false }));

  const removeFilter = filterItem => {
    const updatedState = { ...state };
    updatedState.ninjas = [];

    filterItem === 'experience_started'
      ? (updatedState.ninjaFilters[filterItem] = [0, 10])
      : delete updatedState.ninjaFilters[filterItem];

    setState({ ...updatedState });
    fetchNinjas(updatedState);
  };

  const handleStatusUpdate = value => {
    confirm({
      title: 'Do you want to update Ninja Status?',
      content: `The status will be updated to ${ninjaStatusObj[value]}.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        api.ninja
          .update(state.selectedNinja.uuid, { status: value })
          .then(resp => {
            const ninjas = [ ...state.ninjas ];
            const index = ninjas.findIndex(
              ninja => ninja.uuid === state.selectedNinja.uuid
            );
            ninjas[index].status = resp.status;
            setState(stateObj => ({
              ...stateObj,
              ninjas,
              selectedNinja: resp
            }));
          })
          .catch(err => {
            message.error(err.message);
          });
      }
    });
  };

  const handleNinjaArchive = () => {
    const user = state?.selectedNinja?.user;
    confirm({
      title: 'Do you want to archive Ninja?',
      content: `${user?.first_name} ${user?.last_name} will be archived!`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        api.ninja
          .softDelete(state.selectedNinja.uuid)
          .then(resp => {
            if (resp.success) {
              const ninjas = [...state.ninjas];
              const index = ninjas.findIndex(
                ninja => ninja.uuid === state.selectedNinja.uuid
              );
              ninjas.splice(index, 1);
              const selectedNinja = { ...ninjas[0] };
              setState(stateObj => ({ ...stateObj, ninjas, selectedNinja }));
              history.push(`/ninjas/${selectedNinja.uuid}`);
            }
          })
          .catch(err => {
            message.error(err.message);
          });
      }
    });
  };

  const ninjaStatusDropdown = () => {
    return (
      <div className="ninja-status">
        <Select
          value={ninjaStatusObj[state?.selectedNinja?.status]}
          style={{ width: '150px' }}
          size="large"
          onChange={handleStatusUpdate}
        >
          {meta?.ninja?.status.map((item, index) => (
            <Select.Option value={item.value} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchNinjas({ ...state });
    } // eslint-disable-next-line
  }, [state.page, state.pageEnd, fetchNinjas]);

  useEffect(() => {
    if (ninjaId && state.page === 1 && !state?.ninjas.length) {
      fetchNinja(ninjaId);
    } else if (!state?.ninjas.length) {
      setState(stateObj => ({ ...stateObj, pageEnd: false }));
    } else if (!ninjaId) {
      openDetails(state.ninjas[0]);
    } // eslint-disable-next-line
  }, [state.ninjas, openDetails, history]);

  return (
    <div className="ninjas-page">
      <Row>
        <Col className="ninjas-list-section" span={8}>
          <div className="search-container">
            <div className="search-box" onClick={openNinjasSearch}>
              Search Ninjas
              <Icon type="search" theme="outlined" />
            </div>
          </div>
          <div className="filters">
            {state?.ninjaFilters?.name ? (
              <Tag color="blue" closable onClose={() => removeFilter('name')}>
                Name: {state.ninjaFilters.name}
              </Tag>
            ) : null}
            {state?.ninjaFilters?.skills?.length ? (
              <Tag color="blue" closable onClose={() => removeFilter('skills')}>
                {state.ninjaFilters.skills.length} skill(s)
              </Tag>
            ) : null}
            {state?.ninjaFilters?.experience_started &&
            (state.ninjaFilters.experience_started[0] > 0 ||
              state.ninjaFilters.experience_started[1] < 10) ? (
              <Tag
                color="blue"
                closable
                onClose={() => removeFilter('experience_started')}
              >
                {state.ninjaFilters.experience_started.join('-')} year(s)
              </Tag>
            ) : null}
            {state?.ninjaFilters?.status ? (
              <Tag color="blue" closable onClose={() => removeFilter('status')}>
                Status: {meta.ninja.status[state.ninjaFilters.status].name}
              </Tag>
            ) : null}
            {state?.ninjaFilters?.title ? (
              <Tag color="blue" closable onClose={() => removeFilter('title')}>
                {state.ninjaFilters.title}
              </Tag>
            ) : null}
            {state?.ninjaFilters?.commitment ? (
              <Tag
                color="blue"
                closable
                onClose={() => removeFilter('commitment')}
              >
                {commitments[state.ninjaFilters.commitment]}
              </Tag>
            ) : null}
            {state?.ninjaFilters?.email ? (
              <Tag color="blue" closable onClose={() => removeFilter('email')}>
                Email: {state.ninjaFilters.email}
              </Tag>
            ) : null}
          </div>
          <Divider />
          <List
            ninjas={state?.ninjas}
            loading={state?.loading}
            pageLoaded={state?.pageLoaded}
            handleScroll={handleScroll}
            openDetails={openDetails}
          />
        </Col>
        <Col className="ninja-details-page" span={16}>
          <Button
            type="danger"
            size="large"
            className="archive-action"
            onClick={handleNinjaArchive}
          >
            Archive
          </Button>
          {ninjaStatusDropdown()}
          {ninjaId && state?.selectedNinja?.user && (
            <Card
              title={`${state?.selectedNinja?.user?.first_name} ${state?.selectedNinja?.user?.last_name}`}
              className="ninja-detail-card"
            >
              <Details data={state?.selectedNinja} />
            </Card>
          )}
        </Col>
      </Row>
      <Search
        handleApplyFilters={handleApplyFilters}
        isVisible={state?.showNinjasSearchDialog}
        onCancel={closeNinjasSearch}
        ninjaFilters={state?.ninjaFilters}
        searchNinjas={true}
      />
    </div>
  );
};

export default Ninjas;
