import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Businesses from './pages/Businesses/Businesses';
import Blogs from './pages/Blogs/Blogs';
import Dashboard from './pages/Dashboard/Dashboard';
import GuestRoute from './GuestRoute';
import Branches from './pages/Branches/Branches';
import Ninjas from './pages/Ninjas/Ninjas';
import PrivateRoute from './PrivateRoute';
import Problems from './pages/Problems/ProblemsHOC';
import SideMenu from './components/SideMenu/SideMenuHOC';
import Signin from './pages/Signin/SigninHOC';
import Amenities from './pages/Amenities/Amenities';
import Categories from './pages/Categories/Categories';
import Tags from './pages/Tags/Tags';
import Tests from './pages/Tests/Tests';
import api from './api';
import { fetchAndSetMeta } from './store/config/configAction';
import './assets/less/App.less';

function App({ user, fetchMeta }) {
  api.user.setAuthHeader();
  useEffect(() => {
    fetchMeta();
  }, [fetchMeta]);

  return (
    <div className="App">
      <BrowserRouter>
        <Row>
          <Col
            sm={!user || !user.uuid ? 0 : 6}
            md={!user || !user.uuid ? 0 : 5}
            lg={!user || !user.uuid ? 0 : 4}
            xl={!user || !user.uuid ? 0 : 3}
          >
            {user && user.uuid ? <SideMenu /> : <></>}
          </Col>
          <Col
            sm={!user || !user.uuid ? 24 : 18}
            md={!user || !user.uuid ? 24 : 19}
            lg={!user || !user.uuid ? 24 : 20}
            xl={!user || !user.uuid ? 24 : 21}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/amenities" component={Amenities} />
              <PrivateRoute path="/categories" component={Categories} />
              <PrivateRoute path="/tags" component={Tags} />
              <PrivateRoute
                path="/businesses/:businessId?"
                component={Businesses}
              />
              <PrivateRoute path="/blogs/:blogId?" component={Blogs} />
              <PrivateRoute path="/branches/:branchId?" component={Branches} />
              <PrivateRoute path="/ninjas/:ninjaId?" component={Ninjas} />
              <PrivateRoute path="/problems" component={Problems} />
              <PrivateRoute path="/tests" component={Tests} />
              <GuestRoute path="/signin" component={Signin} />
            </Switch>
          </Col>
        </Row>
      </BrowserRouter>
    </div>
  );
}

App.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  fetchMeta: PropTypes.func
};

App.defaultProps = {
  user: null,
  fetchMeta: () => {}
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    isLoggedIn: state.user.isLoggedIn
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMeta: () => dispatch(fetchAndSetMeta())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
