import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message, InputNumber, Row, Col } from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const AddMenuItemDialog = ({
  //   businessDetails,
  menuCategoryUuid,
  isVisible,
  onSubmit,
  onCancel
}) => {
  //   const initialState = {
  //     name: businessDetails.name,
  //     website: businessDetails.website
  //   };
  const [error, setError] = useState({});
  const requiredFields = ['item_name', 'item_price'];
  const [state, setState] = useState({
    // business: initialState,
    menuItem: {
      item_name: '',
      item_desc: null,
      item_price: 0,
      item_serving: null,
      item_size: null
    },
    loading: false
  });

  const resetState = () =>
    setState(stateObj => ({
      ...stateObj,
      //   business: initialState,
      menuItem: {},
      loading: false
    }));

  const addMenuItem = useCallback(
    menuItem => {
      setState(stateObj => ({ ...stateObj, loading: true }));
      setError({});
      api.menuItem
        .add({ ...menuItem, menu_category_uuid: menuCategoryUuid })
        .then(resp => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          if (resp && resp.uuid) {
            onSubmit(resp);
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    },
    [onSubmit]
  );

  const submit = menuItem => {
    const valid = validate(state.menuItem, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    // setState({
    //   ...state,
    //   menuCategory: {
    //     ...state.menuCategory,
    //     branch_uuid: branchUuid
    //   }
    // });
    addMenuItem(menuItem);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  //   useEffect(() => {
  //     setState(stateObj => ({
  //       ...stateObj,
  //       business: {
  //         name: businessDetails.name,
  //         website: businessDetails.website
  //       }
  //     }));
  //   }, [businessDetails]);

  return (
    <Modal
      title="Add Menu Item"
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(state.menuItem)}
      className="add-menu-item-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Item Name" name="item_name" required>
                {FormItemWithError(
                  <Input
                    size="large"
                    placeholder="Item Name"
                    value={state.menuItem.item_name}
                    onChange={e =>
                      setState({
                        ...state,
                        menuItem: {
                          ...state.menuItem,
                          item_name: e.target.value
                        }
                      })
                    }
                  />,
                  'item_name',
                  error
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Item Description" name="item_desc">
                <Input.TextArea
                  rows={5}
                  size="large"
                  placeholder="Item Description"
                  value={state.menuItem.item_desc}
                  onChange={e =>
                    setState({
                      ...state,
                      menuItem: {
                        ...state.menuItem,
                        item_desc: e.target.value
                      }
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Item Price" name="item_price" required>
                {FormItemWithError(
                  <InputNumber
                    type="number"
                    style={{ width: '75%' }}
                    size="large"
                    placeholder="Item Price"
                    value={state.menuItem.item_price}
                    onChange={value =>
                      setState({
                        ...state,
                        menuItem: {
                          ...state.menuItem,
                          item_price: value
                        }
                      })
                    }
                  />,
                  'item_price',
                  error
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Item Serving" name="item_serving">
                <InputNumber
                  type="number"
                  style={{ width: '75%' }}
                  size="large"
                  placeholder="Item Serving"
                  value={state.menuItem.item_serving}
                  onChange={value =>
                    setState({
                      ...state,
                      menuItem: {
                        ...state.menuItem,
                        item_serving: value
                      }
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Item Size" name="item_size">
                <Input
                  size="large"
                  placeholder="Item Size"
                  style={{ width: '100%' }}
                  value={state.menuItem.item_size}
                  onChange={e =>
                    setState({
                      ...state,
                      menuItem: {
                        ...state.menuItem,
                        item_size: e.target.value
                      }
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

AddMenuItemDialog.propTypes = {
  //   businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  menuCategoryUuid: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddMenuItemDialog;
