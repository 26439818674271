/* eslint-disable camelcase */
import './Categories.less';

import { Button, Empty, Modal, Select, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import AddCategoryDialog from '../../components/AddCategoryDialog/AddCategoryDialog';
import List from './List/List';
import Loader from '../../components/Loader/Loader';
import api from '../../api';

const { Option } = Select;
const { confirm } = Modal;
const Categories = () => {
  const [state, setState] = useState({
    categories: [],
    userGeneratedCategories: [],
    editCategoryData: {},
    page: 1,
    ugPage: 1, // user generated skills pagination
    pageEnd: false,
    loading: false,
    categoriesLoaded: false,
    showAddCategoryDialog: false,
    searchedCategories: [],
    selectedCategories: []
  });

  const fetchCategories = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.categories
        .getList(
          updatedState.searchCategories,
          updatedState.page,
          process.env.REACT_APP_PAGE_LIMIT
        )
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              categories: [...updatedState.categories, ...resp.items],
              categoriesLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.page]
  );

  const fetchUserGeneratedCategories = useCallback(
    updatedState => {
      if (state.loading) return;
      setState(stateObj => ({ ...stateObj, loading: true }));
      api.categories
        .getList('', updatedState.page, process.env.REACT_APP_PAGE_LIMIT, {
          is_user_generated: true
        })
        .then(resp => {
          if (resp && resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              userGeneratedCategories: [
                ...updatedState.userGeneratedCategories,
                ...resp.items
              ],
              categoriesLoaded: true,
              loading: false
            }));
          } else if (!resp.items.length) {
            setState(stateObj => ({
              ...stateObj,
              pageEnd: true,
              loading: false
            }));
          } else {
            setState(stateObj => ({ ...stateObj, loading: true }));
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    }, // eslint-disable-next-line
    [state.ugPage]
  );

  const searchCalled = options => {
    const stateObj = { ...state };
    stateObj.categories = [];
    stateObj.categoriesLoaded = false;
    stateObj.editCategoryData = {};
    const categoriesArray = options.map(option => option.key);
    stateObj.searchCategories = categoriesArray.length
      ? categoriesArray.reduce((query, item) => `${query},${item}`)
      : '';
    stateObj.selectedCategories = options.map(option => option.props.value);
    setState({ ...stateObj });
    fetchCategories(stateObj);
  };

  const handleScroll = e => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
      element.clientHeight
    ) {
      setState(stateObj => ({ ...stateObj, page: stateObj.page + 1 }));
    }
  };

  const openAddCategoryDialog = () =>
    setState(stateObj => ({ ...stateObj, showAddCategoryDialog: true }));

  const closeAddCategoryDialog = () =>
    setState(stateObj => ({
      ...stateObj,
      showAddCategoryDialog: false,
      editCategoryData: {}
    }));

  const updateCategoriesList = data => {
    if (state.editCategoryData.uuid) {
      const index = state.categories.findIndex(
        category => category.uuid === data.uuid
      );
      const updatedCategories = [...state.categories];
      updatedCategories[index] = data;
      setState(stateObj => ({ ...stateObj, categories: updatedCategories }));
      message.success('Category edited successfully!');
    } else {
      setState(stateObj => ({
        ...stateObj,
        categories: [data, ...stateObj.categories]
      }));
      message.success('Category added successfully!');
    }
    closeAddCategoryDialog();
  };

  const editCategoryHandler = (uuid, user_generated = false) => {
    let CategoryToEdit;
    if (user_generated) {
      CategoryToEdit = state.userGeneratedCategories.find(
        category => category.uuid === uuid
      );
    } else {
      CategoryToEdit = state.categories.find(
        category => category.uuid === uuid
      );
    }
    setState(stateObj => ({
      ...stateObj,
      showAddCategoryDialog: true,
      editCategoryData: {
        uuid: CategoryToEdit.uuid,
        name: CategoryToEdit.name,
        slug: CategoryToEdit.slug
      }
    }));
  };

  const deleteCategoryHandler = (uuid, index, user_generated = false) => {
    api.categories.softDelete(uuid);
    const updatedCategories = user_generated
      ? [...state.userGeneratedCategories]
      : [...state.categories];
    updatedCategories.splice(index, 1);
    if (user_generated) {
      setState(stateObj => ({
        ...stateObj,
        userGeneratedCategories: updatedCategories
      }));
    } else {
      setState(stateObj => ({ ...stateObj, categories: updatedCategories }));
    }
    message.success('Category deleted successfully!');
  };

  const showDeleteConfirmDialog = (uuid, index, user_generated = false) => {
    confirm({
      title: 'Do you want to Delete Category?',
      content: `The ${state.categories[index].name} category will be deleted.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCategoryHandler(uuid, index, user_generated);
      }
    });
  };

  const fetchCategoryOptions = (query = '') => {
    api.ninja
      .fetchCategories(query)
      .then(resp => {
        if (resp && resp.items.length) {
          setState(stateObj => ({
            ...stateObj,
            searchedCategories: [...resp.items]
          }));
        }
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (!state.pageEnd) {
      fetchCategories({ ...state });
      // eslint-disable-next-line no-console
      // fetchUserGeneratedCategories({ ...state });
      fetchCategoryOptions();
    } // eslint-disable-next-line
  }, [state.page, state.pageEnd, fetchCategories]);

  return (
    <div className="categories-page">
      <div className="header">
        <h2>Categories</h2>
        <div className="search-bar">
          <Select
            mode="multiple"
            placeholder="Search categories"
            loading={state.loading}
            value={state?.selectedCategories}
            size="large"
            style={{ width: '100%', paddingRight: '1rem' }}
            onChange={(values, options) => searchCalled(options)}
            onSearch={query => fetchCategoryOptions(query)}
            maxTagCount={2}
          >
            {state?.searchedCategories &&
              state?.searchedCategories.length &&
              state?.searchedCategories.map(category => (
                <Option key={category.uuid} value={category.name}>
                  {category.name}
                </Option>
              ))}
          </Select>
          <Button
            icon="plus"
            type="primary"
            size="large"
            onClick={openAddCategoryDialog}
          >
            Add Category
          </Button>
        </div>
      </div>
      <div
        className="categories-list-wrapper"
        style={!state.categoriesLoaded ? { position: 'relative' } : null}
        onScroll={handleScroll}
      >
        {state.loading && <Loader infiniteLoader={state.categoriesLoaded} />}
        {!state.categories.length && !state.loading ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <List
            categories={state.categories}
            editCategoryHandler={editCategoryHandler}
            deleteCategoryHandler={showDeleteConfirmDialog}
            userGeneratedCategories={state.userGeneratedCategories}
          />
        )}
      </div>
      <AddCategoryDialog
        isVisible={state.showAddCategoryDialog}
        onSubmit={updateCategoriesList}
        onCancel={closeAddCategoryDialog}
        editCategoryData={state.editCategoryData}
      />
    </div>
  );
};

export default Categories;
