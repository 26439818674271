import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import './CategorySet.less';

const CategorySet = ({ categories, cardCategories }) => (
  <div className="categories">
    {cardCategories && categories.length > 2 ? (
      <div>
        {categories[0] && (
          <Tag key={`${categories[0].uuid}`}>{categories[0].name}</Tag>
        )}
        {categories[1] && (
          <Tag key={`${categories[1].uuid}`}>{categories[1].name}</Tag>
        )}
        {categories.length > 2 && (
          <Tag key={`${categories[2].uuid}`}>{`+${categories.length -
            2} more`}</Tag>
        )}
      </div>
    ) : (
      categories.map((category, index) => (
        <Tag key={`${category.uuid}-${index}`}>{category.name}</Tag>
      ))
    )}
  </div>
);

CategorySet.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  cardCategories: PropTypes.bool
};

CategorySet.defaultProps = {
  cardCategories: false
};

export default CategorySet;
