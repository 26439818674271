import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Tabs, message } from 'antd';

import './AddEditBranch.less';
import Basic from './Basic/BasicHOC';
import api from '../../../api';
import Loader from '../../../components/Loader/Loader';

const { TabPane } = Tabs;

const AddEditBranch = ({
  isVisible,
  onCancel,
  onSubmit,
  targetBranch,
  editBranch,
  business_uuid
}) => {
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('1');
  const [branch, setBranch] = useState({});

  const changeTab = activeValue => {
    setActiveKey(activeValue);
  };

  const updateBranch = (branchObj, editing = false) => {
    setBranch({ ...branchObj });
    if (!editing) {
      changeTab('2');
    }
  };

  const onDrawerClose = () => {
    onCancel();
    setBranch({});
    changeTab('1');
  };

  const addUpdateBranch = data => {
    setLoading(true);
    const call = data.uuid
      ? api.branches.update(data.uuid, data)
      : api.branches.add(data);
    call
      .then(resp => {
        setLoading(false);
        if (resp && resp.uuid) {
          data.uuid
            ? message.success('Branch Updated!')
            : message.success('Branch Added!');
          onSubmit(resp);
          onDrawerClose();
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const submit = e => {
    console.log('SUBMIT DATA', e);
    const branchObj = { ...e, business_uuid };
    console.log('branchObj', branchObj);
    addUpdateBranch(branchObj);
  };

  useEffect(() => {
    if (isVisible && targetBranch && targetBranch.uuid) {
      setBranch(targetBranch);
    }
  }, [isVisible, targetBranch]);

  return (
    <div>
      <Drawer
        title={editBranch ? 'Edit Branch' : 'Add Branch'}
        placement="right"
        width="45%"
        className="add-edit-branch"
        maskClosable={false}
        onClose={onDrawerClose}
        visible={isVisible}
        getContainer={false}
        destroyOnClose={true}
      >
        {loading && <Loader />}
        <Tabs activeKey={activeKey} onChange={changeTab}>
          <TabPane tab="Basic" key="1">
            <Basic targetBranch={targetBranch} onSubmit={submit} />
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

AddEditBranch.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  targetBranch: PropTypes.objectOf(PropTypes.any),
  editBranch: PropTypes.bool.isRequired,
  business_uuid: PropTypes.string.isRequired
};

AddEditBranch.defaultProps = {
  onCancel: () => {},
  targetBranch: {}
};

export default AddEditBranch;
