import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import Ninja from '../../assets/images/ninja.svg';
import './SideMenu.less';

const SideMenu = ({ logout }) => {
  const history = useHistory();
  const initialRoute =
    history.location.pathname === '/signin'
      ? '/dashboard'
      : `/${history.location.pathname.split('/')[1]}`;
  const [currentRoute, setCurrentRoute] = useState(initialRoute);

  const handleClick = event => {
    if (event.key === '/logout') {
      history.push('/signin');
    } else {
      history.push(event.key);
      setCurrentRoute(event.key);
    }
  };

  return (
    <Menu
      onSelect={handleClick}
      defaultOpenKeys={[currentRoute]}
      selectedKeys={[currentRoute]}
      className="side-menu"
      mode="inline"
    >
      <Menu.Item className="logo" key="/">
        <div>Recce</div>
      </Menu.Item>
      <Menu.Item key="/dashboard">
        <Icon type="dashboard" theme="outlined" />
        Dashboard
      </Menu.Item>
      <Menu.Item key="/amenities">
        <Icon type="unordered-list" theme="outlined" />
        Amenities
      </Menu.Item>
      <Menu.Item key="/categories">
        <Icon type="unordered-list" theme="outlined" />
        Categories
      </Menu.Item>
      <Menu.Item key="/tags">
        <Icon type="unordered-list" theme="outlined" />
        Tags
      </Menu.Item>
      <Menu.Item key="/businesses">
        <Icon type="appstore" theme="outlined" />
        Business
      </Menu.Item>
      <Menu.Item key="/blogs">
        <Icon type="appstore" theme="outlined" />
        Blogs
      </Menu.Item>
      {/* <Menu.Item key="/ninjas">
        <img src={Ninja} alt="" />
        Ninja
      </Menu.Item>
      <Menu.Item key="/jobs">
        <Icon type="solution" theme="outlined" />
        Jobs
      </Menu.Item>
      <Menu.Item key="/problems">
        <Icon type="line-chart" theme="outlined" />
        Problems
      </Menu.Item>
      <Menu.Item key="/tests">
        <Icon type="code" theme="outlined" />
        Tests
      </Menu.Item> */}
      <Menu.Item key="/logout" onClick={() => logout()}>
        <Icon type="logout" theme="outlined" />
        Logout
      </Menu.Item>
    </Menu>
  );
};

SideMenu.propTypes = {
  logout: PropTypes.func
};

SideMenu.defaultProps = {
  logout: null
};

export default SideMenu;
