import actionTypes from './actionTypes';
import localStorage from '../../shared/localStorage';

let localUser = localStorage.getItem('user');
localUser = !localUser || localUser !== 'null' ? JSON.parse(localUser) : {};

const defaultState = {
  user: localUser,
  isLoggedIn: localUser && localUser.access_token
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.login: {
      const { user } = action;
      localStorage.setItem('user', user);
      return {
        ...state,
        user,
        isLoggedIn: true
      };
    }
    case actionTypes.logout: {
      localStorage.removeItem('user');
      return {
        ...state,
        user: {},
        isLoggedIn: false
      };
    }
    default:
      return state;
  }
};
