import React, { useState, useCallback, useEffect } from 'react';
import { message, Empty, Tabs } from 'antd';

import './ProblemDetails.less';
import PropTypes from 'prop-types';
import api from '../../api';
import Loader from '../Loader/Loader';
import ProblemTestCase from '../ProblemTestCase/ProblemTestCase';

const ProblemDetails = ({ problem }) => {
  const [detailedProblem, setDetailedProblem] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchProblemDetails = useCallback(selectedProblem => {
    setLoading(true);
    api.problems
      .getProblemDetails(selectedProblem)
      .then(resp => {
        setLoading(false);
        if (resp && resp.uuid) {
          setDetailedProblem(resp);
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (problem && problem.uuid) {
      fetchProblemDetails(problem);
    }
  }, [problem, fetchProblemDetails]);

  return (
    <div className="problem-details">
      {loading && <Loader />}
      <Tabs defaultActiveKey="statement">
        <Tabs.TabPane tab="Statement" key="statement">
          {!detailedProblem.body && !loading ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: detailedProblem.body
              }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Test Cases" key="testCases">
          <ProblemTestCase testCases={detailedProblem.test_cases} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

ProblemDetails.propTypes = {
  problem: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ProblemDetails;
