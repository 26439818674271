import './List.less';

import { Icon, Table, Tabs } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

const { TabPane } = Tabs;

const List = ({
  tags,
  editTagHandler,
  deleteTagHandler,
  userGeneratedTags
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        const A = a.name.toLowerCase();
        const B = b.name.toLowerCase();
        return A < B ? -1 : A > B ? 1 : 0;
      }
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        const A = a.slug.toLowerCase();
        const B = b.slug.toLowerCase();
        return A < B ? -1 : A > B ? 1 : 0;
      }
    },
    {
      title: 'Actions',
      render: (text, tag, index) => (
        <>
          <Icon
            className="m-r"
            type="edit"
            theme="outlined"
            onClick={() => editTagHandler(tag.uuid, tag.is_user_generated)}
          />
          <Icon
            type="delete"
            theme="outlined"
            onClick={() =>
              deleteTagHandler(tag.uuid, index, tag.is_user_generated)
            }
          />
        </>
      )
    }
  ];
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="All Tags" key="1">
        <Table
          className="tags-list"
          columns={columns}
          dataSource={tags}
          pagination={false}
        />
      </TabPane>
      <TabPane tab="User Generated" key="2">
        <Table
          className="tags-list"
          columns={columns}
          dataSource={userGeneratedTags}
          pagination={false}
        />
      </TabPane>
    </Tabs>
  );
};

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  editTagHandler: PropTypes.func.isRequired,
  deleteTagHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userGeneratedTags: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default List;
