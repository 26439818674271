import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Input,
  message,
  InputNumber,
  Divider,
  Row,
  Col
} from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const EditMenuItemDialog = ({
  //   businessDetails,
  itemUuid,
  isVisible,
  onSubmit,
  onCancel
}) => {
  //   const initialState = {
  //     name: businessDetails.name,
  //     website: businessDetails.website
  //   };
  const [error, setError] = useState({});
  const requiredFields = ['item_name', 'item_price'];
  const childrenRequiredFields = ['item_price'];
  const [itemDetails, setItemDetails] = useState(null);
  const [state, setState] = useState({
    loading: false
  });

  useEffect(() => {
    if (itemUuid) {
      api.menuItem
        .get(itemUuid)
        .then(resp => {
          setItemDetails(resp);
        })
        // eslint-disable-next-line no-shadow
        .catch(error => {
          message.error(error.message);
        });
    }
  }, [itemUuid]);

  const resetState = () =>
    setState(stateObj => ({
      ...stateObj,
      menuItem: {},
      loading: false
    }));

  const updateMenuItem = useCallback(
    // eslint-disable-next-line no-shadow
    itemDetails => {
      setState(stateObj => ({ ...stateObj, loading: true }));
      setError({});
      api.menuItem
        .update(itemDetails)
        .then(resp => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          if (resp && resp.uuid) {
            onSubmit(resp);
          }
        })
        .catch(err => {
          setState(stateObj => ({ ...stateObj, loading: false }));
          message.error(err.message);
        });
    },
    [onSubmit]
  );

  // eslint-disable-next-line no-shadow
  const submit = itemDetails => {
    const valid = validate(itemDetails, requiredFields);
    if (!valid.isValid) {
      setError(valid.errors);
      return;
    }
    // setState({
    //   ...state,
    //   menuCategory: {
    //     ...state.menuCategory,
    //     branch_uuid: branchUuid
    //   }
    // });
    updateMenuItem(itemDetails);
  };

  const cancel = () => {
    // resetState();
    onCancel();
  };

  //   useEffect(() => {
  //     setState(stateObj => ({
  //       ...stateObj,
  //       business: {
  //         name: businessDetails.name,
  //         website: businessDetails.website
  //       }
  //     }));
  //   }, [businessDetails]);

  const generateItemForm = () => {
    console.log('GENERATE_ITEM_FORM: ', itemDetails);
    return (
      <>
        {itemDetails &&
          itemDetails.children.map((child, index) => (
            <Row key={index} gutter={24}>
              <Col span={8}>
                <Form.Item label="Item Price" name="item_price">
                  <InputNumber
                    type="number"
                    size="large"
                    placeholder="Item Price"
                    style={{ width: '75%' }}
                    value={child?.item_price}
                    onChange={
                      value =>
                        setItemDetails({
                          ...itemDetails,
                          children: [
                            ...itemDetails.children.slice(0, index),
                            {
                              ...itemDetails.children[index],
                              item_price: value
                            },
                            ...itemDetails.children.slice(index + 1)
                          ]
                        })
                      // setItemDetails({
                      //   ...itemDetails,
                      //   children: { ...itemDetails.children, item_price: value }
                      // })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Item Serving" name="item_serving">
                  <InputNumber
                    type="number"
                    size="large"
                    placeholder="Item Serving"
                    value={child?.item_serving}
                    style={{ width: '75%' }}
                    onChange={
                      value =>
                        setItemDetails({
                          ...itemDetails,
                          children: [
                            ...itemDetails.children.slice(0, index),
                            {
                              ...itemDetails.children[index],
                              item_serving: value
                            },
                            ...itemDetails.children.slice(index + 1)
                          ]
                        })
                      // setItemDetails({
                      //   ...itemDetails,
                      //   children: {
                      //     ...itemDetails.children,
                      //     item_serving: value
                      //   }
                      // })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Item Size" name="item_size">
                  <Input
                    size="large"
                    placeholder="Item Size"
                    value={child?.item_size}
                    style={{ width: '100%' }}
                    onChange={
                      e =>
                        setItemDetails({
                          ...itemDetails,
                          children: [
                            ...itemDetails.children.slice(0, index),
                            {
                              ...itemDetails.children[index],
                              item_size: e.target.value
                            },
                            ...itemDetails.children.slice(index + 1)
                          ]
                        })
                      // setItemDetails({
                      //   ...itemDetails,
                      //   children: {
                      //     ...itemDetails.children,
                      //     item_size: e.target.value
                      //   }
                      // })
                    }
                  />
                </Form.Item>
              </Col>
              <Divider
                className="menu-divider"
                style={{
                  height: '2px'
                }}
              />
            </Row>
          ))}
      </>
    );
  };

  return (
    <Modal
      title="Edit Menu Item"
      centered
      visible={isVisible}
      okText="Update"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(itemDetails)}
      className="add-menu-item-dialog"
    >
      <div>
        {state.loading && <Loader />}
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Item Name" name="item_name" required>
                {FormItemWithError(
                  <Input
                    size="large"
                    placeholder="Item Name"
                    value={itemDetails?.item_name}
                    onChange={e =>
                      setItemDetails({
                        ...itemDetails,
                        item_name: e.target.value
                      })
                    }
                  />,
                  'item_name',
                  error
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Item Description" name="item_desc">
                <Input.TextArea
                  rows={5}
                  size="large"
                  placeholder="Item Description"
                  value={itemDetails?.item_desc}
                  onChange={e =>
                    setItemDetails({
                      ...itemDetails,
                      item_desc: e.target.value
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Item Price" name="item_price" required>
                {FormItemWithError(
                  <InputNumber
                    type="number"
                    size="large"
                    placeholder="Item Price"
                    style={{ width: '75%' }}
                    value={itemDetails?.item_price}
                    onChange={value =>
                      setItemDetails({ ...itemDetails, item_price: value })
                    }
                  />,
                  'item_price',
                  error
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Item Serving" name="item_serving">
                <InputNumber
                  type="number"
                  size="large"
                  placeholder="Item Serving"
                  value={itemDetails?.item_serving}
                  style={{ width: '75%' }}
                  onChange={value =>
                    setItemDetails({
                      ...itemDetails,
                      item_serving: value
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Item Size" name="item_size">
                <Input
                  size="large"
                  placeholder="Item Size"
                  style={{ width: '100%' }}
                  value={itemDetails?.item_size}
                  onChange={e =>
                    setItemDetails({
                      ...itemDetails,
                      item_size: e.target.value
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider
            className="menu-divider"
            style={{
              margin: '14px 0 0',
              height: '5px',
              backgroundColor: '#eb5149'
            }}
          /> */}
          <h2 style={{ textAlign: 'center' }}>Children</h2>
          {itemDetails && itemDetails.children ? generateItemForm() : null}
        </Form>
      </div>
    </Modal>
  );
};

EditMenuItemDialog.propTypes = {
  //   businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemUuid: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default EditMenuItemDialog;
