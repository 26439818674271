import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, message, Upload, Icon } from 'antd';

import api from '../../api';
import Loader from '../Loader/Loader';
import { validate, FormItemWithError } from '../../shared/validations';

const AddMenuImageDialog = ({
  //   businessDetails,
  branchUuid,
  isVisible,
  onSubmit,
  onCancel
}) => {
  //   const initialState = {
  //     name: businessDetails.name,
  //     website: businessDetails.website
  //   };
  const [error, setError] = useState({});
  const [defaultMenuMedia, setDefaultMenuMedia] = useState([]);
  const [defaultMedia, setDefaultMedia] = useState([]);
  const [altText, setAltText] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  //   //   const requiredFields = ['alt'];
  //     const [state, setState] = useState({
  //       // business: initialState,
  //       menuCategory: {
  //         name: '',
  //         category_desc: ''
  //       },
  //       loading: false
  //     });

  // useEffect(() => {
  //   if (categoryData?.uuid) {
  //     console.log('EDIT CATEGORY: ', categoryData.uuid);
  //     setState({
  //       ...state,
  //       menuCategory: {
  //         name: categoryData.name,
  //         category_desc: categoryData.category_desc,
  //         uuid: categoryData.uuid
  //       }
  //     });
  //   }
  // }, [categoryData]);

  const resetState = () => {
    setAltText('');
    setFileUrl('');
  };

  const addMenuCategory = useCallback(
    (text, imageUrl) => {
      api.media
        .add({
          target_uuid: branchUuid,
          target: 'branch',
          category: 'Menu',
          url: imageUrl,
          is_featured: false,
          alt_text: text
        })
        .then(resp => {
          if (resp && resp.uuid) {
            message.success('Image Uploaded!');
            // fileList.pop();
            // fileList.push({
            //   uid: resp.uuid,
            //   thumbUrl: resp.url
            // });
            defaultMedia.push({
              uid: resp.uuid,
              thumbUrl: resp.url
            });
            setDefaultMedia(defaultMedia);
            onSubmit();
            // setFileList(fileList);
            // setDefaultMedia(...defaultMedia, {
            //   uid: resp.uuid,
            //   thumbUrl: resp.secure_url
            // });
          }
        })
        .catch(err => {
          message.error(err.message);
        });
    },
    [onSubmit]
  );

  const submit = text => {
    console.log('SUBMITTTT', text);
    console.log('FILE URL', fileUrl);
    // const valid = validate(text, requiredFields);
    // if (!valid.isValid) {
    //   console.log('AFTER SUBMITTTT', text);
    //   setError(valid.errors);
    //   return;
    // }
    // setState({
    //   ...state,
    //   menuCategory: {
    //     ...state.menuCategory,
    //     branch_uuid: branchUuid
    //   }
    // });
    addMenuCategory(text, fileUrl);
  };

  const cancel = () => {
    resetState();
    onCancel();
  };

  //   useEffect(() => {
  //     setState(stateObj => ({
  //       ...stateObj,
  //       business: {
  //         name: businessDetails.name,
  //         website: businessDetails.website
  //       }
  //     }));
  //   }, [businessDetails]);

  const prepareMenuData = file => {
    const uuid = `${branchUuid}/`;
    return {
      upload_preset: 'recce_prod',
      public_id: `${uuid}/${file.name
        .replace('/', '')
        .split('.')
        .shift()}`
    };
  };

  const handlePreview = async file => {
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  // eslint-disable-next-line no-shadow
  const handleMenuImageChange = ({ file, fileList }) => {
    if (file?.status === 'removed') {
      if (file?.response) {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.thumbUrl == file.response.secure_url;
        });
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      } else {
        const indexOfObject = defaultMedia.findIndex(object => {
          // eslint-disable-next-line eqeqeq
          return object.uid == file.uid;
        });
        api.media
          .softDelete(defaultMedia[indexOfObject].uid)
          .then(resp => {
            if (resp) {
              message.success('Image Deleted!');
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        defaultMedia.splice(indexOfObject, 1);
        setDefaultMedia(defaultMedia);
      }
    }
    // eslint-disable-next-line eqeqeq
    if (file?.response && file?.status != 'removed') {
      console.log('URL ===>>>', file?.response?.secure_url);
      setFileUrl(file?.response?.secure_url);
      //   addMenuCategory(file);
      //   api.media
      //     .add({
      //       target_uuid: branchUuid,
      //       target: 'branch',
      //       category: 'Menu',
      //       url: file?.response?.secure_url,
      //       is_featured: false,
      //       alt_text: altText
      //     })
      //     .then(resp => {
      //       if (resp && resp.uuid) {
      //         message.success('Image Uploaded!');
      //         // fileList.pop();
      //         // fileList.push({
      //         //   uid: resp.uuid,
      //         //   thumbUrl: resp.url
      //         // });
      //         defaultMedia.push({
      //           uid: resp.uuid,
      //           thumbUrl: resp.url
      //         });
      //         setDefaultMedia(defaultMedia);
      //         // setFileList(fileList);
      //         // setDefaultMedia(...defaultMedia, {
      //         //   uid: resp.uuid,
      //         //   thumbUrl: resp.secure_url
      //         // });
      //       }
      //     })
      //     .catch(err => {
      //       message.error(err.message);
      //     });
    }
    setFileList(fileList);
  };

  const removeFile = file => {
    // eslint-disable-next-line no-console
    console.log('onRemove File: ', file);
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewVisible(false);

  return (
    <Modal
      title="Add Menu Category"
      centered
      visible={isVisible}
      okText="Submit"
      width={720}
      destroyOnClose
      onCancel={cancel}
      onOk={() => submit(altText)}
      className="add-menu-category-dialog"
    >
      <div>
        {loading && <Loader />}
        <Form>
          <Form.Item label="Image Alt Text" name="alt">
            <Input
              size="large"
              placeholder="Image Alt Text"
              value={altText}
              onChange={e => setAltText(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Category Description" name="description">
            <Upload
              action="https://api.cloudinary.com/v1_1/recce/upload"
              listType="picture-card"
              // [
              //   {
              //     uid: '9e43ffb2-a560-4f3a-8f7e-05a4b55f2143',
              //     thumbUrl:
              //       'https://res.cloudinary.com/recce/image/upload/v1668277811/recce_prod/ed912070-e32e-49ef-a9fd-14445f79d546/0-2.jpg'
              //   }
              // ]
              defaultFileList={defaultMenuMedia}
              // fileList={fileList}
              data={prepareMenuData}
              multiple={true}
              // beforeUpload={beforeUpload}
              onPreview={handlePreview}
              onChange={handleMenuImageChange}
              onRemove={removeFile}
            >
              {/* {fileList.length >= 8 ? null : uploadButton} */}
              {uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {/* <Input.TextArea
              rows={5}
              size="large"
              placeholder="Category Description"
              value={state.menuCategory.category_desc}
              onChange={e =>
                setState({
                  ...state,
                  menuCategory: {
                    ...state.menuCategory,
                    category_desc: e.target.value
                  }
                })
              }
            /> */}
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

AddMenuImageDialog.propTypes = {
  //   businessDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  branchUuid: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AddMenuImageDialog;
