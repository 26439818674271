import business from './business';
import config from './config';
import branches from './branches';
import ninja from './ninja';
import problems from './problems';
import amenities from './amenities';
import categories from './categories';
import test from './test';
import location from './location';
import user from './user';
import blog from './blog';
import blogCategory from './blogCategory';
import menuCategory from './menuCategory';
import menuItem from './menuItem';
import media from './media';
import tags from './tags';

const api = {
  business,
  config,
  branches,
  ninja,
  problems,
  amenities,
  categories,
  test,
  user,
  blog,
  blogCategory,
  location,
  menuCategory,
  menuItem,
  media,
  tags
};

export default api;
