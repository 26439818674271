import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tag, Avatar } from 'antd';
import Man from '../../assets/images/man-user.svg';

import './NinjaCard.less';
import { ninjaStatusColor } from '../../shared/utility';
import SkillSet from '../CategorySet/CategorySet';

const NinjaCard = ({ data, ninjasStatus, openDetails }) => {
  const { ninjaId } = useParams();
  const ninjaStatusObj = {};
  ninjasStatus?.length &&
    ninjasStatus.map(item => (ninjaStatusObj[item.value] = item.name));

  const ninjaAvatar = () => {
    return data?.user?.avatar
      ? data.user.avatar.replace(
          'upload/',
          'upload/w_140,h_140,c_thumb,g_face/'
        )
      : Man;
  };

  return (
    <div
      className={`ninja-card ${data.uuid === ninjaId ? 'selected' : ''}`}
      onClick={() => openDetails(data)}
    >
      <div className="avatar">
        <Avatar size={64} src={ninjaAvatar()} />
      </div>
      <div className="details">
        <h4 className="name">
          {`${data.user.first_name} ${data.user.last_name}`}
          {data.status !== null && ninjasStatus && ninjasStatus[0] ? (
            <>
              {' | '}
              <Tag color={ninjaStatusColor(data.status)}>
                {ninjaStatusObj[data.status]}
              </Tag>
            </>
          ) : null}
        </h4>
        <SkillSet skills={data.skills} cardSkills={true} />
        <h4 className="experience">
          {data.title}, {data.experience_started.years}{' '}
          {data.experience_started === 1 ? 'yr' : 'yrs'}
        </h4>
      </div>
    </div>
  );
};

NinjaCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  ninjasStatus: PropTypes.arrayOf(PropTypes.any).isRequired,
  openDetails: PropTypes.func
};

NinjaCard.defaultProps = {
  openDetails: () => {}
};

export default NinjaCard;
